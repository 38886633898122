import React, { Component, useState, useEffect } from "react";
import styled from "styled-components";

import { getStoarge, setStoarge, useAuth } from "Datas/storage";
import { CheckPhone, validatePassword } from "Settings/common";
import { redirect, withRouter } from "react-router";
import { LoginUser } from "Datas/api";
import { Link } from "react-router-dom";
 
 
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { GetInfoDataNoUser, GetInforMation } from "Datas/swr";
 
import AlertModal from "Modal";
import { LoginChkOff, LoginChkOn } from "assets";
 

const Wrapper = styled.div``;

const Inner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 570px;
  padding: 0 20px;
`;

const LoginText = styled.div`
  min-width: 280px;
  font-size: 20px;
  font-weight: bold;
  margin: 15px 0;
  color: #000;
  letter-spacing: 1;
  /* transform:scale(0.5); */
  @media screen and (max-width: 450px) {
    font-size: 22px;
  }
  margin-bottom: 15px;
  font-weight: 700;
`;

const CredentialsInput = styled.div`
  font-size: 15px;
  width: 100%;
  min-width: 280px;
  margin-top: 15px;
  background-color: #fff;
  :focus {
    background-color: none;
  }
`;

const JoinArea = styled.div`
  min-width: 282px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 25px 0px;
  & > .right {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  & > .left {
    display: flex;
    align-items: center;
    & > button {
      width: 22px;
      margin-right: 7px;
      border: 0;
      background: #fff;
      margin-top: 3px;
      cursor: pointer;
    }
    & .LoginChkOn,
    & .LoginChkOff {
      width: 22px;
      height: 22px;
    }
    & > span {
      font-size: 16px;
    }
  }
`;

const SortationLine = styled.div`
  height: 10px;
  width: 1px;
  background-color: #ddd;
  margin: 0 20px;
`;

const CredentialsInputText = styled.div`
  font-size: 25px;
  color: #838383;

  @media screen and (max-width: 450px) {
    font-size: 17px;
  }
`;

const CredentialsInputInput = styled.input`
  width: 100%;
  margin-top: 5px;
  height: 60px;
  border: 1px solid #eaeaea;
  background-color: #fff;
  border-radius: 10px;
  color: #000;
  font-size: 16px;
  padding: 10px 10px 10px 20px;
  :focus {
    background-color: none;
  }
`;

const LoginBtn = styled.div`
  min-width: 282px;
  width: 100%;
  cursor: pointer;
  height: 60px;
  color: #fff;
  background: #333;
  /* border: 1px solid #feb43c; */
  border-radius: 10px;
  margin-top: 15px;
  :hover {
    opacity: 0.95;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

const LogoArea = styled.div`
  text-align: center;
  width: 100%;
  margin: 20px auto 40px;
`;

const SignupBtn = styled.div`
  color: #333;
  :hover {
    opacity: 0.5;
  }
  /* border: 1px solid #feb43c; */
  cursor: pointer;
  /* border-radius: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

const FindIdPW = styled.div`
  font-size: 16px;
  color: #333;
  text-align: center;
  cursor: pointer;
`;

const TintBox = styled.p`
  min-width: 250px;
  color: red;
  margin-top: 12px;
  font-size: 15px;
  padding-left: 15px;
`;
const MainLogo = styled.img`
  width: 100%;
  min-width: 280px;
`;
const LoginWrapper = styled.div`
  position: relative;
  max-width: 445px;
  margin: 15px auto;
  padding-bottom: 100px;
  padding-top: 100px;
  @media screen and (max-width: 600px) {
    width: 90%;
  }
`;
//셋로그인 진행해주기
const Login = ({ history }) => {
  const { NoInfoData, NoInfoDataMutate } = GetInfoDataNoUser();
  const { setLogin } = useAuth();
  const [isModal, setIsModal] = useState(false);
  //const fingerPrint = GetFingerPrint();
  const fpPromise = FingerprintJS.load();
  const [isModal1, setIsModal1] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
    finger: "",
    fcm: "",
  });
  //로그인날려주기
  const _handleLogin = async () => {
    const fp = await fpPromise;
    let fcm = await getStoarge("fcmToken");
    fcm = await JSON.parse(fcm);
    const result = await fp.get();
    const visitorId = result.visitorId;
    data.finger = visitorId;
    data.fcm = fcm?.fcmToken;

    if (!CheckPhone(data.email) || data.password === "") {
      setIsModal(true);
      return;
    } else {
      let res = await LoginUser(data, "user");

      if (!res) {
        setIsModal(true);
        return;
      } else {
        if (res?.data?.status == 400) {
          setIsModal1(true);
          return;
        }
        if (res?.data?.status == 403) {
          history.push(`/resetFinger/${res?.data?.id}`);
          alert(res.data.message);
          return;
        }
        await setStoarge("jwt", res?.data?.jwt); //로그인후 저장 메인이동
        await setStoarge("LoginData", res?.data?.userId );
        await setLogin(res.data);
        if (loginChk === true) {
          await setStoarge("AutoLogin", true); //로그인후 저장 메인이동
        }
        history.push("/");
      }
    }
  };
  const _closeModal = () => {
    setIsModal(false);
  };

  const [loginChk, setLoginChk] = useState(true);

  return (
    <>
      <Wrapper>
        <AlertModal open={isModal} close={_closeModal} header="로그인 정보">
          로그인 정보가 잘못 되었습니다.
        </AlertModal>
        <AlertModal
          open={isModal1}
          close={() => {
            setIsModal1(false);
          }}
          header="로그인 정보"
        >
          {`관리자 승인 대기중 입니다\n승인후 안내드리겠습니다.`}
        </AlertModal>
        <LoginWrapper>
          <LogoArea>
            <MainLogo src={NoInfoData !== undefined && NoInfoData.image} />
            <LoginText>로그인</LoginText>
          </LogoArea>
          <CredentialsInput>
            {/* <CredentialsInputText>아이디</CredentialsInputText> */}
            <CredentialsInputInput
              value={data.email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
              placeholder="아이디"
            ></CredentialsInputInput>
          </CredentialsInput>
          {data?.email !== "admin" && (
            <>
              {!CheckPhone(data.email) && (
                <TintBox>휴대폰 번호를 입력해주세요.</TintBox>
              )}
            </>
          )}
          <CredentialsInput>
            {/* <CredentialsInputText>비밀번호</CredentialsInputText> */}
            <CredentialsInputInput
              value={data.password}
              type="password"
              onChange={(e) => setData({ ...data, password: e.target.value })}
              placeholder="비밀번호"
            ></CredentialsInputInput>
            <TintBox>{validatePassword(data.password)}</TintBox>
          </CredentialsInput>
          <JoinArea>
            <div className="left">
              <button /* onClick={() => setLoginChk(!loginChk)} */>
                {loginChk === false && (
                  <img src={LoginChkOff} className="LoginChkOn" />
                )}
                {loginChk === true && (
                  <img src={LoginChkOn} className="LoginChkOff" />
                )}
              </button>
              <span>자동 로그인</span>
            </div>
            <div className="right">
              <Link to="/register">
                <SignupBtn>회원가입</SignupBtn>
              </Link>
              <SortationLine />
              <Link to="/findpw">
                <FindIdPW>비밀번호 찾기</FindIdPW>
              </Link>
            </div>
          </JoinArea>
          <LoginBtn onClick={() => _handleLogin()}>로그인</LoginBtn>
        </LoginWrapper>
      </Wrapper>
    </>
  );
};

export default withRouter(Login);
