import React, { useState, useEffect } from "react";
import { Pagination as AntPagination } from "antd";
import styled from "styled-components";

const PagenNvigationArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
  @media(max-width: 900px) {
    margin: 60px 0 -45px;
  }
`;

const CustomPagination = ({ data, setOffset, page, setPage }) => {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    let Pages = [];
    let counts;
    if (data?.count > 10) {
      counts = Math.ceil(data?.count / 10); // Math.ceil to round up
      for (let i = 0; i < counts; i++) {
        Pages.push(i + 1); // Page numbers should start from 1
      }
    }
    setPage(Pages);
  }, [data, setPage]);

  useEffect(() => {
    setOffset((currentPage - 1) * 10);
  }, [currentPage, setOffset]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <PagenNvigationArea>
      <AntPagination
        current={currentPage}
        total={data?.count || 0}
        pageSize={10}
        onChange={handlePageChange}
        showSizeChanger={false} // Hide page size changer if not needed
      />
    </PagenNvigationArea>
  );
};

export default CustomPagination;
