import React, { useState } from "react";
import styled from "styled-components";
import Phone from "Components/Front/Phone.js";

import KakaoIcon from "kakaotalk.png";
import CallIconPng from "call.png";
import { phoneProducts } from "Settings/mockData";
import {
  GetproductList,
  GetproductList_ver2,
  GetPromotionList,
} from "Datas/swr";
import { useHistory, withRouter } from "react-router";
import mockBanner from "../../mockBanner.png";
import Sliders from "Components/Sliders";
import { Fade } from "react-reveal";
import CustomPagination from "Settings/pagination";
import { TextField } from "@material-ui/core";
import { Pagination } from "react-pagination-bar";
import "react-pagination-bar/dist/index.css";
import { useEffect } from "react";
import { useAuth } from "Datas/storage";
import { BANNER1920, BANNER360, BANNER768 } from "assets";
import { AntSelect, CustomSelect, TabWrap } from "Components/Front/Front";
import moment from "moment";
const Wrapper = styled.div``;

const Leaderboard = styled.img`
  width: 100%;
  margin-top: 100px;
  height: 550px;
  background: #f0f0f0;
  @media screen and (max-width: 768px) {
    height: 320px;
  }
  @media screen and (max-width: 375px) {
    height: 207px;
  }
`;

const Main = styled.div`
  display: flex;
  max-width: 1380px;
  padding: 0;
  margin: 0 auto;
  width: 100%;
  flex-direction: column;
  @media (max-width: 1280px) {
    padding: 0 10px;
  }
`;

const PhoneTechTop6 = styled.div`
  display: flex;
  color: #feb43c;
  font-size: 25px;

  margin-top: 96px;

  @media screen and (max-width: 768px) {
    font-size: 22px;
  }

  @media screen and (max-width: 375px) {
    font-size: 18px;
  }
`;

const WhichPhoneHot = styled.div`
  display: flex;
  font-size: 35px;
  font-weight: 600;
  width: fit-content;
  @media screen and (max-width: 768px) {
    font-size: 26px;
    font-weight: 600;
    margin-left: 2%;
  }
  @media screen and (max-width: 375px) {
    font-size: 20px;
    font-weight: 600;
    margin-left: 2%;
  }
`;

const PhoneWrapper = styled.div`
  display: flex;
  //justify-content: space-between;
  flex-wrap: wrap;
  margin-top:16px;
  position: relative;
`;

const StartTalk = styled.div`
  position: absolute;
  right: 0px;
  top: 50px;
`;

const KakaoTalk = styled.div`
  display: flex;
  width: 256px;
  height: 60px;
  align-items: center;
  background: #f5f5f5;
  border-radius: 30px;
  & > div {
    margin-left: 15px;
  }
  font-size: 20px;
  @media screen and (max-width: 450px) {
    width: 105px;
    height: 30px;
    font-size: 12px;
  }
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

const KakaoTalkIcon = styled.div`
  height: 60px;
  width: 60px;
  margin-left: auto !important;
  border-radius: 100%;

  @media screen and (max-width: 450px) {
    height: 30px;
    width: 30px;
  }

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
`;

const CallTalk = styled.div`
  display: flex;
  width: 256px;
  height: 60px;
  align-items: center;
  margin-top: 15px;
  background: #f5f5f5;
  border-radius: 30px;
  & > div {
    margin-left: 15px;
  }
  font-size: 20px;
  @media screen and (max-width: 450px) {
    width: 105px;
    height: 30px;
    font-size: 12px;
  }
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

const CallIcon = styled.div`
  height: 60px;
  width: 60px;
  margin-left: auto !important;
  border-radius: 100%;

  @media screen and (max-width: 450px) {
    height: 30px;
    width: 30px;
  }

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
`;

const PhoneOptionCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.selected === true ? "#feb43c" : "#f0f0f0")};
  color: ${(props) => (props.selected === true ? "#fff" : "333")};
  padding: 7px 21px;
  font-weight: 500;
  margin-right: 20px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 30px;

  @media screen and (max-width: 768px) {
    padding: 7px 16px;
    font-size: 14px;
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  margin: 55px 0;
`;

const TitleFlexBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1300px) {
    width: 100%;
    padding: 0 10px;
  }
`;

const AlgnBox = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  height: 35px;
`;

const Algn = styled.div`
  width: 55px;
  height: 33px;
  border-radius: 8px;
  background-color: orange;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const SearchBox = styled.div`
  width: 50%;
  margin-top: 12px;
  font-size: 15px;
  @media (max-width: 1300px) {
    width: 70%;
    padding: 0 10px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const BannerWrap = styled.div`
width:100%;
cursor:pointer;

@media screen and (max-width: 1024px) {
    height:244px;
  };
@media screen and (max-width: 520px) {
    height:130px;
  } ;
 
`;
const BannerImg = styled.img`
width:100%;
height:100%;
object-fit: cover;
max-width:100%;
max-height:100%;
`;
const Category = ({ match, profile, route }) => {
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { setLogout } = useAuth();
  const [filtervalue, setFilterValue] = useState(1);
  const { PromotionListData } = GetPromotionList(10);
  const { ProductListData, Error } = GetproductList_ver2(
    "category",
    0,
    match?.params?.id,
    offset,
    filtervalue,
    search,
    profile?.storeid
  );
  const [SKT요금제리스트, 설정SKT요금제리스트] = useState([]);
  const [KT요금제리스트, 설정KT요금제리스트] = useState([]);
  const [LGU요금제리스트, 설정LGU요금제리스트] = useState([])
  const [공시정보, 설정공시정보] = useState('할인유형별')
  const [SKT선택, 설정SKT선택] = useState('SKT요금제')
  const [KT선택, 설정KT선택] = useState('KT요금제')
  const [LGU선택, 설정LGU선택] = useState('LGU+요금제')
  const history = useHistory();
  /* if (match.params.type) { */
  useEffect(() => {
    setOffset(0);
    setCurrentPage(1);
  }, [match?.params?.id]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (ProductListData) {
      // Function to extract the number from the label
      const extractNumberFromLabel = (label) => {
        const match = label.match(/\((\d+)\)/);
        return match ? parseInt(match[1], 10) : 0;
      };

      // Function to sort items by the number extracted from the label in descending order
      const sortItemsByNumberDescending = (items) => items.sort((a, b) => extractNumberFromLabel(b.label) - extractNumberFromLabel(a.label));

      // Process and sort SKT items
      const SKT = ProductListData?.rows[0]?.telecom_product_key_product
        ?.filter(item => item?.telecomname === "SKT")
        .map(item => ({ label: item?.name, value: item?.id }))
        .filter(item => item !== undefined);

      // Process and sort KT items
      const KT = ProductListData?.rows[0]?.telecom_product_key_product
        ?.filter(item => item?.telecomname === "KT")
        .map(item => ({ label: item?.name, value: item?.id }))
        .filter(item => item !== undefined);

      // Process and sort LGU items
      const LGU = ProductListData?.rows[0]?.telecom_product_key_product
        ?.filter(item => item?.telecomname === "LGU+")
        .map(item => ({ label: item?.name, value: item?.id }))
        .filter(item => item !== undefined);

      // Sorting items based on the number in the label in descending order
      const sortedSKT = sortItemsByNumberDescending(SKT || []);
      const sortedKT = sortItemsByNumberDescending(KT || []);
      const sortedLGU = sortItemsByNumberDescending(LGU || []);

      // Update the state with sorted items
      설정SKT요금제리스트([{ label: 'SKT요금제', value: 'SKT요금제' }, ...sortedSKT]);
      설정KT요금제리스트([{ label: 'KT요금제', value: 'KT요금제' }, ...sortedKT]);
      설정LGU요금제리스트([{ label: 'LGU+요금제', value: 'LGU+요금제' }, ...sortedLGU]);
    }
  }, [ProductListData]);



  return (
    <>

      {/*  <BannerWrap style={{ maxWidth: 1440, margin: '0 auto', marginTop: 125, marginBottom: 30 }} onClick={() => {
        history.push('/announcement')
      }}> */}
      {/*   {windowWidth >= 1100 && <BannerImg src={BANNER1920} alt="Large Banner" />}
        {windowWidth > 520 && windowWidth < 1100 && <BannerImg src={BANNER768} alt="Medium Banner" />}
        {windowWidth < 520 && <BannerImg src={BANNER768} alt="Small Banner" />} */}
      {/*   </BannerWrap> */}

      {/*    <TitleFlexBox style={{ maxWidth: 1440, margin: '0 auto' }}>
        <WhichPhoneHot>{match?.params?.type}</WhichPhoneHot>
      
      </TitleFlexBox> */}
      <div style={{marginTop:145}} />
      <TabWrap style={{ margin: '-24px 0px' }}>

        <div className="inner">
          <div className="flex">
            <CustomSelect
              value={공시정보}
              onChange={(e) => {
                설정공시정보(e)
              }}
              options={[
                {
                  value: '할인유형별',
                  label: '할인유형별',
                },
                {
                  value: '공시지원할인',
                  label: '공시지원할인',
                },
                {
                  value: '선택약정할인',
                  label: '선택약정할인',
                },
              ]}
              width="180px"
              height="40px"
            />

          </div>
          <div className="flex">
            <CustomSelect
              value={SKT선택}
              onChange={(e) => {
                설정SKT선택(e)
              }}
              options={SKT요금제리스트}
              width="180px"
              height="40px"
            />

          </div>
          <div className="flex">

            <CustomSelect
              onChange={(e) => {
                설정KT선택(e)
              }}
              value={KT선택} options={KT요금제리스트}
              width="180px"
              height="40px" />
          </div>
          <div className="flex">
            <CustomSelect
              onChange={(e) => {
                설정LGU선택(e)
              }}
              value={LGU선택} options={LGU요금제리스트}
              width="180px"
              height="40px" />
          </div>
        </div>
      </TabWrap>
      <Wrapper>
        <Main style={{ marginTop: 16 }}>


          {/* <OptionWrapper>
          <PhoneOptionCircle selected={false}>SAMSUNG</PhoneOptionCircle>
          <PhoneOptionCircle selected={true}>APPLE</PhoneOptionCircle>
          <PhoneOptionCircle selected={false}>LG</PhoneOptionCircle>
        </OptionWrapper> */}
          {/* <PhoneTechTop6>폰테크 애플 상품</PhoneTechTop6> */}
          {/* {match?.params?.type === "one" && (
          <WhichPhoneHot>번호이동 상품</WhichPhoneHot>
        )}
        {match?.params?.type === "two" && (
          <WhichPhoneHot>기기변경 상품</WhichPhoneHot>
        )}
        {match?.params?.type === "three" && (
          <WhichPhoneHot>증정 사은품</WhichPhoneHot>
        )} */}


          <PhoneWrapper>
            <SearchBox>
              <TextField
                fullWidth
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                id="outlined-basic"
                label="상품명 검색"
                variant="outlined"
              />
            </SearchBox>

            {/* {PromotionListData !== undefined &&
              PromotionListData?.length !== 0 &&
              PromotionListData?.map((el, idx) => {
                if (!el?.type) {
                  return;
                }
                return (
                  <Main>
                    <PhoneWrapper style={{ marginTop: 50 }}>
                      {phoneProducts.map((el, idx) => {
                        return <Phone data={el} />;
                      })}
                    </PhoneWrapper>
                  </Main>
                );
              })} */}
            {/* mockup data */}
            <Main>
              <PhoneWrapper style={{ marginTop: 35 }}>
                {ProductListData?.rows?.map((el, idx) => {
                  return <Phone data={el} 공시정보={공시정보} SKT선택={SKT선택} KT선택={KT선택} LGU선택={LGU선택} />;
                })}
              </PhoneWrapper>
            </Main>
            {/* mockup data */}
            {/* <Phone />
              <Phone />
              <Phone />
              <Phone /> */}
            {/* <StartTalk>
            <KakaoTalk>
              <div>카카오톡상담</div>
              <KakaoTalkIcon>
                <img alt="" src={KakaoIcon} />
              </KakaoTalkIcon>
            </KakaoTalk>
            <CallTalk>
              <div>전화 상담</div>
              <CallIcon>
                <img alt="" src={CallIconPng} />
              </CallIcon>
            </CallTalk>
          </StartTalk> */}
          </PhoneWrapper>
        </Main>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Pagination
            currentPage={currentPage}
            itemsPerPage={10}
            onPageChange={(pageNumber) => {
              setCurrentPage(pageNumber);
              setOffset((pageNumber - 1) * 9);
            }}
            totalItems={ProductListData?.count}
            pageNeighbours={14}
          />
        </div>
      </Wrapper>
    </>
  );

};

export default withRouter(Category);
