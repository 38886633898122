const validateEmail = (email) => {
  let message;
  if (!email) {
    message = "이메일을 입력해주세요";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    message = "이메일 형식이 아닙니다.";
  }
  return message;
};

const validatePassword = (Password) => {
  let message;
  if (!Password) {
    message = "패스워드를 입력하여 주세요";
  } else if (Password.length < 3) {
    message = "최소 3자리이상의 패스워드를 입력해주세요";
  }
  return message;
};

const validatePasswordConfirm = (Password, PasswordConfirm) => {
  if (Password !== PasswordConfirm) {
    return "비밀번호를 확인하여주세요";
  }
  return false;
};
const CheckPhone = (Number) => {
  Number = Number.split("-").join("");
  let result = /^((01[1|6|7|8|9])[1-9]+[0-9]{6,7})|(010[1-9][0-9]{7})$/;
  return result.test(Number);
};

 const baseurl = "https://폰라인.net/api";
/*  const baseurl = "http://localhost:3001/api"; */

export {
  baseurl,
  CheckPhone,
  validateEmail,
  validatePassword,
  validatePasswordConfirm,
};
