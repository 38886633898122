import React from "react";
import PropTypes from "prop-types";
// Material-ui 컴포넌트들
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { HomeIcon } from "assets";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router";
import { ArrowBack } from "@material-ui/icons";
const drawerWidth = 240; // 메뉴 Drawer 가로크기

const MobileBox = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    display: flex;
  }
`;
const BackNavi = ({ onDrawerToggle }) => {
  const history = useHistory();
  const location = useLocation();
  return (
    <MobileBox
      onClick={() => {
        history.goBack();
      }}
    >
      <ArrowBack style={{ width: 20, height: 20,marginBottom:12 }}></ArrowBack>
      <div>{location.pathname.replace('/','')}</div>
    </MobileBox>
  );
};

export default BackNavi;
