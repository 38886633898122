import styled from "@emotion/styled";
export const CounselingArea = styled.div`
  width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: -110px auto 0;
  padding: 0px 0;
  font-size: 17px;
  & > h1 {
    margin-bottom: 10px;
  }
  & > .title {
    & > h1 {
      font-size: 35px;
      font-weight: bold;
      margin-bottom: 25px;
    }
  }
  & > table {
    width: 100%;
  }
  & > table > thead > tr,
  & > table > thead .conselingList > tr {
    height: 59px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ddd;
    & > th {
      width: 25%;
    }
  }
  & > table > thead .conselingList {
    height: 350px;
    overflow-x: scroll;
   -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  & .head {
    background-color: #0b1c4d;
    color: #fff;
    border-radius: 8px;
  }
  & .content {
    color: #333;
    & > .freebie {
      color: #000;
      font-weight: bold;
    }
    & .state {
      display: flex;
      justify-content: center;
      & > div {
        width: 80%;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        border-radius: 6px;
      }
    }
  }
  @media(max-width: 800px) {
    width: 90%;
    font-size: 14px;
    letter-spacing: -.5px;
    margin: -85px auto 0;
  }
  @media(max-width: 450px) {
    width: 90%;
    font-size: 14px;
    letter-spacing: -.5px;
  }
`;

export const StateBox = styled.div`
  background-color: ${(props) => {
    if (props.State === "상담중") {
      return "#13338c";
    }
    if (props.State === "상담완료") {
      return "#777";
    }
    if (props.State === "상담대기") {
      return "#eee";
    }
    return "#f3f3f3"
  }};
  color: ${(props) => {
    if (props.State === "상담중") {
      return "#fff";
    }
    if (props.State === "상담완료") {
      return "#fff";
    }
    return "#444"
  }};
`;