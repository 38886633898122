import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Phone from "Components/Front/Phone.js";

import { GetAuthData, GetOrderListMain, GetproductList, GetproductList_ver2, GetPromotionList, GetPromotionListN } from "Datas/swr";

import { phoneProducts } from "Settings/mockData";
import Sliders from "Components/Sliders";
import { Fade } from "react-reveal";
import CustomPagination from "Settings/pagination";

import { withRouter } from "react-router";

import Loader from "react-loader-spinner";
import Counseling from "./Counseling";
import { GetCardMain, GetReqTelList } from "./swr";

import { GetMe } from "Datas/storage";
import { Select } from "antd";
const breakpoints = [450, 768, 992, 1200]; // 0 1 번쨰사용
const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

const Wrapper = styled.div``;
export const ContentWrap = styled.div`
  margin-top: 129px;
`;

const Leaderboard = styled.img`
  width: 100%;
  margin-top: 100px;
  height: 350px;
  background: #f0f0f0;

  @media screen and (max-width: 768px) {
    height: 320px;
  }

  @media screen and (max-width: 375px) {
    height: 207px;
  }
`;

const Main = styled.div`
  display: flex;
  max-width: 1440px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  width: 100%;
  flex-direction: column;
  margin-top: ${(props) => {
    if (props.idx > 0) {
      return "-140px";
    }
  }};
`;

const PhoneTechTop6 = styled.div`
  display: flex;
  color: #feb43c;
  font-size: 25px;

  margin-top: 96px;

  @media screen and (max-width: 768px) {
    font-size: 22px;
  }

  @media screen and (max-width: 375px) {
    font-size: 18px;
  }
`;

const WhichPhoneHot = styled.div`
  display: flex;
  font-size: 35px;
  font-weight: 600;
  margin-top: 50px;

  width: fit-content;

  @media screen and (max-width: 768px) {
    font-size: 26px;
    font-weight: 600;
    margin-left: 2%;
    margin-top: 40px;
  }
  @media screen and (max-width: 375px) {
    font-size: 20px;
    font-weight: 600;
    margin-left: 2%;
    margin-top: 30px;
  }
`;

const PhoneWrapper = styled.div`
  display: flex;
  //justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 50px;
  /* width: calc(100% / 3 - 16px - 0.01px); */
  /* margin-left: calc(16px / 2); */
  margin-right: calc(72px / 3);
  align-items: center;
  border-radius: 20px;
  :nth-child(3n) {
    margin-right: 0px;
  }
  @media (max-width: 1300px) {
    width: 100%;
    margin: -20px 0px 50px;
    div.gsIaTP {
      width: 100%;
      margin: -20px 0 50px;
    }
  }
  @media (max-width: 768px) {
    div.gsIaTP {
      width: 100%;
      margin: -20px 0 50px;
    }
  }
`;

const CustomerReviewBox = styled.div`
  margin: 0px auto;
  max-width: 1640px;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${mq[0]} {
    align-items: flex-start;
    justify-content: flex;
  }

  ${mq[1]} {
    align-items: flex-start;
    justify-content: flex;
  }
`;

const CustomerReivewText = styled.div`
  box-shadow: inset 0 -8px 0 #ffcea3;
  font-size: 28px;
  font-weight: 600;
`;

const TitleFlexBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  max-width:1440px;
  margin:0 auto;
  justify-content: space-between;
`;

const AlgnBox = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  height: 35px;
`;

const Algn = styled.div`
  width: 55px;
  height: 33px;
  border-radius: 8px;
  background-color: red;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const SearchBox = styled.div`
  width: 50%;
  margin-top: 12px;
  @media screen and (max-width: 1300px) {
    width: 70%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
export const TabWrap = styled.div`
width:100%;
background-color: #f4f4f4;
height:62px;
 display: flex;
 flex-direction: row;
 align-items: center;
 @media screen and (max-width: 909px) {
 
background-color: #fff;
 }
 .tele-txt {
  margin-right:10px;
  font-weight:bold;
  font-size:16px;
 @media screen and (max-width: 909px) {
width:100px; 
background-color: #fff;
font-size:14px;
}
 }
 

 @media screen and (max-width: 909px) {
 height:auto;
 padding-top:16px;
 padding-bottom: 8px;
 }
 .inner {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
 
 
  gap: 10px; /* Optional: adds space between the items */
  @media screen and (max-width: 909px) {
  grid-template-columns: repeat(2, 1fr);
  gap:16px;
  }
}

 
.flex {
  display:flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 909px) {
 margin-bottom:16px
  }
 
} 

@media screen and (max-width: 1440px) {
  padding-left: 16px;
  padding-right: 16px;
}
`;


export const AntSelect = styled(Select)`
  width: 180px;
  height: 38px;
  margin-right: 16px;
  
  .ant-select-selector {
    border-radius: 0px !important;
    position: relative;
    z-index: 1000;
  }

  /* Ensure the dropdown is fully visible */
  .ant-select-dropdown {
    max-height: none !important;
    overflow: visible !important;
  }

  /* Remove scrolling from dropdown menu */
  .ant-select-dropdown-menu {
    max-height: none !important;
    overflow: visible !important;
  }
`;


const DropdownWrapper = styled.div`
  position: relative;
  width: ${({ width }) => width || '180px'};
  background-color: #fff;
  
    width:100%;
      @media screen and (max-width: 909px) { 
margin-bottom: -16px;
font-size:11px;
      }

`;

const DropdownButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff !important;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  height: ${({ height }) => height || '38px'};
    width:100%;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  max-height: 300px; /* Set maximum height to ensure visibility */
  overflow: auto; /* Allow scrolling if content overflows */
  z-index: 99999;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

const DropdownItem = styled.div`
padding: 8px;
cursor: pointer;
background: ${({ active }) => (active ? '#e6f7ff' : '#fff')};
  &:hover {
  background: #e6f7ff;
}
`;

export const CustomSelect = ({ value, onChange, options, width, height }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);
  const dropdownRef = useRef(null);

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (option) => {
    setSelectedValue(option.value);
    onChange(option.value);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <DropdownWrapper ref={dropdownRef} width={width}>
      <DropdownButton onClick={handleButtonClick} height={height}>
        {options.find(option => option.value === selectedValue)?.label || 'Select...'}
        <span>▼</span>
      </DropdownButton>
      {isOpen && (
        <DropdownMenu>
          {options.map(option => (
            <DropdownItem
              key={option.value}
              active={option.value === selectedValue}
              onClick={() => handleItemClick(option)}
            >
              {option.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </DropdownWrapper>
  );
};

const Promotion = ({ promotion, idx }) => {
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState([]);
  const [filterValue, setFilterValue] = useState(0);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const { profile } = GetMe();

  const { ProductListData } = GetproductList_ver2(
    "promotion",
    promotion?.id,
    0,
    offset,
    filterValue,
    search,
    profile?.storeid
  );
  console.log(ProductListData, '리스트')

  const [SKT요금제리스트, 설정SKT요금제리스트] = useState([]);
  const [KT요금제리스트, 설정KT요금제리스트] = useState([]);
  const [LGU요금제리스트, 설정LGU요금제리스트] = useState([])
  const [공시정보, 설정공시정보] = useState('할인유형별')
  const [SKT선택, 설정SKT선택] = useState('SKT요금제')
  const [KT선택, 설정KT선택] = useState('KT요금제')
  const [LGU선택, 설정LGU선택] = useState('LGU+요금제')
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [search]);
  useEffect(() => {
    if (ProductListData) {
      // Function to extract the number from the label
      const extractNumberFromLabel = (label) => {
        const match = label.match(/\((\d+)\)/);
        return match ? parseInt(match[1], 10) : 0;
      };

      // Function to sort items by the number extracted from the label in descending order
      const sortItemsByNumberDescending = (items) => items.sort((a, b) => extractNumberFromLabel(b.label) - extractNumberFromLabel(a.label));

      // Process and sort SKT items
      const SKT = ProductListData?.rows[0]?.telecom_product_key_product
        ?.filter(item => item?.telecomname === "SKT")
        .map(item => ({ label: item?.name, value: item?.id }))
        .filter(item => item !== undefined);

      // Process and sort KT items
      const KT = ProductListData?.rows[0]?.telecom_product_key_product
        ?.filter(item => item?.telecomname === "KT")
        .map(item => ({ label: item?.name, value: item?.id }))
        .filter(item => item !== undefined);

      // Process and sort LGU items
      const LGU = ProductListData?.rows[0]?.telecom_product_key_product
        ?.filter(item => item?.telecomname === "LGU+")
        .map(item => ({ label: item?.name, value: item?.id }))
        .filter(item => item !== undefined);

      // Sorting items based on the number in the label in descending order
      const sortedSKT = sortItemsByNumberDescending(SKT || []);
      const sortedKT = sortItemsByNumberDescending(KT || []);
      const sortedLGU = sortItemsByNumberDescending(LGU || []);

      // Update the state with sorted items
      설정SKT요금제리스트([{ label: 'SKT요금제', value: 'SKT요금제' }, ...sortedSKT]);
      설정KT요금제리스트([{ label: 'KT요금제', value: 'KT요금제' }, ...sortedKT]);
      설정LGU요금제리스트([{ label: 'LGU+요금제', value: 'LGU+요금제' }, ...sortedLGU]);
    }
  }, [ProductListData]);

  //수리시작
  return (
    <>

      <TabWrap style={{ marginBottom: -31 }}>
        <div className="inner">
          <div className="flex">

            <CustomSelect
              value={공시정보}
              onChange={(e) => {
                설정공시정보(e)
              }}
              options={[
                {
                  value: '할인유형별',
                  label: '할인유형별',
                },
                {
                  value: '공시지원할인',
                  label: '공시지원할인',
                },
                {
                  value: '선택약정할인',
                  label: '선택약정할인',
                },
              ]}
              width="180px"
              height="40px"
            />

          </div>
          <div className="flex">
            <CustomSelect
              value={SKT선택}
              onChange={(e) => {
                설정SKT선택(e)
              }}
              options={SKT요금제리스트}
              width="180px"
              height="40px"
            />

          </div>
          <div className="flex">

            <CustomSelect
              onChange={(e) => {
                설정KT선택(e)
              }}
              value={KT선택} options={KT요금제리스트}
              width="180px"
              height="40px" />
          </div>
          <div className="flex">

            <CustomSelect
              onChange={(e) => {
                설정LGU선택(e)
              }}
              value={LGU선택} options={LGU요금제리스트}
              width="180px"
              height="40px" />
          </div>
        </div>
      </TabWrap>
      <Main idx={idx}>

        {/*    
        <SearchBox>
          <TextField
            fullWidth
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            id="outlined-basic"
            label="상품검색"
            variant="outlined"
          />
        </SearchBox> */}
        <PhoneWrapper style={{ marginTop: 50 }}>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // width: "100%",
                height: 500,
              }}
            >
              <Loader type="TailSpin" color="orange" height={50} width={50} />
            </div>
          ) : (
            <>
              {ProductListData?.rows.map((el, idx) => {
                return (
                  <>
                    <Phone data={el} 공시정보={공시정보} SKT선택={SKT선택} KT선택={KT선택} LGU선택={LGU선택} />
                  </>
                );
              })}
              <CustomPagination
                data={ProductListData}
                setOffset={setOffset}
                page={page}
                setPage={setPage}
              />
            </>
          )}
        </PhoneWrapper>
      </Main>
    </>
  );
};

const Front = () => {
  const { PromotionListData } = GetPromotionList(0);
  const { PromotionNListData } = GetPromotionListN(0);
  const { AuthData } = GetAuthData();
  const { CardMainData, CardMainMutate, isLoading } = GetCardMain({
    siteName: "폰라인어플",
  });

  const { OrdereList } = GetOrderListMain();

  const { pathname } = window.location;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Fade Button>
        <Wrapper>
          <div style={{marginTop:20}}>
            <Sliders /></div>
          {true ? <>
            {PromotionListData !== undefined &&
              PromotionListData?.rows?.length !== 0 &&
              PromotionListData?.rows?.map((el, idx) => {
                if (!el?.type) {
                  return;
                }
                return <Promotion promotion={el} idx={idx} />;
              })}</> : <>  {PromotionNListData !== undefined &&
                PromotionNListData?.rows?.length !== 0 &&
                PromotionNListData?.rows?.map((el, idx) => {
                  if (!el?.type) {
                    return;
                  }
                  return <Promotion promotion={el} idx={idx} />;
                })} </>}
        </Wrapper>
        <ContentWrap>
          <Counseling data={OrdereList} />
        </ContentWrap>
      </Fade>
    </>
  );
};

export default withRouter(Front);
