import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useAuth } from "Datas/storage";
import { GetStoreList, GetUserProfile } from "Datas/swr";
import moment from "moment";
import { deleteMe, updateUser, uploadImage, userUpdate } from "Datas/api";
import { Fade } from "react-reveal";
import AlertModal from "Modal";
import { Input } from "antd";

const breakingpoints = [375, 768, 992, 1200]; // 0 1 번째사용
const mq = breakingpoints.map((bp) => `@media(max-width:${bp}px)}`);

const Wrapper = styled.div`
  position: relative;
  margin: 190px auto 100px;
  max-width: 1350px;
  min-width: 700px;
  
  padding: 60px 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  border-radius: 15px;
  @media (max-width: 900px) {
    margin: 95px auto 0;
    padding: 50px 90px 150px;
    border-radius: 0px;
  }
  @media (max-width: 768px) {
    margin: 95px 0px -50px -35px;
    padding: auto;
  }
  @media (max-width: 600px) {
    margin: 95px auto 0px;
    padding: 40px 40px 200px;
    display: block;
    min-width: 100%;
  }
  @media (max-width: 450px) {
    margin: 88px auto 0px;
    padding: 40px 35px 200px;
  }
  @media (max-width: 360px) {
    padding: 40px 25px 200px;
  }
`;

const ProfileBox = styled.div`
  width: 120px;
  text-align: center;
  font-weight: ${(props) => {
    if (props.bold) {
      return "bold";
    }
  }};
  ${mq[1]} {
    width: 120px;
  }
  ${mq[0]} {
    width: 110px;
  }
`;

const Top = styled.div`
  margin: 0 auto;
  width: 100%;
  text-align: center;
`;

const Text = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 5px;
  & > div:first-child {
    font-size: 36px;
  }
  & > div:last-child {
    font-size: 17px;
    color: #111;
  }
  ${mq[0]} {
    & > div:first-child {
      font-size: 36px;
    }
    & > div:last-child {
      font-size: 14px;
      color: #111;
    }
  }
`;

const Logout = styled.div`
  margin-left: auto;
  align-items: center;
  color: #ff3333;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  display: flex;
`;

const CblackentialsInputText = styled.div`
  font-size: 15px;
  color: #111;
  min-width: 100px;
  margin: auto 0;
  line-height: 30px;

  @media screen and (max-width: 450px) {
    font-size: 17px;
  }
`;

const CblackentialsInputInput = styled.input`
  height: 40px;
  color: #666;
  font-size: 15px;
  border-width: 0;
  background-color: transparent;
  @media screen and (max-width: 450px) {
    height: 45px;
  }
`;

const ProfileIag = styled.div`
  background-image: ${(props) => {
    let url;
    if (props.url) {
      url = `url(${props.url})`;
    } else {
      url = "";
    }
    return url;
  }};
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  background-color: ${(props) => {
    let color;
    if (!props.url) {
      color = "#e3e3e3";
    }
    return (color = "#fff");
  }};
  width: 160px;
  height: 160px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 160px;
  border: ${(props) => {
    let board;
    if (props.url) {
      board = "1px solid #ddd";
    }
    return board;
  }};
`;

const SignupBtn = styled.div`
  width: 150px;
  height: 88px;
  color: #fff;
  background-color: black;
   
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 600;
  @media screen and (max-width: 900px) {
    width: 280px;
    height: 56px;
    margin: 0 10px;
  }
  @media screen and (max-width: 768px) {
    width: 220px;
    height: 56px;
    min-width: 100px;
    margin: 0 10px;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    height: 45px;
    min-width: 100px;
    margin: 0;
  }
  :active,
  :hover {
    background-color: black;
    border: 0px solid #999;
    color: #fff;
  }
  cursor: pointer;
`;

const EditBtn = styled.div`
  width: 150px;
  height: 88px;
  color: #111;
  background-color: #fff;
  border: 1px solid #111;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 600;
  :focus {
    opacity: 0.5;
  }
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  @media screen and (max-width: 900px) {
    width: 280px;
    height: 56px;
    margin: 0 10px;
  }
  @media screen and (max-width: 768px) {
    width: 220px;
    height: 50px;
    min-width: 100px;
    margin: 0 10px;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    height: 45px;
    min-width: 100px;
    margin: 0;
  }
  /* :active,
  :hover {
    background-color: black;
    border: 0px solid #999;
    color: #fff;
  } */
  cursor: pointer;
`;

const ImageLabel = styled.p`
  font-size: 13px;
  font-weight: 500;
  color: #a7a7a7;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    font-weight: 500;
  }

  @media screen and (max-width: 375px) {
    font-size: 12px;
    font-weight: 500;
  }
`;

const ProfileBtnArea = styled.div`
  margin: 0 auto;
  min-width: 150px;
  @media (max-width: 900px) {
    display: flex;
    position: absolute;
    bottom: 10%;
    width: 75%;
  }
  @media (max-width: 600px) {
    display: block;
    position: absolute;
    bottom: 0%;
    width: 85%;
  }
`;

const ProfileImgArea = styled.div`
  margin: auto;
  min-width: 160px;
  padding-bottom: 20px;
`;

const InforArea = styled.div`
  margin: 0 auto;
  min-width: 280px;
  padding-top: 0px;
  @media (max-width: 600px) {
    max-width: 100%;
    padding-top: 20px;
  }
`;

const SortationLineHeignt = styled.div`
  width: 1px;
  height: 220px;
  background-color: #ddd;
  @media (max-width: 600px) {
    display: none;
  }
`;
const SortationLineWidth = styled.div`
  height: 1px;
  width: 100%;
  background-color: #ddd;
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
`;

const Profile = ({ profile }) => {
  const { setLogout } = useAuth();
  const { StoreListData, StoreListMutate } = GetStoreList(0);
  const { UserProfileData, isLoading } = GetUserProfile(profile?.userId);
  const [image, setImage] = useState([]);
  const [userStore, setUserStore] = useState("");
  const [imgBase64, setImgBase64] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [datas, setDatas] = useState({
    useremail: "",
    name: "",

    office: "",
    rank: "",
    code: "",
    image: "",
  });
  const handleImage = (e) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      const base64 = reader.result;
      if (base64) {
        setImgBase64([base64.toString()]);
      }
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      setImage([e.target.files[0]]); //경로탈착
    }
  };
  console.log(UserProfileData, "프로필");
  useEffect(() => {
    if (!isLoading) {
      setDatas({
        useremail: UserProfileData?.profile.useremail,
        name: UserProfileData?.profile.name,
        dlCode: UserProfileData?.profile?.code,
        office: UserProfileData?.profile.office,
        rank: UserProfileData?.profile.rank,
        code: UserProfileData?.profile.Store?.code,
        image: UserProfileData?.profile.image[0],
      });
    }
  }, [UserProfileData]);
 
  const _handleUpdate = async () => {
    if (datas?.image === "") delete datas.image;
    delete datas.code;

    //base 64 upload
    if (imgBase64.length > 0) {
      const ids = await Promise.all(
        image.map((uri) => {
          if (!!uri.id) return uri.id;
          let formData = new FormData();

          formData.append("files", uri);
          return uploadImage(formData);
        })
      );

      datas.image = ids;
    }
    await updateUser({ id: profile?.userId, body: datas });
  };

  const _handleLogout = async () => {
    setIsModal(true);
    setTimeout(() => {
      setLogout();
    }, 500);
  };

  const _closeModal = () => {
    setIsModal(false);
  };
  const _handleUnSign = async () => {
    if (profile?.DLMode === true) {
      alert('딜러 탈퇴 문의는 고객센터를 통해 진행해주세요');
      return;
    }
    if (window.confirm("정말 탈퇴하시겠습니까?")) {
      alert("회원 탈퇴가 완료되었습니다.");
      await deleteMe();
      setLogout();
    } else {
      return;
    }
  };

  console.log(profile);

  return (
    <Fade Button>
      <React.Fragment >
        <div style={{ minHeight: '78vh' }}>

          <AlertModal open={isModal} close={_closeModal} header="로그아웃 완료">
            로그아웃이 완료되었습니다
          </AlertModal>

          <Wrapper>
            <ProfileImgArea>
              {/*  <div
              style={{
                margin: "0 auto 20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <input
                type="file"
                name="upload-file"
                style={{ display: "none" }}
                onChange={handleImage}
                id="upload-file"
              ></input>
              {imgBase64.length > 0 ? (
                <label for="upload-file">
                  <ProfileIag for="upload-file" url={imgBase64[0]} />
                </label>
              ) : datas.image ? (
                <label for="upload-file">
                  <ProfileIag url={datas.image} />
                </label>
              ) : (
                <label for="upload-file">
                  <ProfileIag
                    for="upload-file"
                    url={datas.image}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ImageLabel>프로필 사진 등록</ImageLabel>
                  </ProfileIag>
                </label>
              )}
            </div> */}
              <Top>
                <Text>
                  <ProfileBox bold style={{ fontSize: 15 }}>
                    {profile?.gm > 0 && `${datas?.name} 가맹주님`}
                    {profile?.DLMode && "딜러"}
                  </ProfileBox>
                </Text>
                <Text style={{ display: "none" }}>
                  {/*    <ProfileBox>
                    누적수수료 <br />
                    {profile?.salea ? profile?.salea : "권한이없습니다"}
                  </ProfileBox> */}
                </Text>
                <Text>
                  <ProfileBox style={{ color: "#666" }}>
                    {profile?.gm > 0 && "포인트 : "}
                    {profile?.DLMode && "포인트 : "}
                    {profile?.DLMode === true && profile?.point}
                    {profile?.gm > 0 && profile?.point}
                  </ProfileBox>
                </Text>
              </Top>
            </ProfileImgArea>
            <SortationLineHeignt />
            <SortationLineWidth />
            <InforArea>
              <div style={{ display: "flex" }}>
                <CblackentialsInputText>이름</CblackentialsInputText>
                <Input
                  style={{ borderRadius: 0 }}
                  disabled={true}
                  value={datas?.name}
                  onChange={(e) => setDatas({ ...datas, name: e.target.value })}
                />
              </div>



              <div style={{ display: "flex", marginTop: 16, marginBottom: 10 }}>
                <CblackentialsInputText>소속</CblackentialsInputText>
                <Input
                  style={{ borderRadius: 0 }}
                  disabled={true}
                  placeholder="소속 입력"
                  value={datas?.office}
                  onChange={(e) => setDatas({ ...datas, office: e.target.value })}
                />
              </div>



              <div style={{ display: "flex" }}>
                <CblackentialsInputText>인증코드</CblackentialsInputText>
                <CblackentialsInputInput
                  placeholder="인증코드 선택"
                  disabled={true}
                  value={datas?.code}
                  onChange={(e) => setDatas({ ...datas, code: e.target.value })}
                />
              </div>
              {UserProfileData?.profile?.DLMode === true && (
                <>
                  <CblackentialsInputText>딜러코드</CblackentialsInputText>
                  <CblackentialsInputInput
                    placeholder="딜러코드 선택"
                    disabled={true}
                    value={datas?.dlCode}
                  />
                </>
              )}
            </InforArea>

            {/* <CblackentialsInputText>명함</CblackentialsInputText>
        <CblackentialsInputInput
          placeholder="인증코드를 선택해주세요"
          value={datas?.code}
          onChange={(e) => setDatas({ ...datas, code: e.target.value })}
        /> */}

            <ProfileBtnArea>
              <SignupBtn onClick={_handleLogout} style={{ marginBottom: 10 }}>
                로그아웃
              </SignupBtn>
              <EditBtn onClick={_handleUpdate} style={{ marginBottom: 10 }}>수정</EditBtn>
              <SignupBtn onClick={_handleUnSign} style={{ marginBottom: 10 }}>
                회원탈퇴
              </SignupBtn>
            </ProfileBtnArea>
          </Wrapper>
        </div>
      </React.Fragment>
    </Fade>
  );
};

export default Profile;
