import { Modal, Button } from 'antd';
import { CircleChkIco } from "assets";
import React from 'react';
import styled from 'styled-components';

const P_MODAL = styled(Modal)`
.ant-modal-content {
  padding: 0px !important;
}
`;
const AlertModal = (props) => {
  const { open, close, header } = props;

  return (
    <P_MODAL
      style={{ padding: 0 }}
      visible={open}
      closable={false}
      footer={null}  // 버튼을 커스텀할 것이므로 기본 footer 제거
      centered  // 모달을 화면 중앙에 위치시킴
      bodyStyle={{
        height: 300,
        borderRadius: 10,
        padding: 0,
        position: 'relative',
      }}
    >
      <div style={{ background: "#000", height: 20, width: "100%" }} />
      <Button
        type="text"
        onClick={close}
        style={{
          fontSize: 30,
          position: "absolute",
          top: 25,
          right: 7,
        }}
      >&times;</Button>

      {/* <header>{header}</header> */}
      <img src={CircleChkIco} style={{
        width: 35,
        height: 35,
        position: "absolute",
        top: "25%",
        left: 0,
        right: 0,
        margin: "auto"
      }} />

      <div style={{
        position: "absolute",
        top: 0,
        bottom: -20,
        margin: "auto",
        height: "100px",
        width: "100%",
        border: 0,
        textAlign: "center",
        fontSize: 16
      }}>
        {props.children}
      </div>

      <Button
        type="primary"
        onClick={close}
        style={{
          background: "#ddd",
          color: "#000",
          width: 80,
          height: 45,
          fontSize: 15,
          position: "absolute",
          bottom: 50,
          margin: "auto",
          left: 0,
          right: 0,
          borderRadius: 10
        }}>
        확인
      </Button>

      <div style={{
        background: "#000",
        height: 10,
        width: "100%",
        position: "absolute",
        bottom: 0
      }} />
    </P_MODAL>
  );
};

export default AlertModal;
