import React, { Component, useEffect, useState } from "react";
import styled from "styled-components";

import PhoneImgPng from "phone.png";
import { Fade } from "react-reveal";
import { GetAllOrderList, GetInforMation } from "Datas/swr";
import { withRouter } from "react-router";
import moment from "moment";
import { updateOrder } from "Datas/api";

const Wrapper = styled.div``;

const TopLabel = styled.div``;

const Top = styled.div`
  border-bottom: 1px solid #eee;
  height: 70px;
  display: flex;
  align-items: center;

  width: 80%;
  margin: 0px auto;
  margin-top: 25px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  //padding: 0 50px 0 20px;
`;

const TopButton = styled.div`
  display: flex;
  margin-left: auto;
`;

const Add = styled.div`
  display: flex;
  background: #6091ed;
  padding: 10px 40px;
  color: #fff;
  margin-right: 20px;
  border-radius: 10px;
`;

const GuideLabel = styled.div``;

const GuideLabelBig = styled.div`
  font-size: 45px;
  margin-top: 40px;
  font-weight: 700;
  color: rgb(34, 37, 49);
`;

const GuideLabelSmall = styled.div`
  color: #838383;
`;

const PhoneImg = styled.div`
  & > img {
    width: 100%;
  }
  max-width: 111px;
  width: 100%;
  @media screen and (max-width: 450px) {
    max-width: 92px;
  }
  margin-right: 40px;
`;

const ContractInfo = styled.div`
  display: flex;
  margin-top: 75px;
  @media screen and (max-width: 450px) {
    margin-top: 50px;
  }
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`;

const PhoneInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% / 4 - 32px - 0.01px);

  @media screen and (max-width: 1600px) {
    width: 100%;
  }
`;

const PhoneInfoLabel = styled.div`
  border-bottom: 2px solid #d1d1d1;
  font-size: 30px;
  font-weight: 500;
  padding-bottom: 5px;
  display: flex;
  @media screen and (max-width: 450px) {
    font-size: 22px;
  }
`;

const MiddleWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 1600px) {
    width: 100%;
    flex-wrap: wrap;
    margin: 10px 0 30px 0;
  }
  width: calc(100% / 2 - 32px - 0.01px);

  & > div {
    width: calc(100% / 1 - 32px - 0.01px);
    margin-left: calc(32px / 1);
    margin-right: calc(32px / 1);
    @media screen and (max-width: 1600px) {
      width: 100%;
      margin: 0;
    }
  }
  & > div:last-child {
    @media screen and (max-width: 1600px) {
      margin-top: 30px;
    }
  }
`;

const PhoneInfo = styled.div`
  display: flex;
  margin-top: 20px;
`;

const PhoneInfoRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
`;

const PhoneInfoRow = styled.div`
  display: flex;
  align-items: center;
  margin: 2px 0;
`;

const PhoneInfoRowKey = styled.div`
  font-size: 18px;
  margin-right: 20px;
  color: #838383;

  @media screen and (max-width: 450px) {
    font-size: 16px;
  }
`;

const PhoneInfoRowValue = styled.div`
  margin-left: auto;
  font-size: 20px;
  @media screen and (max-width: 450px) {
    font-size: 15px;
  }
`;

const PhoneInfoMonthlyPrice = styled.div`
  margin-top: 20px;
`;

const PhoneInfoMonthlyPriceOriginal = styled.div`
  display: flex;
  & > span {
    font-size: 20px;
    @media screen and (max-width: 450px) {
      font-size: 18px;
    }
  }
  & > span:last-child {
    margin-left: auto;
  }
`;

const PhoneInfoMonthlyPriceRate = styled.div`
  margin-top: 20px;
  display: flex;
  & > span {
    font-size: 20px;
    @media screen and (max-width: 450px) {
      font-size: 18px;
    }
  }
  & > span:last-child {
    margin-left: auto;
  }
`;

const PhoneInfoReleasePrice = styled.div`
  display: flex;
  padding: 0 10px;
  margin: 5px 0;
  & > span {
    color: #ef522a;
    font-size: 16px;
  }
  & > span:last-child {
    margin-left: auto;
  }
`;

const PhoneInfoReleasePriceDiscount = styled.div`
  display: flex;
  padding: 0 10px;
  margin: 5px 0;
  & > span {
    color: #ef522a;
    font-size: 16px;
  }
  & > span:last-child {
    margin-left: auto;
  }
`;

const PhoneInfoSuperDC = styled.div`
  display: flex;
  padding: 0 10px;
  margin: 5px 0;
  & > span {
    font-size: 16px;
  }
  & > span:last-child {
    margin-left: auto;
  }
`;

const PhoneInfoMonthlyPriceTotal = styled.div`
  background: #ef522a;
  margin-top: 10px;
  display: flex;
  padding: 8px 12px;
  font-weight: 500;
  border-radius: 10px;
  color: #fff;
  & > span {
    font-size: 16px;
  }
  & > span:last-child {
    margin-left: auto;
  }
`;

const PhoneInfoMonthlyCharge = styled.div`
  margin-top: 20px;
  font-size: 20px;
  @media screen and (max-width: 450px) {
    font-size: 18px;
  }
`;

const PhoneInfoMonthlyChargeReal = styled.div`
  font-size: 40px;
  color: #ef522a;
  font-weight: 500;
  margin-left: auto;
  @media screen and (max-width: 450px) {
    font-size: 32px;
  }
`;

const PhoneInfoMonthlyChargeMessage = styled.div`
  color: #5f5f5f;
  font-size: 15px;

  & > span {
    display: block;
  }
  & > span:first-child {
    margin-top: 25px;
  }
`;

const Phone5G = styled.div`
  display: flex;
  margin-left: auto;
  background: #ffb43c;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  padding: 3px 16px;
  font-size: 20px;
  color: #fff;

  @media screen and (max-width: 450px) {
    font-size: 11px;
  }
`;

const CostumerInfo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  margin: 0 auto;
  margin-top: 129px;
  width: 100%;
`;

const CostumerInfoLabel = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 15px;
`;

const AddrInfo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  margin: 0 auto;
  margin-top: 129px;
  width: 100%;
`;

const AddrInfoLabel = styled.div`
  font-size: 35px;
  color: rgb(34, 37, 49);
  margin-bottom: 40px;
`;

const TwoInputWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const DefaultInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  width: 100%;
  max-width: 550px;
`;

const DefaultInputLabel = styled.div`
  font-size: 25px;
  font-weight: 700;
  white-space: nowrap;
  color: #838383;

  @media screen and (max-width: 450px) {
    font-size: 18px;
  }
`;

const DefaultInput = styled.input`
  width: 100%;

  background-color: transparent;
  border: none;

  //margin-top: 5px;
  //height: 65px;
  //border: 2px solid #f0f0f0;
  //border-radius: 10px;
  color: #000;
  font-size: 16px;
  padding: 10px;

  @media screen and (max-width: 450px) {
    height: 45px;
  }
`;

const AdultOrNot = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 450px) {
    height: 45px;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 5px;
    height: 65px;
    color: #636363;
    font-weight: 700;
    border: 2px solid #f0f0f0;
    border-radius: 10px;
    font-size: 16px;
    max-width: 250px;
    padding: 10px;
  }
  @media screen and (max-width: 450px) {
    & > div:first-child {
      margin-right: 25px;
    }
    & > div {
      height: 50px;
    }
  }
`;

const AddrInputWrapperLabel = styled.div`
  font-size: 25px;
  font-weight: 700;
  white-space: nowrap;
  margin-top: 10px;
  color: #838383;

  @media screen and (max-width: 450px) {
    font-size: 18px;
  }
`;

const AddrInputWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
`;
const RightTable = styled.tr`
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;
const MainContainer = styled.div`
  width: 80%;
  margin: 0px auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const AddrInputTop = styled.div`
  display: flex;
  // align-items: center;
`;

const AddrInput = styled.input`
  width: 100%;
  margin-top: 5px;
  height: 65px;
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  color: #000;
  font-size: 16px;
  padding: 10px;

  @media screen and (max-width: 450px) {
    height: 45px;
  }
`;

const AddrSearch = styled.div`
  background: #ef522a;
  color: #fff;
  padding: 8px 16px;
  border-radius: 10px;
  white-space: nowrap;
  font-weight: 700;
  display: flex;
  margin-left: 20px;
  align-items: center;
  justify-content: center;
`;

const AddrDetailed = styled.div``;

const CostumerRequire = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
`;

const CostumerRequireLabel = styled.div`
  font-size: 25px;
  font-weight: 700;
  color: #838383;
  margin-top: 15px;

  @media screen and (max-width: 450px) {
    font-size: 18px;
  }
`;

const CostumerRequireTextarea = styled.textarea`
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  resize: none;
  height: 200px;
  font-size: 16px;
  padding: 8px 20px;
  margin-top: 5px;
`;

const BigAgree = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const BigAgreeAgreeText = styled.div`
  margin-left: 5px;
`;

const BigAgreeAgree = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const BigAgreeAgreeInput = styled.input`
  -webkit-appearance: auto;
`;

const Agree = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

const AgreeText = styled.div`
  display: flex;
  align-items: center;
  & > span:first-child {
    font-size: 20px;
    margin-left: 5px;
    @media screen and (max-width: 450px) {
      font-size: 14px;
    }
  }
  & > span:last-child {
    font-size: 15px;
    @media screen and (max-width: 450px) {
      font-size: 11px;
    }
    color: #969696;
    margin-left: 5px;
  }
`;

const AgreeAgree = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const AgreeAgreeInput = styled.input`
  -webkit-appearance: auto;
`;

const AgreeAgreeText = styled.div`
  margin-left: 5px;
  @media screen and (max-width: 450px) {
    font-size: 14px;
  }
`;

const BigAgreeText = styled.div`
  font-size: 28px;
  font-weight: 500;
  color: rgb(34, 37, 49);

  @media screen and (max-width: 450px) {
    font-size: 24px;
  }
`;

const AgreeSection = styled.div`
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  margin-top: 100px;
`;

const SignupBtn = styled.div`
  width: 100%;
  height: 65px;
  max-width: 1300px;
  margin: 0 auto;
  color: #fff;
  background: #ef522a;
  border-radius: 10px;
  margin-top: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  @media screen and (max-width: 450px) {
    height: 45px;
    font-size: 18px;
  }
  margin-bottom: 80px;
`;

const Content = styled.div``;

const VerifyIf = styled.div`
  display: flex;
  flex-direction: column;
  //max-width: 1300px;
  margin: 0 auto 25px auto;
  //margin-top: 129px;
  width: 100%;
`;

const VerifyIfLabel = styled.div`
  font-size: 30px;
  font-weight: 500;
  padding-bottom: 5px;
  display: flex;
  @media screen and (max-width: 450px) {
    font-size: 22px;
  }
`;

const VerifyIfLabelBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const VerifyIfLabelBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  padding: 5px 10px;
  padding: 11px 40px;
  margin-right: 20px;
  border-radius: 7px;
  border: 2px solid #eee;
  color: #565656;
  @media screen and (max-width: 768px) {
    padding: 5px 8px;
    padding: 5px 8px;
  }
`;

const ContractDetail = ({ match, location, history, profile }) => {
  const { allOrderListData, allOrderListMutate } = GetAllOrderList(0, "전체");
  const { InfoData, InfoDataMutate } = GetInforMation();

  const [data, setData] = useState({
    cardname: "",
    cardsale: "",
    color: "",
    hard: "",
    store: "",
    nowtel: "",
    willtell: "",
    zip: "",
    zipinfo: "",
    monthuse: "",
    productname: "",
    saletype: "",
    marketprice: "",
    openprice: "",
    dcprice: "",
    monthprice: "",
    salemonth: "",
    pricetype: "",
    usim: "",
    username: "",
    receiver: "", //받는사람
    receivercall: "", //받는사람연락처
    DLCode: "", //딜러코드
    opentel: "", //개통할번호
    tel: "", //추가연락처
    memo: "",
    child: "", //미성년자 여부
    pairbirth: "", //법정 대리인 생년월일
    pairinfo: "", //법정 대리인 관계
    pairname: "",
    pairtel: "",
    birthday: "",
    pricetypeprice: "",
    infophone: "",
    isvalidate: "",
    memoadmin: "",
    newPrice: "",
  });

  const _handleUpdate = async () => {
    let body = {
      memoadmin: data.memoadmin, //관리자메모
      receivercall: data.receivercall, //배송 연락처
      opentel: data.opentel, //개통 연락처
      tel: data.tel, //추가 연락처
    };
    await updateOrder(body, location?.state?.id);
    history.goBack();
  };
  useEffect(() => {
    if (allOrderListData !== undefined) {
      /* let res = allOrderListData?.filter(
        (el, idx) => Number(el?.id) === Number(match?.params?.id)
      ); */
      console.log(location.state);
      setData({
        cardname: location?.state?.OrderProduct?.cardname,
        cardsale: location?.state?.OrderProduct?.cardsale,
        color: location?.state?.OrderProduct?.colorname,
        hard: location?.state?.OrderProduct?.hard,
        store: location?.state?.User?.Store?.name,
        nowtel: location?.state?.OrderProduct?.nowtel,
        willtell: location?.state?.OrderProduct?.willtell,
        zip: location?.state?.zip,
        zipinfo: location?.state?.zipinfo,
        monthuse: location?.state?.OrderProduct?.monthuse,
        productname: location?.state?.OrderProduct?.productname,
        saletype: location?.state?.OrderProduct?.saletype,
        marketprice: location?.state?.OrderProduct?.marketprice,
        openprice: location?.state?.OrderProduct?.openprice,
        dcprice: location?.state?.OrderProduct?.dcprice,
        monthprice: location?.state?.OrderProduct?.monthprice,
        salemonth: location?.state?.OrderProduct?.salemonth?.toString(),
        pricetype: location?.state?.OrderProduct.pricetype,
        usim: location?.state?.usim,
        username: location?.state?.name,
        receiver: location?.state?.receiver, //받는사람
        receivercall: location?.state?.receivercall, //받는사람연락처
        infophone: location?.state?.OrderProduct?.infophone,
        opentel: location?.state?.tel, //개통할번호
        tel: location?.state?.tel1, //추가연락처
        memo: location?.state?.memo,
        child: location?.state?.child, //미성년자 여부
        pairbirth: location?.state?.pairbirth, //법정 대리인 생년월일
        pairinfo: location?.state?.pairinfo, //법정 대리인 관계
        pairname: location?.state?.pairname,
        pairtel: location?.state?.pairtel,
        newPrice: location?.state?.newPrice,
        birthday: location?.state?.birthday,
        pricetypeprice: location?.state?.OrderProduct?.pricetypeprice,
        isvalidate: location?.state?.isvalidate,
        memoadmin: location?.state?.memoadmin,
        DLCode: location?.state?.DLCode,
      });
    }
  }, [allOrderListData]);
  return (
    <Fade Button>
      <Wrapper>
        <Top>
          <TopLabel>개통 신청서 확인</TopLabel>
          {profile.gm > 0 && (
            <TopButton>
              <Add onClick={() => _handleUpdate()}>수정</Add>
            </TopButton>
          )}
        </Top>

        <Content style={{ width: "100%" }}>
          <MainContainer>
            <VerifyIf>
              <CostumerInfoLabel>승인여부</CostumerInfoLabel>
              <VerifyIfLabelBtnWrapper>
                <VerifyIfLabelBtn
                  style={
                    data?.isvalidate === 0
                      ? {
                          backgroundColor: "orange",
                          color: "white",
                          fontWeight: "bold",
                        }
                      : { backgroundColor: "" }
                  }
                >
                  대기중
                </VerifyIfLabelBtn>
                <VerifyIfLabelBtn
                  style={
                    data?.isvalidate === 11
                      ? {
                          backgroundColor: "orange",
                          color: "white",
                          fontWeight: "bold",
                        }
                      : { backgroundColor: "" }
                  }
                >
                  개통중
                </VerifyIfLabelBtn>
                <VerifyIfLabelBtn
                  style={
                    data?.isvalidate === 1
                      ? {
                          backgroundColor: "orange",
                          color: "white",
                          fontWeight: "bold",
                        }
                      : { backgroundColor: "" }
                  }
                >
                  해피콜 완료
                </VerifyIfLabelBtn>
                <VerifyIfLabelBtn
                  style={
                    data?.isvalidate === 2
                      ? {
                          backgroundColor: "orange",
                          color: "white",
                          fontWeight: "bold",
                        }
                      : { backgroundColor: "" }
                  }
                >
                  서식지 완료
                </VerifyIfLabelBtn>
                <VerifyIfLabelBtn
                  style={
                    data?.isvalidate === 3
                      ? {
                          backgroundColor: "orange",
                          color: "white",
                          fontWeight: "bold",
                        }
                      : { backgroundColor: "" }
                  }
                >
                  기변 개통 완료
                </VerifyIfLabelBtn>
                <VerifyIfLabelBtn
                  style={
                    data?.isvalidate === 4
                      ? {
                          backgroundColor: "orange",
                          color: "white",
                          fontWeight: "bold",
                        }
                      : { backgroundColor: "" }
                  }
                >
                  상품 준비중
                </VerifyIfLabelBtn>
                <VerifyIfLabelBtn
                  style={
                    data?.isvalidate === 5
                      ? {
                          backgroundColor: "orange",
                          color: "white",
                          fontWeight: "bold",
                        }
                      : { backgroundColor: "" }
                  }
                >
                  배송중
                </VerifyIfLabelBtn>
                <VerifyIfLabelBtn
                  style={
                    data?.isvalidate === 6
                      ? {
                          backgroundColor: "orange",
                          color: "white",
                          fontWeight: "bold",
                        }
                      : { backgroundColor: "" }
                  }
                >
                  배송완료
                </VerifyIfLabelBtn>
                <VerifyIfLabelBtn
                  style={
                    data?.isvalidate === 7
                      ? {
                          backgroundColor: "orange",
                          color: "white",
                          fontWeight: "bold",
                        }
                      : { backgroundColor: "" }
                  }
                >
                  개통완료
                </VerifyIfLabelBtn>
                <VerifyIfLabelBtn
                  style={
                    data?.isvalidate === 8
                      ? {
                          backgroundColor: "orange",
                          color: "white",
                          fontWeight: "bold",
                        }
                      : { backgroundColor: "" }
                  }
                >
                  교품중
                </VerifyIfLabelBtn>
                <VerifyIfLabelBtn
                  style={
                    data?.isvalidate === 9
                      ? {
                          backgroundColor: "orange",
                          color: "white",
                          fontWeight: "bold",
                        }
                      : { backgroundColor: "" }
                  }
                >
                  교품 회수
                </VerifyIfLabelBtn>
                <VerifyIfLabelBtn
                  style={
                    data?.isvalidate === 10
                      ? {
                          backgroundColor: "orange",
                          color: "white",
                          fontWeight: "bold",
                        }
                      : { backgroundColor: "" }
                  }
                >
                  주문 취소
                </VerifyIfLabelBtn>
              </VerifyIfLabelBtnWrapper>
            </VerifyIf>
            <CostumerInfoLabel>가입자 정보</CostumerInfoLabel>
            <table
              id="customers"
              style={{
                marginTop: 24,
                width: "100%",
              }}
            >
              <RightTable>
                <td>관리자 메모</td>
                <td>
                  <DefaultInput
                    value={data?.memoadmin}
                    onChange={(e) => {
                      setData({ ...data, memoadmin: e.target.value });
                    }}
                    placeholder="괸리자 메모"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>명의자 성함</td>
                <td>
                  <DefaultInput
                    value={data?.username}
                    onChange={(e) => {
                      setData({ ...data, username: e.target.value });
                    }}
                    placeholder="아이디를 입력해주세요"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>개통할 휴대폰번호</td>
                <td>
                  <DefaultInput
                    value={data?.opentel}
                    onChange={(e) => {
                      setData({ ...data, opentel: e.target.value });
                    }}
                    placeholder="입력해주세요"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>추가연락처</td>
                <td>
                  <DefaultInput
                    value={data?.tel}
                    onChange={(e) => {
                      setData({ ...data, tel: e.target.value });
                    }}
                    placeholder="입력해주세요"
                  />
                </td>
              </RightTable>

              <RightTable>
                <td>성인 / 미성년자 여부</td>
                <td style={{ padding: "10px 15px" }}>
                  {data?.child ? "미성년자" : "성인"}
                </td>
              </RightTable>
              <RightTable>
                <td>구매자 생년월일</td>
                <td style={{ padding: "10px 15px" }}>
                  {moment(data?.birthday).format("YYYY년MM월DD일")}
                </td>
              </RightTable>
              {data?.child && (
                <>
                  <RightTable>
                    <td>법정대리인 성명</td>
                    <td style={{ padding: "10px 15px" }}>{data?.pairname}</td>
                  </RightTable>
                  <RightTable>
                    <td>법정대리인 생년월일</td>
                    <td style={{ padding: "10px 15px" }}>
                      {moment(data?.pairbirth).format("YYYY-MM-DD")}
                    </td>
                  </RightTable>
                  <RightTable>
                    <td>법정대리인 전화번호</td>
                    <td style={{ padding: "10px 15px" }}>{data?.pairtel}</td>
                  </RightTable>
                  <RightTable>
                    <td>법정대리인 관계</td>
                    <td style={{ padding: "10px 15px" }}>{data?.pairinfo}</td>
                  </RightTable>
                </>
              )}

              <RightTable>
                <td>개통 가맹점</td>
                <td>
                  <DefaultInput value={data?.store} disabled={true} />
                </td>
              </RightTable>
              <RightTable>
                <td>소속 딜러</td>
                <td>
                  <DefaultInput
                    value={data?.DLCode ? data.DLCode : "가맹점 개통"}
                    disabled={true}
                  />
                </td>
              </RightTable>
            </table>
            <CostumerInfoLabel style={{ marginTop: 25 }}>
              배송지 정보
            </CostumerInfoLabel>
            <table
              id="customers"
              style={{
                marginTop: 24,
                width: "100%",
              }}
            >
              <RightTable>
                <td>받으시는 분</td>
                <td>
                  <DefaultInput
                    value={data?.receiver}
                    onChange={(e) => {
                      setData({ ...data, receiver: e.target.value });
                    }}
                    placeholder="입력해주세요"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>배송 연락처</td>
                <td>
                  <DefaultInput
                    value={data?.receivercall}
                    onChange={(e) => {
                      setData({ ...data, receivercall: e.target.value });
                    }}
                    placeholder="입력해주세요"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>배송지 주소</td>
                <td>
                  <DefaultInput
                    value={data?.zip}
                    disabled={true}
                    placeholder="주소를 입력해주세요"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>상세주소</td>
                <td>
                  <DefaultInput
                    value={data?.zipinfo}
                    disabled={true}
                    placeholder="상세주소를 입력해주세요"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>고객요청사항</td>
                <td>
                  <DefaultInput
                    value={data?.memo}
                    disabled={true}
                    placeholder="요청사항을 입력해주세요"
                  />
                </td>
              </RightTable>
            </table>

            <CostumerInfoLabel style={{ marginTop: 25 }}>
              상품 정보11
            </CostumerInfoLabel>
            <table
              id="customers"
              style={{
                marginTop: 24,
                width: "100%",
              }}
            >
              <RightTable>
                <td>상품 명</td>
                <td>
                  <DefaultInput
                    value={data?.productname}
                    disabled={true}
                    placeholder="입력해주세요"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>사용중인 통신사</td>
                <td>
                  <DefaultInput
                    value={data?.nowtel}
                    disabled={true}
                    placeholder="입력해주세요"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>사용할 통신사</td>
                <td>
                  <DefaultInput
                    value={data?.willtell}
                    disabled={true}
                    placeholder="주소를 입력해주세요"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>색상</td>
                <td>
                  <DefaultInput
                    value={data?.color}
                    disabled={true}
                    placeholder="주소를 입력해주세요"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>용량</td>
                <td>
                  <DefaultInput
                    value={data?.hard}
                    disabled={true}
                    placeholder="주소를 입력해주세요"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>상품권</td>
                <td>
                  <DefaultInput
                    value={data?.saletype}
                    disabled={true}
                    placeholder="상세주소를 입력해주세요"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>요금제</td>
                <td>
                  <DefaultInput
                    value={data?.pricetype}
                    disabled={true}
                    placeholder="요청사항을 입력해주세요"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>구매방법</td>
                <td>
                  <DefaultInput
                    value={data?.monthuse}
                    disabled={true}
                    placeholder="요청사항을 입력해주세요"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>유심선택</td>
                <td>
                  <DefaultInput
                    value={data.usim ? "새 유심" : "기존 유심"}
                    disabled={true}
                    placeholder="요청사항을 입력해주세요"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>할부원금</td>
                <td>
                  <DefaultInput
                    value={Number(data.salemonth).toLocaleString()}
                    disabled={true}
                    placeholder="요청사항을 입력해주세요"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>출고가</td>
                <td>
                  <DefaultInput
                    value={data?.marketprice}
                    disabled={true}
                    placeholder="요청사항을 입력해주세요"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>
                  {data?.openprice.indexOf("공시지원") !== -1
                    ? "공시지원할인 "
                    : "선택약정할인"}
                </td>
                <td>
                  <DefaultInput
                    value={data?.openprice}
                    disabled={true}
                    placeholder="요청사항을 입력해주세요"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>{InfoData !== undefined && InfoData.pagename} D.C</td>
                <td>
                  <DefaultInput
                    value={data?.dcprice}
                    disabled={true}
                    placeholder="요청사항을 입력해주세요"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>제휴카드</td>
                <td>
                  <DefaultInput
                    value={data?.cardname}
                    disabled={true}
                    placeholder="등록한 제휴카드가 없습니다"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>제휴카드 할인금</td>
                <td>
                  <DefaultInput
                    value={`월${data?.cardsale} x 24개월 = ${
                      Number(data?.cardsale) * 24
                    }`}
                    disabled={true}
                  />
                </td>
              </RightTable>
              {data?.nowtel === "신규가입" && (
                <RightTable>
                  <td>신규가입 할인금</td>
                  <td>
                    <DefaultInput
                      value={`${Number(data?.newPrice).toLocaleString()}원`}
                      disabled={true}
                    />
                  </td>
                </RightTable>
              )}
              <RightTable>
                <td>부가서비스</td>
                <td>
                  <DefaultInput
                    value={data?.infophone}
                    disabled={true}
                    placeholder="요청사항을 입력해주세요"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>특판할인</td>
                <td>
                  <DefaultInput
                    value={`${Number(data?.pricetypeprice).toLocaleString()}원`}
                    disabled={true}
                    placeholder="요청사항을 입력해주세요"
                  />
                </td>
              </RightTable>
              <RightTable>
                <td>월 실 납부액</td>
                <td>
                  <DefaultInput
                    value={`월${data?.monthprice} `}
                    disabled={true}
                    placeholder="요청사항을 입력해주세요"
                  />
                </td>
              </RightTable>
            </table>
          </MainContainer>{" "}
        </Content>

        <Content>
          {/* <GuideLabel>
              <GuideLabelBig>개통신청서</GuideLabelBig>
              <GuideLabelSmall>
                신청정보 및 배송정보 입력 후 온라인 공식신청서 작성을
                완료해주시기 바랍니다.
              </GuideLabelSmall>
            </GuideLabel> */}

          {/* <ContractInfo>
              <PhoneInfoWrapper>
                <PhoneInfoLabel>
                  {data?.productname}<Phone5G>5G</Phone5G>
                </PhoneInfoLabel>
                <PhoneInfo>
                  <PhoneImg>
                    <img alt="" src={PhoneImgPng} />
                  </PhoneImg>
                  <PhoneInfoRowWrapper>
                    <PhoneInfoRow>
                      <PhoneInfoRowKey>사용중인 통신사</PhoneInfoRowKey>
                      <PhoneInfoRowValue>{data?.nowtel}</PhoneInfoRowValue>
                    </PhoneInfoRow>
                    <PhoneInfoRow>
                      <PhoneInfoRowKey>사용할 통신사</PhoneInfoRowKey>
                      <PhoneInfoRowValue>{data?.willtell}</PhoneInfoRowValue>
                    </PhoneInfoRow>
                    <PhoneInfoRow>
                      <PhoneInfoRowKey>할인방법</PhoneInfoRowKey>
                      <PhoneInfoRowValue>{data?.saletype}</PhoneInfoRowValue>
                    </PhoneInfoRow>
                    <PhoneInfoRow>
                      <PhoneInfoRowKey>요금제</PhoneInfoRowKey>
                      <PhoneInfoRowValue>{data?.pricetype}</PhoneInfoRowValue>
                    </PhoneInfoRow>
                    <PhoneInfoRow>
                      <PhoneInfoRowKey>구매방법</PhoneInfoRowKey>
                      <PhoneInfoRowValue>
                        {data?.monthuse}
                      </PhoneInfoRowValue>
                    </PhoneInfoRow>
                    <PhoneInfoRow>
                      <PhoneInfoRowKey>유심선택</PhoneInfoRowKey>
                      <PhoneInfoRowValue>{data.usim ? '새 유심':'기존 유심'}</PhoneInfoRowValue>
                    </PhoneInfoRow>
                  </PhoneInfoRowWrapper>
                </PhoneInfo>
              </PhoneInfoWrapper>
              <MiddleWrapper>
                <PhoneInfoWrapper>
                  <PhoneInfoLabel>월 할부금</PhoneInfoLabel>
                  <PhoneInfoMonthlyPrice>
                    <PhoneInfoMonthlyPriceOriginal>
                      <span>할부원금</span>
                      <span>{data.salemonth}원</span>
                    </PhoneInfoMonthlyPriceOriginal>
                    <PhoneInfoReleasePrice>
                      <span>출고가</span>
                      <span>{data?.marketprice}원</span>
                    </PhoneInfoReleasePrice>
                    <PhoneInfoReleasePriceDiscount>
                      <span>{data?.openprice.indexOf('공시지원') !== -1 ? '공시지원할인 ' : '선택약정할인'}</span>
                      <span>{data?.openprice}</span>
                    </PhoneInfoReleasePriceDiscount>
                    <PhoneInfoSuperDC>
                      <span>당근폰 슈퍼 D.C</span>
                      <span>{data?.dcprice}원</span>
                    </PhoneInfoSuperDC>
                    <PhoneInfoMonthlyPriceTotal>
                      <span>월 ({data?.monthuse})</span>
                      <span>{data?.monthuse ==='일시불' ? data.salemonth :  Math.round(Number(data?.salemonth?.replace('개월',''))/ Number(data.salemonth))  }원</span>
                    </PhoneInfoMonthlyPriceTotal>
                  </PhoneInfoMonthlyPrice>
                </PhoneInfoWrapper>
                <PhoneInfoWrapper>
                  <PhoneInfoLabel>월 통신요금</PhoneInfoLabel>
                  <PhoneInfoMonthlyPriceRate>
                    <span>요금제</span>
                    <span>{data?.pricetypeprice}원</span>
                  </PhoneInfoMonthlyPriceRate>
                  <PhoneInfoMonthlyPriceTotal>
                    <span>월</span>
                    <span>{data?.pricetypeprice}원</span>
                  </PhoneInfoMonthlyPriceTotal>
                </PhoneInfoWrapper>
              </MiddleWrapper>
              <PhoneInfoWrapper>
                <PhoneInfoLabel>월 실 납부액</PhoneInfoLabel>
                <PhoneInfoMonthlyCharge>월 청구액</PhoneInfoMonthlyCharge>
                <PhoneInfoMonthlyChargeReal>
                {data?.monthprice}원
                </PhoneInfoMonthlyChargeReal>
                <PhoneInfoMonthlyChargeMessage>
                  <span>* 부가세·할부이자 연 5.9% 포함 금액</span>
                  <br />
                  <span>
                    실제로 청구되는 금액으로 추가로 청구되실 금액 없습니다.
                  </span>
                </PhoneInfoMonthlyChargeMessage>
              </PhoneInfoWrapper>
            </ContractInfo> */}

          {/* <VerifyIf>
              <VerifyIfLabel>승인여부</VerifyIfLabel>
              <VerifyIfLabelBtnWrapper>
                <VerifyIfLabelBtn>취소</VerifyIfLabelBtn>
                <VerifyIfLabelBtn style={data?.isvalidate !==1 ?{backgroundColor:"orange",color:'white',fontWeight:'bold'} : {backgroundColor:''}}>진행중</VerifyIfLabelBtn>
                <VerifyIfLabelBtn style={data?.isvalidate ===1 ?{backgroundColor:"orange",color:'white',fontWeight:'bold'} : {backgroundColor:''}}>완료</VerifyIfLabelBtn>
              </VerifyIfLabelBtnWrapper>
            </VerifyIf> */}

          {/* <CostumerInfo>
              <CostumerInfoLabel>가입자 정보</CostumerInfoLabel>
              <TwoInputWrapper>

                <DefaultInputWrapper>
                  <DefaultInputLabel>명의자 성함</DefaultInputLabel>
                  <DefaultInput value={data?.username}  disabled={true} placeholder="아이디를 입력해주세요" />
                </DefaultInputWrapper>
              </TwoInputWrapper>
              <TwoInputWrapper>
                <DefaultInputWrapper>
                  <DefaultInputLabel>개통할 휴대폰번호</DefaultInputLabel>
                  <DefaultInput value={data?.opentel}  disabled={true} placeholder="입력해주세요" />
                </DefaultInputWrapper>
                <DefaultInputWrapper>
                  <DefaultInputLabel>추가연락처</DefaultInputLabel>
                  <DefaultInput value={data?.tel}  disabled={true} placeholder="입력해주세요" />
                </DefaultInputWrapper>
              </TwoInputWrapper>
              <TwoInputWrapper>
                <DefaultInputWrapper>
                  <DefaultInputLabel>이메일</DefaultInputLabel>
                  <DefaultInput value={data?.email}  disabled={true} placeholder="입력해주세요" />
                </DefaultInputWrapper>
                <DefaultInputWrapper>
                  <DefaultInputLabel>성인 / 미성년자 여부</DefaultInputLabel>
                  <AdultOrNot>
                    <div style={data.child ? { backgroundColor: '' } : {backgroundColor:'orange',color:'white',fontWeight:'bold'} }>성인</div>
                    <div style={data.child ===false ? { backgroundColor: '' } : {backgroundColor:'orange',color:'white'} }>미성년자</div>
                  </AdultOrNot>
                </DefaultInputWrapper>
              </TwoInputWrapper>
            </CostumerInfo> */}

          {/* <AddrInfo>
              <AddrInfoLabel>배송지 정보</AddrInfoLabel>
              <TwoInputWrapper>
                <DefaultInputWrapper>
                  <DefaultInputLabel>받으시는 분</DefaultInputLabel>
                  <DefaultInput value={data?.receiver}  disabled={true}  placeholder="입력해주세요" />
                </DefaultInputWrapper>
                <DefaultInputWrapper>
                  <DefaultInputLabel>배송 연락처</DefaultInputLabel>
                  <DefaultInput  value={data?.receivercall}  disabled={true} placeholder="입력해주세요" />
                </DefaultInputWrapper>
              </TwoInputWrapper>
              <AddrInputWrapper>
                <AddrInputWrapperLabel>배송지 주소</AddrInputWrapperLabel>
                <AddrInputTop>
                  <AddrInput value={data?.zip}  disabled={true} placeholder="주소를 입력해주세요" />

                </AddrInputTop>
                <AddrDetailed>
                  <AddrInput value={data?.zipinfo}  disabled={true} placeholder="상세주소를 입력해주세요" />
                </AddrDetailed>
              </AddrInputWrapper>
            </AddrInfo> */}

          {/* <CostumerRequire>
              <CostumerRequireLabel>고객요청사항</CostumerRequireLabel>
              <CostumerRequireTextarea value={data?.memo}  disabled={true} placeholder="요청사항을 입력해주세요" />
            </CostumerRequire> */}
        </Content>
      </Wrapper>
    </Fade>
  );
};

export default withRouter(ContractDetail);
