import { GetTermList } from "Datas/swr";
import React, { Component } from "react";
import { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";

const Wrapper = styled.div``;

const Inner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 570px;
  padding: 0 20px;
`;

const BigAgree = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const BigAgreeAgreeText = styled.div`
  margin-left: 5px;
  margin-top: 2px;
`;

const BigAgreeAgree = styled.label`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const BigAgreeAgreeInput = styled.input`
  -webkit-appearance: auto;
  width: 16px;
  height: 16px;
`;

const Agree = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0;
  @media(max-width:500px){
    align-items: flex-start;
  }
`;

const AgreeText = styled.div`
  font-size: 16px;
  display: flex;
  align-items: center;
  @media(max-width: 500px) {
    & > span:first-child {
      font-size: 14px;
    }
    align-items: flex-start;
  }
  & > span:last-child {
    font-size: 13px;
    color: #666;
    margin-left: 20px;
    border: 1px solid #ccc;
    padding: 3px 0 1px;
    border-radius: 3px;
    margin-top: -3px;
    min-width: 81px;
    max-height: 25px;
    margin-right: 20px;
    letter-spacing: -.5px;
    text-align: center;
  }
`;

const AgreeAgree = styled.label`
  display: flex;
  align-items: center;
  margin-left: auto;
  justify-content: center;
  min-width: 49px;
`;

const AgreeAgreeInput = styled.input`
  -webkit-appearance: auto;
  width: 16px;
  height: 16px;
`;

const AgreeAgreeText = styled.div`
  margin: 2px -10px 0 5px;
  min-width: 38px;
`;

const BigAgreeText = styled.div`
  font-size: 25px;
  font-weight: 700;

  @media screen and (max-width: 450px) {
    font-size: 24px;
  }
`;

const SignupBtn = styled.div`
  width: 100%;
  height: 65px;
  color: #fff;
  background: #feb43c;
  border: 1px solid #feb43c;
  border-radius: 10px;
  margin-top: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  @media screen and (max-width: 450px) {
    height: 45px;
    font-size: 18px;
  }
  margin-bottom: 80px;
`;

const AgreeBox = styled.textarea`
  padding: 16px;
  width: 100%;
  height: 190px;
  padding: 15px 20px;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  overflow-y: scroll;
  font-size: 14px;
  line-height: 20px;
  margin-top: 5px;
`;

const TermsAgree = ({ SignUp, setAcct, acct, termstype }) => {
  const { TermListData, TermListMutate, isLoading } = GetTermList(0, termstype);

  const [listchecked, setChecked] = useState(false);
  useEffect(() => {
    if (!isLoading) {
      setAcct(TermListData?.rows);
    }
  }, [TermListData]);

  const __handleSelect = (idx) => {
    let datas = [...acct];
    datas[idx].validate = !datas[idx].validate;
    setAcct(datas);
  };

  useEffect(() => {
    let res = acct?.findIndex((item, idx) => {
      return item.agreed === !true;
    });
    console.log(res);
    if (res === -1) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [acct]);

  const _handleAllCheck = () => {
    let datas = [...acct];


    if (listchecked) {
      for (let i = 0; i < datas.length; i++) {
        datas[i].agreed = false;
      }
    } else {
      for (let i = 0; i < datas.length; i++) {
        datas[i].agreed = true;
      }
    }
    setAcct(datas);
  };

  return (
    <Wrapper style={SignUp && { position: "relative", marginTop: 54 }}>
      {SignUp ? (
        <>
          <BigAgree>
            <BigAgreeText>약관 및 이용 동의</BigAgreeText>
            <BigAgreeAgree for="checkedAll">
              <BigAgreeAgreeInput
                id="checkedAll"
                onClick={() => {
                  _handleAllCheck();
                }}
                checked={listchecked}
                type="checkbox"
              />
              <BigAgreeAgreeText>전체 동의</BigAgreeAgreeText>
            </BigAgreeAgree>
          </BigAgree>
          {acct?.map((item, idx) => {
            return (
              <>
                <Agree>
                  <AgreeText>
                    <span>{item?.title}</span>
                    <span
                      onClick={() => {
                        __handleSelect(idx);
                      }}
                    >
                      전체보기
                    </span>
                  </AgreeText>
                  <AgreeAgree for={`check${idx}`}>
                    <AgreeAgreeInput
                      id={`check${idx}`}
                      onClick={() => {
                        let datas = [...acct];
                        datas[idx].agreed = !acct[idx].agreed;
                        setAcct(datas);
                      }}
                      checked={item.agreed}
                      type="checkbox"
                    />
                    <AgreeAgreeText>동의</AgreeAgreeText>
                  </AgreeAgree>
                </Agree>
                {acct[idx].validate === true && (
                  <AgreeBox readOnly={true} value={item?.contents}></AgreeBox>
                )}
              </>
            );
          })}
        </>
      ) : (
        <Inner>
          <BigAgree>
            <BigAgreeText>약관 및 이용 동의</BigAgreeText>
            <BigAgreeAgree for="checkedAll">
              <BigAgreeAgreeInput
                id="checkedAll"
                onClick={() => {
                  _handleAllCheck();
                }}
                checked={listchecked}
                type="checkbox"
              />
              <BigAgreeAgreeText>전체 동의</BigAgreeAgreeText>
            </BigAgreeAgree>
          </BigAgree>
          {acct?.map((item, idx) => {
            return (
              <>
                <Agree>
                  <AgreeText>
                    <span>{item?.title}</span>
                    <span
                      onClick={() => {
                        __handleSelect(idx);
                      }}
                    >
                      전체보기
                    </span>
                  </AgreeText>
                  <AgreeAgree for={`check${idx}`}>
                    <AgreeAgreeInput
                      id={`check${idx}`}
                      onClick={() => {
                        let datas = [...acct];
                        datas[idx].agreed = !acct[idx].agreed;
                        setAcct(datas);
                      }}
                      checked={item.agreed}
                      type="checkbox"
                    />
                    <AgreeAgreeText>동의</AgreeAgreeText>
                  </AgreeAgree>
                </Agree>
                {acct[idx].validate === true && (
                  <AgreeBox readOnly={true} value={item?.contents}></AgreeBox>
                )}
              </>
            );
          })}
        </Inner>
      )}
    </Wrapper>
  );
};

export default TermsAgree;
