import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";


import DaumPostcode from "react-daum-postcode";
import { Fade } from "react-reveal";
import { GetInforMation, GetUserOrderList, GetUserProfile } from "Datas/swr";
import { createContract, createPost } from "Datas/api";
import TermsAgree from "Components/TermsAgree/TermsAgree";
import moment from "moment";
import AlertModal from "Modal";

import { CloseBtn_Ico } from "assets";
import { useLocation } from "react-router";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ko from "date-fns/locale/ko";
import { Button, Modal as antModal } from 'antd';
import { CloseOutlined } from "@material-ui/icons";
registerLocale("ko", ko);

const Wrapper = styled.div``;

const Inner = styled.div`
  max-width: 1440px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 150px;
`;

const GuideLabel = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0 -60px;
  @media (max-width: 768px) {
    display: block;
  }
  @media (max-width: 550px) {
    text-align: center;
    margin-top: -10px;
  }
`;

const GuideLabelBig = styled.div`
  min-width: 150px;
  font-size: 25px;
  font-weight: 700;
  margin-right: 20px;
  color: #000;
  @media (max-width: 768px) {
    margin-bottom: 5px;
  }
`;

const GuideLabelSmall = styled.div`
  color: #999;
  font-size: 15px;
  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
  @media (max-width: 550px) {
    max-width: 320px;
    margin: 0 auto 25px;
  }
`;

const PhoneImg = styled.div`
  & > img {
    width: 60%;
  }
  max-width: 120px;
  margin-right: -30px;
`;

const ContractInfo = styled.div`
  display: flex;
  margin-top: 75px;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  position: relative;
  @media (max-width: 1280px) {
    justify-content: flex-start;
  }
  @media (max-width: 1280px) {
    justify-content: space-between;
  }
`;

const PhoneInfoWrapper = styled.div`
  flex-direction: column;
  width: calc(100% / 4 - 32px - 0.01px);
  min-width: 250px;
  height: 320px;
  border: 1px solid black;
  border-radius: 10px;
  padding-bottom: 15px;
  padding: 15px;
  margin-bottom: 30px;
  @media (max-width: 1280px) {
    margin-right: 20px;
  }
  @media (max-width: 1125px) {
    width: calc(100% / 2 - 10px - 0.01px);
    margin-right: 0px;
  }
  @media (max-width: 550px) {
    width: 100%;
    margin-right: 0px;
  }
`;

const PhoneInfoLabel = styled.div`
  font-size: 17px;
  font-weight: bold;
  height: 60px;
  border-bottom: 1px solid #ccc;
`;

const MiddleWrapper = styled.div`
  display: flex;
  width: calc(100% / 4 - 32px - 0.01px);
  min-width: 280px;

  & > div {
    width: calc(100% / 1 - 32px - 0.01px);
    margin-left: calc(32px / 1);
    margin-right: calc(32px / 1);
    @media screen and (max-width: 1600px) {
      width: 100%;
      margin: 0;
    }
  }
  & > div:last-child {
    @media screen and (max-width: 1600px) {
      margin-top: 30px;
    }
  }
`;

const PhoneInfo = styled.div`
  display: flex;
  padding: 12px 0 0;
  border-top: 1px solid #ccc;
`;

const PhoneInfoRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PhoneInfoRow = styled.div`
  display: flex;
  align-items: center;
  margin: 2px 0;
  width: 100%;
`;

const PhoneInfoRowKey = styled.div`
  font-size: 15px;
  margin-right: 20px;
  color: #838383;

  @media screen and (max-width: 450px) {
    font-size: 16px;
  }
`;

const PhoneInfoRowValue = styled.div`
  margin-left: auto;
  font-size: 15px;
`;

const PhoneInfoMonthlyPrice = styled.div`
  margin-top: 0px;
`;

const PhoneInfoMonthlyPriceOriginal = styled.div`
  display: flex;
  & > span {
    font-size: 16px;
    font-weight: bold;
  }
  & > span:last-child {
    margin-left: auto;
  }
`;

const PhoneInfoMonthlyPriceRate = styled.div`
  margin-top: 20px;
  display: flex;
  & > span {
    font-size: 15px;
    font-weight: 600;
    @media screen and (max-width: 450px) {
      font-size: 16px;
    }
  }
  & > span:last-child {
    margin-left: auto;
  }
`;

const PhoneInfoReleasePrice = styled.div`
  display: flex;
  margin: 5px 0;
  & > span {
    color: black;
    font-size: 15px;
  }
  & > span:last-child {
    margin-left: auto;
  }
`;

const PhoneInfoReleasePriceDiscount = styled.div`
  display: flex;
  margin: 5px 0;
  & > span {
    color: black;
    font-size: 15px;
  }
  & > span:last-child {
    margin-left: auto;
  }
`;

const PhoneInfoSuperDC = styled.div`
  display: flex;
  margin: 5px 0;
  & > span {
    font-size: 15px;
  }
  & > span:last-child {
    margin-left: auto;
  }
`;

const PhoneInfoMonthlyPriceTotal = styled.div`
  /* width: calc(100% / 4 - 32px - 0.01px); */
  width: 100%;
  background: black;
  margin-left: -15px;
  display: flex;
  padding: 10px 15px;
  font-weight: 600;
  border-radius: 0 0 10px 10px;
  color: #fff;
  & > span {
    font-size: 15px;
  }
  & > span:last-child {
    margin-left: auto;
  }
  position: absolute;
  bottom: 0;
`;

const PhoneInfoMonthlyCharge = styled.div`
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
  @media screen and (max-width: 450px) {
    font-size: 16px;
  }
`;

const PhoneInfoMonthlyChargeReal = styled.div`
  margin-top: 20px;
  font-size: 24px;
  color: black;
  font-weight: 600;
  margin-left: auto;
  @media screen and (max-width: 450px) {
    font-size: 28px;
  }
`;

const PhoneInfoMonthlyChargeMessage = styled.div`
  color: #5f5f5f;
  font-size: 13px;
  padding-top: 20px;
  & > p {
    display: block;
    margin-top: 10px;
    position: absolute;
    bottom: 0;
    background-color: #000;
    color: #fff;
    height: 45px;
    width: 100%;
    line-height: 45px;
    margin-left: -15px;
    border-radius: 0 0 8px 8px;
    border: 1px solid #000;
    letter-spacing: -0.5px;
    text-align: center;
    font-size: 11px;
    letter-spacing: -1px;
  }
  & > span:first-child {
    font-weight: bold;
  }
`;

const Phone5G = styled.div`
  display: flex;
  width: 50px;
  height: 25px;
  background: black;
  font-weight: 700;
  border-radius: 30px;
  font-size: 15px;
  color: #fff;
  line-height: 27px;
  justify-content: center;

  @media screen and (max-width: 450px) {
    font-size: 11px;
  }
`;

const CostumerInfo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  margin: 0 auto;
  margin-top: 129px;
  width: 100%;
  @media (max-width: 1280px) {
    margin-top: 20px;
  }
`;

const CostumerInfoLabel = styled.div`
  font-size: 25px;
  font-weight: bold;
  color: rgb(34, 37, 49);
  margin-bottom: 10px;
`;

const AddrInfo = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1300px;
  margin: 0 auto;
  width: 100%;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const AddrInfoLabel = styled.div`
  font-size: 25px;
  color: #111;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const InfoAreaInner = styled.div`
  background-color: #f9f9f9;
  padding: 15px 15px 30px;
  border-radius: 10px;
  @media (max-width: 500px) {
    padding: 15px 20px 15px;
  }
`;

const TwoInputWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  /* flex-wrap: wrap; */
  border-radius: 10px;
  padding: 15px 0;
  @media (max-width: 500px) {
    flex-direction: column;
    padding: 0;
    width: 100%;
  }
`;

const ShippongInputArea = styled.div`
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  padding: 15px 0;
  flex-direction: column;
  width: 49%;
  position: relative;
  @media (max-width: 500px) {
    flex-direction: column;
    padding: 0;
    width: 100%;
  }
`;

const DefaultInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0 -10px;
  width: 100%;
  max-width: 550px;
  .react-datepicker__input-container > input {
    border: 2px solid black;
    width: 95%;
    height: 50px;
    padding-left: 20px;
    border-radius: 10px;
    margin-top: 5px;
    font-size: 14px;
  }
  .react-datepicker__triangle {
    left: -212px !important;
  }
  @media (max-width: 500px) {
    margin: -10px 0 10px;
  }
`;

const DefaultInputLabel = styled.div`
  font-size: 15px;
  font-weight: 600;
  white-space: nowrap;
  color: #111;
  @media screen and (max-width: 500px) {
    margin-top: 15px;
  }
`;

const DefaultInput = styled.input`
  width: 100%;
  margin-top: 5px;
  height: 50px;
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  color: #000;
  font-size: 16px;
  padding: 10px 10px 10px 20px;
  &.essential {
    border: 2px solid black;
  }
  @media screen and (max-width: 1200px) {
    width: 95%;
  }
  @media screen and (max-width: 768px) {
    ::placeholder {
      font-size: 14px;
    }
    font-size: 14px;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

const AdultOrNot = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 50px;
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 5px;
    height: 55px;
    color: #636363;
    font-weight: 700;
    border: 2px solid #f0f0f0;
    border-radius: 10px;
    font-size: 16px;
    max-width: 180px;
    padding: 10px;
  }
  @media (max-width: 500px) {
    margin: 25px 0 0;
  }
`;

const AddrInputWrapperLabel = styled.div`
  font-size: 15px;
  font-weight: 600;
  white-space: nowrap;
  margin-top: 10px;
  color: #111;
`;

const AddrInputWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  position: relative;
`;

const AddrInputTop = styled.div`
  display: flex;
  // align-items: center;
`;

const AddrInput = styled.input`
  width: 100%;
  height: 50px;
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  color: #000;
  font-size: 16px;
  padding: 10px;
  @media screen and (max-width: 768px) {
    ::placeholder {
      font-size: 13px;
    }
  }
  @media screen and (max-width: 450px) {
    height: 45px;
    font-size: 11px;
  }
`;

const AddrSearch = styled.div`
  background: #000;
  color: #fff;
  padding: 8px 12px;
  border-radius: 10px;
  white-space: nowrap;
  font-weight: 700;
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`;

const AddrDetailed = styled.div``;

const CostumerRequire = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
`;

const CostumerRequireLabel = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: #111;
  margin-top: 15px;
`;

const CostumerRequireTextarea = styled.textarea`
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  resize: none;
  height: 200px;
  font-size: 16px;
  padding: 12px 15px;
  margin-top: 5px;
  ::placeholder {
    font-size: 14px;
  }
`;

const BigAgree = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const BigAgreeAgreeText = styled.div`
  margin-left: 5px;
`;

const BigAgreeAgree = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const SignupBtn = styled.div`
  width: 50%;
  height: 60px;
  max-width: 1300px;
  margin: 0 auto;
  color: #fff;
  background: black;
  border-radius: 10px;
  margin-top: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 80px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const PostCodeStyle = styled.div`
 
   height:100% !important;
`;

export const AddressCloseBtn = styled.img`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 30px;
  right: 11px;
  cursor: pointer;
  z-index: 1000;
  display: none;
  @media (max-width: 500px) {
    display: block;
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        top: 340px;
      }
    }
  }
`;
const P_MODAL = styled(antModal)`
  margin: 0 auto;

  .ant-modal-content {
    height: 600px;
    border-radius: 0px !important;

    margin: 0 auto;
    display: flex;
    padding: 0px !important;
    flex-direction: column;
 
  }

  .ant-modal-body {
    flex: 1;
    overflow-y: auto; /* 필요에 따라 스크롤 설정 */
  }
`
const Contract = ({ location, profile, history, match }) => {
  console.log(location?.state);

  console.log(profile);

  const {
    mainDatas,
    cardSelected,
    ChooseSales,
    StoreInfoData,
    productData,
    allPrice,
    telCount,
    PayPrice,
    YoGumZe,
    teleComPrice,
    DCprice,
    Bonus,
    TongPrice,
    InfoDatas,
    newPrice,
    MarketPp,
    changePrice,
  } = location?.state;

  const [acct, setAcct] = useState([]);
  const [datas, setDatas] = useState({
    name: "",
    birthday: "",
    tel: "",
    tel1: "",
    child: 0,
    receiver: "",
    receivercall: "",
    zip: "",
    zipinfo: "",
    isvalidate: "",
    validate: "",
    pairbirth: "",
    pairtel: "",
    pairname: "",
    pairinfo: "",
    memo: "",
    usim: "",
  });

  const [isOpenPost, setIsOpenPost] = useState(false);
  const { GetUserOrderListMutate } = GetUserOrderList(profile?.userId);
  const [isModal, setIsModal] = useState(false);
  const { InfoData, InfoDataMutate } = GetInforMation();
  const onChangeOpenPost = () => {
    setIsOpenPost(!isOpenPost);
  };

  const onCompletePost = (data) => {
    let fullAddr = data.address;
    let extraAddr = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddr += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddr +=
          extraAddr !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddr += extraAddr !== "" ? ` (${extraAddr})` : "";
    }

    setDatas({ ...datas, zip: fullAddr });

    setIsOpenPost(false);
  };
  const _closeModal = () => {
    setIsModal(false);
  };
  const _handleSubmit = async () => {
    //생년월일 문자열 합산

    if (
      datas.name === "" ||
      datas?.zip === "" ||
      datas.zipinfo === "" ||
      datas.receiver === "" ||
      datas.tel === "" ||
      datas.tel1 === "" ||
      moment(birthday).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
    ) {
      setIsModal(true);
      return;
    }
    if (datas.child) {
      if (datas?.pairtel === "" || datas?.pairname === "") {
        alert("법정 대리인 정보를 입력하여주세요.");
        return;
      }
    }
    if (acct.length !== 0) {
      let res = acct?.findIndex((item, idx) => {
        return item.agreed === !true;
      });

      if (res !== -1) {
        alert("약관을 동의해주세요");
        return;
      }
    }
    /*   datas.birthday = `${dates.year}-${dates.month}-${dates.date}`; */

    //데이터 형식 정리
    let isvalidate = mainDatas?.oldtelecom === "신규가입" ? 11 : 0;
    let body = {
      storeid: profile?.storeid,

      orderData: {
        userid: profile?.userId,
        image: productData?.images,
        name: datas?.name,
        isvalidate: isvalidate,
        usim: mainDatas?.sim === "새 유심" ? true : false,
        productid: productData?.id,
        tel: datas?.tel,
        birthday: birthday,
        tel1: datas?.tel1,
        newPrice: Number(newPrice),
        child: datas?.child,
        pairbirth: pairbirth,
        pairtel: datas?.paritel,
        pairname: datas?.pairname,
        pairinfo: datas.pairinfo,
        receiver: datas?.receiver,
        receivercall: datas?.receivercall,
        zip: datas?.zip,
        validateOrderDay: moment(new Date()).format("YYYY-MM-DD"),
        zipinfo: datas?.zipinfo,
        memo: datas?.memo,
      },
      ProductData: {
        image: productData?.images,
        nowtel: mainDatas?.oldtelecom,
        willtell: mainDatas?.newtelecom,
        saletype: Bonus,
        pricetype: teleComPrice?.name,
        pricetypeprice: TongPrice,
        monthuse: mainDatas?.checkout,
        salemonth: allPrice > 0 ? allPrice : 0,
        marketprice: productData?.marketprice,
        openprice: `${mainDatas?.discount} ${ChooseSales.toString().replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        )}원`,
        dcprice: DCprice,
        monttype: mainDatas?.checkout,
        cardsale: cardSelected?.discount,
        cardname: cardSelected?.name,
        monthprice:
          mainDatas.checkout !== "일시불"
            ? Math.round(
              Number(Number(allPrice)) /
              mainDatas.checkout.replace("개월", "")
            ) > 0
              ? Math.round(
                Number(Number(allPrice)) /
                mainDatas.checkout.replace("개월", "")
              )
              : 0
            : Number(allPrice) > 0
              ? Number(allPrice)
              : 0,
        infophone: InfoDatas,
        productname: productData?.name,
        colorname: mainDatas?.color,
        hard: mainDatas?.storage,
      },
    };

    //실제 API POST
    await createContract(body);
    await GetUserOrderListMutate();
    history.push("/success");
  };



  const handleFocus = (e) => {
    const [fieldName, fieldIndex] = e.target.name.split("-");
    if (e.target.value.length >= e.target.maxLength) {
      if (parseInt(fieldIndex, 10) < 3) {
        const nextItem = document.querySelector(
          `input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`
        );

        if (nextItem !== null) {
          nextItem.focus();
        }
      }
    }
  };
  let SalePrice = teleComPrice?.pricemonth - teleComPrice?.pricemonth * 0.25;

  const storeId = match.params.storeId;
  const { pathname } = useLocation();




  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [pairbirth, setPairbirth] = useState(new Date());
  const [birthday, setBirthday] = useState(new Date());

  return (
    <Fade Button>
      <Wrapper>
        <AlertModal open={isModal} close={_closeModal} header="로그아웃 완료">
          필수 정보를 입력해주세요.
        </AlertModal>
        <Inner style={{ maxWidth: 1280 }}>
          <GuideLabel>
            <GuideLabelBig>개통신청서</GuideLabelBig>
            <GuideLabelSmall>
              신청정보 및 배송정보 입력 후 온라인 공식신청서 작성을 완료해주시기
              바랍니다.
            </GuideLabelSmall>
          </GuideLabel>
          <ContractInfo>
            <PhoneInfoWrapper>
              <div
                style={{ display: "flex", alignItems: "center", height: 90 }}
              >
                <PhoneImg>
                  <img alt="" src={productData?.images[0]} />
                </PhoneImg>
                <PhoneInfoLabel style={{ border: 0 }}>
                  {productData?.name}
                  <Phone5G>{productData?.giga}</Phone5G>
                </PhoneInfoLabel>
              </div>
              <PhoneInfo>
                <PhoneInfoRowWrapper>
                  <PhoneInfoRow>
                    <PhoneInfoRowKey>사용중인 통신사</PhoneInfoRowKey>
                    <PhoneInfoRowValue>
                      {mainDatas?.oldtelecom}
                    </PhoneInfoRowValue>
                  </PhoneInfoRow>
                  <PhoneInfoRow>
                    <PhoneInfoRowKey>사용할 통신사</PhoneInfoRowKey>
                    <PhoneInfoRowValue>
                      {mainDatas?.newtelecom}
                    </PhoneInfoRowValue>
                  </PhoneInfoRow>
                  <PhoneInfoRow>
                    <PhoneInfoRowKey>할인방법</PhoneInfoRowKey>
                    <PhoneInfoRowValue>{mainDatas?.discount}</PhoneInfoRowValue>
                  </PhoneInfoRow>
                  <PhoneInfoRow>
                    <PhoneInfoRowKey>요금제</PhoneInfoRowKey>
                    <PhoneInfoRowValue>{teleComPrice?.name}</PhoneInfoRowValue>
                  </PhoneInfoRow>
                  <PhoneInfoRow>
                    <PhoneInfoRowKey>구매방법</PhoneInfoRowKey>
                    <PhoneInfoRowValue>{mainDatas?.checkout}</PhoneInfoRowValue>
                  </PhoneInfoRow>
                  <PhoneInfoRow>
                    <PhoneInfoRowKey>유심선택</PhoneInfoRowKey>
                    <PhoneInfoRowValue>{mainDatas?.sim}</PhoneInfoRowValue>
                  </PhoneInfoRow>
                </PhoneInfoRowWrapper>
              </PhoneInfo>
            </PhoneInfoWrapper>
            <PhoneInfoWrapper style={{ position: "relative" }}>
              <PhoneInfoLabel style={{ height: 35 }}>월 할부금</PhoneInfoLabel>
              <PhoneInfoMonthlyPrice style={{ marginTop: 15 }}>
                <PhoneInfoMonthlyPriceOriginal>
                  <span>할부원금</span>
                  <span>{allPrice > 0 ? allPrice?.toLocaleString() : 0}원</span>
                </PhoneInfoMonthlyPriceOriginal>
                <PhoneInfoReleasePrice>
                  <span>출고가</span>
                  <span>{Number(MarketPp)?.toLocaleString()}원</span>
                </PhoneInfoReleasePrice>
                {mainDatas?.oldtelecom === "신규가입" && (
                  <PhoneInfoReleasePrice>
                    <span>신규가입할인</span>
                    <span>{Number(newPrice)?.toLocaleString()}원</span>
                  </PhoneInfoReleasePrice>
                )}
                <PhoneInfoReleasePrice>
                  <span>상품권</span>
                  <span>{Bonus.toLocaleString()}원</span>
                </PhoneInfoReleasePrice>
                <PhoneInfoReleasePrice>
                  <span>폰라인 특판 할인</span>
                  <span>-{TongPrice?.toLocaleString()}원</span>
                </PhoneInfoReleasePrice>
                <PhoneInfoReleasePrice>
                  <span>전환지원금</span>
                  <span>-{changePrice?.toLocaleString()}원</span>
                </PhoneInfoReleasePrice>

                <PhoneInfoReleasePriceDiscount>
                  <span>{mainDatas?.discount}</span>
                  <span>-{ChooseSales.toLocaleString()}원</span>
                </PhoneInfoReleasePriceDiscount>
                <PhoneInfoSuperDC>
                  <span>
                    {InfoData !== undefined && InfoData.pagename} D.C
                  </span>
                  <span>-{Number(DCprice).toLocaleString()}원</span>
                </PhoneInfoSuperDC>
              </PhoneInfoMonthlyPrice>
              <PhoneInfoMonthlyPriceTotal style={{ wdith: "100%" }}>
                <span>
                  {mainDatas?.checkout !== "일시불"
                    ? `월 (${mainDatas?.checkout})`
                    : mainDatas?.checkout}
                </span>
                <span>
                  {mainDatas.checkout !== "일시불"
                    ? Math.round(
                      Number(Number(allPrice)) /
                      mainDatas.checkout.replace("개월", "")
                    ) > 0
                      ? Math.round(
                        Number(Number(allPrice)) /
                        mainDatas.checkout.replace("개월", "")
                      )
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : 0
                    : Number(allPrice) > 0
                      ? Number(allPrice)
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : 0}
                  원
                </span>
              </PhoneInfoMonthlyPriceTotal>
            </PhoneInfoWrapper>
            <PhoneInfoWrapper style={{ position: "relative" }}>
              <PhoneInfoLabel style={{ height: 35 }}>
                월 통신요금
              </PhoneInfoLabel>
              <PhoneInfoMonthlyPriceRate>
                <span>요금제</span>
                <span>
                  {YoGumZe?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
                </span>
              </PhoneInfoMonthlyPriceRate>
              <PhoneInfoMonthlyPriceTotal>
                <span>월 납부액</span>
                <span>
                  {YoGumZe?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
                </span>
              </PhoneInfoMonthlyPriceTotal>
            </PhoneInfoWrapper>
            {/* <MiddleWrapper></MiddleWrapper> */}
            <PhoneInfoWrapper style={{ position: "relative" }}>
              <PhoneInfoLabel style={{ height: 35 }}>
                월 실 납부액
              </PhoneInfoLabel>
              <div style={{ display: "flex", alignItems: "center" }}>
                <PhoneInfoMonthlyCharge>월 청구액</PhoneInfoMonthlyCharge>
                <PhoneInfoMonthlyChargeReal>
                  {PayPrice.toLocaleString()}
                  <span style={{ fontSize: 15, fontWeight: 500 }}>원</span>
                </PhoneInfoMonthlyChargeReal>
              </div>
              <PhoneInfoMonthlyChargeMessage>
                * 부가세·할부이자 연
                <span style={{ color: "black" }}>{InfoData?.salePrice}%</span>
                포함 금액
                <br />
                <p>실제로 청구되는 금액으로 추가로 청구되실 금액 없습니다.</p>
              </PhoneInfoMonthlyChargeMessage>
            </PhoneInfoWrapper>
          </ContractInfo>
          <CostumerInfo>
            <CostumerInfoLabel>가입자 정보</CostumerInfoLabel>
            <InfoAreaInner>
              <TwoInputWrapper>
                <DefaultInputWrapper>
                  <DefaultInputLabel>명의자 성함</DefaultInputLabel>
                  <DefaultInput
                    className="essential"
                    placeholder="이름을 입력해주세요(필수)"
                    value={datas.name}
                    onChange={(e) =>
                      setDatas({ ...datas, name: e.target.value })
                    }
                  />
                </DefaultInputWrapper>
                <DefaultInputWrapper>
                  <DefaultInputLabel>생년월일(필수)</DefaultInputLabel>
                  <DatePicker
                    selected={birthday}
                    onChange={(date) => setBirthday(date)}
                    locale="ko"
                    dateFormat="yyyy.MM.dd"
                    placeholderText="생년월일을 입력하세요"
                  />

                  {/* <DefaultInput
                  className="essential"
                    placeholder="생년월일을 입력해주세요"
                    value={datas.birthday}
                    type="date"
                    onChange={(e) => {
                      setDatas({ ...datas, birthday: e.target.value });
                    }}
                  /> */}

                  {/*        <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <DefaultInput
                      name="ssn-1"
                      style={{ wdth: "32%" }}
                      placeholder="ex) 1999"
                      value={dates.year}
                      maxLength={4}
                      onChange={(e) => {
                        //setDatas({ ...datas, birthday: e.target.value });
                        setDates({ ...dates, year: e.target.value });
                        handleFocus(e);
                      }}
                    />
                    <DefaultInput
                      name="ssn-2"
                      style={{ wdth: "32%" }}
                      placeholder="ex) 01"
                      value={dates.month}
                      maxLength={2}
                      onChange={(e) => {
                        //setDatas({ ...datas, birthday: e.target.value });
                        setDates({ ...dates, month: e.target.value });
                        handleFocus(e);
                      }}
                    />
                    <DefaultInput
                      name="ssn-3"
                      style={{ wdth: "32%" }}
                      placeholder="ex) 01"
                      value={dates.date}
                      maxLength={2}
                      onChange={(e) => {
                        //setDatas({ ...datas, birthday: e.target.value });
                        setDates({ ...dates, date: e.target.value });
                        handleFocus(e);
                      }}
                    />
                  </div> */}
                </DefaultInputWrapper>
              </TwoInputWrapper>
              <TwoInputWrapper>
                <DefaultInputWrapper>
                  <DefaultInputLabel>개통할 휴대폰번호</DefaultInputLabel>
                  <DefaultInput
                    placeholder="입력해주세요(필수)"
                    value={datas.tel}
                    onChange={(e) =>
                      setDatas({ ...datas, tel: e.target.value })
                    }
                  />
                </DefaultInputWrapper>
                <DefaultInputWrapper>
                  <DefaultInputLabel>추가연락처</DefaultInputLabel>
                  <DefaultInput
                    placeholder="입력해주세요(필수)"
                    value={datas.tel1}
                    onChange={(e) =>
                      setDatas({ ...datas, tel1: e.target.value })
                    }
                  />
                </DefaultInputWrapper>
              </TwoInputWrapper>
            </InfoAreaInner>
            <TwoInputWrapper style={{ justifyContent: "flex-start" }}>
              <DefaultInputWrapper>
                {/* <DefaultInputLabel>성인 / 미성년자 여부</DefaultInputLabel> */}
                <AdultOrNot>
                  <div
                    onClick={() => setDatas({ ...datas, child: false })}
                    style={
                      !datas?.child
                        ? {
                          backgroundColor: "black",
                          border: "none",
                          color: "#fff",
                          cursor: "pointer",
                          marginRight: 10,
                        }
                        : { cursor: "pointer" }
                    }
                  >
                    성인
                  </div>
                  <div
                    onClick={() => setDatas({ ...datas, child: true })}
                    style={
                      datas?.child
                        ? {
                          backgroundColor: "black",
                          border: "none",
                          color: "#fff",
                          cursor: "pointer",
                          marginLeft: 10,
                        }
                        : { cursor: "pointer" }
                    }
                  >
                    미성년자
                  </div>
                </AdultOrNot>
              </DefaultInputWrapper>
            </TwoInputWrapper>
            {datas?.child ? (
              <>
                <div
                  style={{
                    backgroundColor: "#f9f9f9",
                    padding: "15px 15px 30px",
                    borderRadius: 10,
                    marginTop: 15,
                  }}
                >
                  <TwoInputWrapper>
                    <DefaultInputWrapper>
                      <DefaultInputLabel>법정대리인 성함</DefaultInputLabel>
                      <DefaultInput
                        className="essential"
                        placeholder="대리인의 성함을 입력해주세요(필수)"
                        value={datas.pairname}
                        onChange={(e) =>
                          setDatas({ ...datas, pairname: e.target.value })
                        }
                      />
                    </DefaultInputWrapper>
                    <DefaultInputWrapper>
                      <DefaultInputLabel>
                        법정대리인 생년월일(필수)
                      </DefaultInputLabel>
                      <DatePicker
                        className="essential"
                        selected={pairbirth}
                        onChange={(date) => setPairbirth(date)}
                        locale="ko"
                        dateFormat="yyyy.MM.dd"
                        placeholderText="생년월일을 입력하세요"
                      />
                      {/* <DefaultInput
                        className="essential"
                        placeholder="대리인의 생년월일을 입력해주세요(필수)"
                        value={datas.pairbirth}
                        type="date"
                        onChange={(e) =>
                          setDatas({ ...datas, pairbirth: e.target.value })
                        }
                      /> */}
                    </DefaultInputWrapper>
                  </TwoInputWrapper>
                  <TwoInputWrapper>
                    <DefaultInputWrapper>
                      <DefaultInputLabel>법정대리인 연락처</DefaultInputLabel>
                      <DefaultInput
                        placeholder="대리인의 연락처를 입력해주세요(필수)"
                        value={datas.paritel}
                        onChange={(e) =>
                          setDatas({ ...datas, pairtel: e.target.value })
                        }
                      />
                    </DefaultInputWrapper>
                    <DefaultInputWrapper>
                      <DefaultInputLabel>명의자와의 관계</DefaultInputLabel>
                      <DefaultInput
                        placeholder="명의자와의 관계를 입력해주세요(필수)"
                        value={datas.pairinfo}
                        onChange={(e) =>
                          setDatas({ ...datas, pairinfo: e.target.value })
                        }
                      />
                    </DefaultInputWrapper>
                  </TwoInputWrapper>
                </div>
              </>
            ) : <></>}
          </CostumerInfo>
          <AddrInfoLabel style={{ marginTop: 60 }}>배송지 정보</AddrInfoLabel>
          <div
            style={{
              border: "1px solid #333",
              padding: "20px 20px 25px",
              borderRadius: 10,
            }}
          >
            <AddrInfo>
              <ShippongInputArea>
                <DefaultInputWrapper>
                  <DefaultInputLabel>받으시는 분</DefaultInputLabel>
                  <DefaultInput
                    placeholder="입력해주세요(필수)"
                    value={datas.receiver}
                    onChange={(e) =>
                      setDatas({ ...datas, receiver: e.target.value })
                    }
                  />
                </DefaultInputWrapper>
                <DefaultInputWrapper>
                  <DefaultInputLabel>배송 연락처</DefaultInputLabel>
                  <DefaultInput
                    placeholder="입력해주세요(필수)"
                    value={datas.receivercall}
                    onChange={(e) =>
                      setDatas({ ...datas, receivercall: e.target.value })
                    }
                  />
                </DefaultInputWrapper>
              </ShippongInputArea>
              <ShippongInputArea>
                <DefaultInputWrapper>
                  <AddrInputWrapperLabel>배송지 주소</AddrInputWrapperLabel>
                  <AddrInputTop

                  >
                    <AddrInput
                      placeholder="배송지 주소를 입력해주세요(필수)"
                      value={datas?.zip}
                      disabled={true}

                    />

                    <AddrSearch onClick={() => {
                      setIsOpenPost(true)

                    }}>
                      검색
                    </AddrSearch>
                  </AddrInputTop>
                </DefaultInputWrapper>
                <DefaultInputWrapper>
                  <AddrInputWrapperLabel>상세 주소</AddrInputWrapperLabel>
                  <AddrInput
                    placeholder="상세주소를 입력해주세요(필수)"
                    value={datas.zipinfo}
                    onChange={(e) =>
                      setDatas({ ...datas, zipinfo: e.target.value })
                    }
                  />
                </DefaultInputWrapper>
              </ShippongInputArea>
            </AddrInfo>
            <CostumerRequire>
              <CostumerRequireLabel>고객요청사항</CostumerRequireLabel>
              <CostumerRequireTextarea
                onChange={(e) => setDatas({ ...datas, memo: e.target.value })}
                placeholder="요청사항을 입력해주세요"
              />
            </CostumerRequire>
          </div>
          <TermsAgree
            acct={acct}
            setAcct={setAcct}
            SignUp
            termstype={1}
            style={{ marginTop: 24 }}
          ></TermsAgree>
          {/* <AgreeSection>
            <BigAgree>
              <BigAgreeText>약관 및 이용 동의</BigAgreeText>
              <BigAgreeAgree>
                <BigAgreeAgreeInput type="checkbox" />
                <BigAgreeAgreeText>전체 동의</BigAgreeAgreeText>
              </BigAgreeAgree>
            </BigAgree>
            <Agree>
              <AgreeText>
                <span>회선유지가 기간 및 기간 내 금지사항</span>
                <span>전체보기</span>
              </AgreeText>
              <AgreeAgree>
                <AgreeAgreeInput type="checkbox" />
                <AgreeAgreeText>동의</AgreeAgreeText>
              </AgreeAgree>
            </Agree>
            <Agree>
              <AgreeText>
                <span>요금제 유지기간 안내 및 금지사항</span>
                <span>전체보기</span>
              </AgreeText>
              <AgreeAgree>
                <AgreeAgreeInput type="checkbox" />
                <AgreeAgreeText>동의</AgreeAgreeText>
              </AgreeAgree>
            </Agree>
            <Agree>
              <AgreeText>
                <span>위약금 및 잔여할부금 관련 안내</span>
                <span>전체보기</span>
              </AgreeText>
              <AgreeAgree>
                <AgreeAgreeInput type="checkbox" />
                <AgreeAgreeText>동의</AgreeAgreeText>
              </AgreeAgree>
            </Agree>
            <Agree>
              <AgreeText>
                <span>개인정보 조회 및 동의사항</span>
                <span>전체보기</span>
              </AgreeText>
              <AgreeAgree>
                <AgreeAgreeInput type="checkbox" />
                <AgreeAgreeText>동의</AgreeAgreeText>
              </AgreeAgree>
            </Agree>
          </AgreeSection> */}
          <div
            style={{
              borderTop: "1px solid #ccc",
              height: 15,
              marginTop: 30,
            }}
          />
          <SignupBtn onClick={() => _handleSubmit()} style={{ marginTop: 30 }}>
            개통하기
          </SignupBtn>
        </Inner>

        <P_MODAL
          centered={true}
          visible={isOpenPost}
          closable={false}
          footer={false}
          title={
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' ,paddingLeft:16,paddingRight:16,paddingTop:5,paddingBottom:5,borderBottom:'1px solid #eee'}}>
              <span>주소 검색</span>
              <Button
                type="text"
                icon={<CloseOutlined />}
                onClick={() => setIsOpenPost(false)}
                style={{ marginLeft: 'auto' }}
              />
            </div>
          }
          maskClosable={true} // 이 부분을 추가
          onCancel={() => setIsOpenPost(false)} // 모달이 닫힐 때 호출되는 함수
        >
          <>
            <PostCodeStyle>

              <DaumPostcode
                style={{ height: '100%' }}
                // style={postCodeStyle}

                onComplete={onCompletePost}
              />

            </PostCodeStyle>
          </>
        </P_MODAL>
      </Wrapper>
    </Fade>
  );
};

export default Contract;
