import Slider from "react-slick";
import { useEffect } from "react";
import styled from "styled-components";
import { GetBannerList, GetPromotionList } from "Datas/swr";

import { isTablet, isMobile } from "react-device-detect";
import { useState } from "react";
import { Link } from "react-router-dom";

const Leaderboard = styled.img`
  width: 100%;
  margin-top: 97px;
  background: #f0f0f0;
  height: 550px;
  @media screen and (max-width: 1600px) {
    height: 450px;
    margin-top: 129px;
  }
  @media screen and (max-width: 1440px) {
    height: 400px;
    margin-top: 100px;
  }
  @media screen and (max-width: 1280px) {
    height: 350px;
    margin-top: 99px;
  }

  @media (max-width: 768px) {
    height: 250px;
    margin-top: 84px;
  }
  @media (max-width: 625px) {
    height: 165px;
    margin-top: 98px;
  }
`;

const Sliders = () => {
  const { BannerListData, isLoading } = GetBannerList(0);
  const [BannerData, setBannerData] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  useEffect(() => {
    if (!isLoading && BannerListData?.status !== 400) {
      setBannerData(BannerListData?.rows);
    }
  }, [BannerListData]);

  console.log(BannerListData);
  return (
    <Slider {...settings}>
      {BannerData !== undefined && BannerData?.length !== 0 ? (
        BannerData?.map((el, idx) => {
          return (
            <Link to={el.url}>
              <Leaderboard src={el?.image[0]}></Leaderboard>
            </Link>
          );
        })
      ) : (
        <></>
      )}
    </Slider>
  );
};
export default Sliders;
