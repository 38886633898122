import styled from "styled-components";

const breakpoints = [450, 768, 992, 1200, 420, 345]; // 0 1 번쨰사용
const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const Wrapper = styled.div`

  overflow: auto;
  margin-bottom: -240px;
  -webkit-overflow-scrolling: touch;
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1280px) {
    flex-direction: column;
  }
`;

export const Things = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
`;

export const Inner = styled.div`
  margin-top: 180px;
  max-width: 1440px;
  min-width: 275px;
  padding: 0 20px;
  margin: 0 auto;
  padding-top: 150px;
  width: 100%;
  @media screen and (max-width: 768px) {
    margin-top: 100px;
    padding-top: 0px;
  }
  @media screen and (max-width: 450px) {
    margin-top: 125px;
    padding-top: 0px;
  }
`;

export const PhonePreview = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1280px) {
    margin: -30px auto 0;
  }
  @media (max-width: 768px) {
    margin: -30px auto;
  }
`;

export const PhonePreviewImg = styled.div`
   
   max-width:340px;
  margin-top: 42px;
 
  border-radius: 10px;
  & > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 1280px) {
    border: 0;
    background-color: transparent;
  }
`;

export const PhonePreviewInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 25px;
`;
export const PhonePreviewRightBox = styled.div`
  margin-left: 67px;
  font-weight: bold;
  font-size: 20px;
  min-width: 182px;
  @media screen and (max-width: 768px) {
    margin-left: 0px;
    margin-top: 5px;
    font-size: 26px;
  }
`;

export const PhonePreviewRightTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const PhonePreviewRightPrice = styled.div`
  color: black;
`;
export const PhonePreviewRightTitle_T = styled.div``;
export const PhonePreviewRightTitle_B = styled.div`
  background: black;
  border-radius: 7px;
  color: white;
  font-weight: bold;
  width: 35px;
  text-align: center;
  padding-top: 3px;
  margin-top: -3px;
  height: 23px;
  margin-left: 12px;
  font-size: 14px;
`;

export const PhonePreviewName = styled.div`
  display: flex;
  margin-top: 45px;
  margin-bottom: 16px;
  border-top: 2px solid black;
  border-bottom: 1px solid black;
  padding-bottom: 16px;
  padding-top: 16px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const PhonePreviewLeftName = styled.div`
  font-weight: bold;
  font-size: 15px;
  margin-top: 5px;
  min-width: 70px;
  @media (max-width: 1280px) {
    font-size: 18px;
  }
`;

export const PhonePreviewDiscountPrice = styled.div`
  color: black;
  font-size: 24px;
  display: flex;
  font-weight: 500;
  padding-bottom: 12px;
  align-items: center;
  border-bottom: 2px solid black;
`;

export const PhoneOptionWrapper = styled.div`
  width: ${(props) => {
    if (props.TelPrice) {
      return "70%";
    }
    return "100%";
  }};
  display: flex;
  flex-direction: column;
  //overflow-x: scroll;
  margin-bottom: 12px;
  @media screen and (max-width: 1280px) {
    margin-bottom: 12px;
    width: 100%;
  }
`;

export const PhoneOption = styled.div`
  display: -webkit-box;
  flex-direction: row;
  align-items: center;
  & > div.firstdiv {
    width: 80%;
    @media (max-width: 1280px) {
      width: 87.3%;
    }
    @media (max-width: 600px) {
      width: 100%;
    }
  }
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const PhoneOption2 = styled.div`
  display: -webkit-box;
  flex-direction: row;
  align-items: center;
  & > div.firstdiv {
    width: 80%;
    margin-top: -12px;
    @media (max-width: 1280px) {
      width: 87.3%;
    }
    @media (max-width: 1024px) {
      width: 84%;
    }
    @media (max-width: 768px) {
      width: 78%;
    }
    @media (max-width: 600px) {
      width: 100%;
      margin: 0px;
    }
  }
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const PhoneColorPareText = styled.div`
  margin-top: 2px;
  font-size: 11px;
`;

export const PhoneColorPare = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
`;

export const PhoneOptionCircleMemory = styled.div`
  //display: flex;
  display: -webkit-box;
  overflow-x: scroll;
  margin-top: -12px;
  @media (max-width: 600px) {
    margin-top: 0px;
    width: 100%;
  }
`;

export const PhoneOptionCircleMemory2 = styled.div`
  //display: flex;
  display: -webkit-box;
  overflow-x: scroll;
  margin-top: -12px;
  @media (max-width: 600px) {
    margin-top: 0;
    width: 100%;
  } ;
`;

export const PhoneOptionCircleMemoryHard = styled.div`
  //display: flex;
  margin-top: -12px;
  display: -webkit-box;
  overflow-x: scroll;
  @media screen and (max-width: 600px) {
    margin-top: 0px;
  }
`;

export const PhoneOptionName = styled.div`
  min-width: 95px;
  font-size: 14px;
  color: black;
  margin-bottom: 10px;
  padding-top: 2px;
  width: 95px;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 7px;
  margin-right: 35px;
  & > div {
    margin-top: -5px;
  }
  @media (max-width: 1280px) {
    width: 120px;
  }
  @media screen and (max-width: 450px) {
    margin-right: 0px;
    width: 40%;
    border: 1px solid #ccc;
    margin-top: 10px;
    display: ${(props) => {
    if (props.Origin) {
      return "none";
    }
    return "flex";
  }};
  }
`;

export const PhoneOptionName2 = styled.div`
  min-width: 95px;
  font-size: 14px;
  color: black;
  margin-bottom: 10px;
  padding-top: 2px;
  width: 95px;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  border: 1px solid #ccc;
  border-radius: 7px;
  margin-right: 35px;
  margin-top: -70px;
  background-color: #fff;
  @media (max-width: 1280px) {
    width: 120px;
  }
  @media screen and (max-width: 600px) {
    margin: 10px 0px 10px;
    width: 40%;
    display: ${(props) => {
    if (props.Origin) {
      return "none";
    }
    return "flex";
  }};
  }
`;

export const SizeBox = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;
  color: ${(props) => {
    if (props.selected) {
      return "black";
    }
    return "#999";
  }};
  :hover {
    opacity: 0.9;
    color: black;
  }
`;

export const PhoneOptionCircleVers = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  padding-top: 2.5px;
  border: ${(props) => (props.selected === true ? "2px solid #000" : "none")};
  color: ${(props) => (props.selected === true ? "#000" : "#666")};
  background-color: #f7f7f7;
  width: ${(props) => {
    if (props.tel) {
      return "42px;";
    }
  }};
  height: ${(props) => {
    if (props.tel) {
      return "42px;";
    }
  }};
  font-weight: ${(props) => (props.selected === true ? 600 : 500)};
  margin-right: 10px;
  font-size: 11px;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    font-size: 11px;
    width: ${(props) => {
    if (props.tel) {
      return "22.5%";
    }
  }};
    height: ${(props) => {
    if (props.tel) {
      return "42px;";
    }
  }};
  }
`;

export const PhoneOptionCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  border: ${(props) =>
    props.selected === true ? "2px solid black" : "1px solid #DDDDDD"};
  color: ${(props) => (props.selected === true ? "black" : "#999999")};
  background-color: #fff;
  padding: ${(props) => {
    if (props.일시불) {
      return "9px 10px 7px";
    }
    if (props.유심) {
      return "9px 20px 7px";
    }
    return "9px 10px 7px";
  }};
  font-weight: ${(props) => {
    if (props.일시불) {
      return 600;
    }
    if (props.유심) {
      return 600;
    }
    return 500;
  }};
  margin-right: ${(props) => {
    if (props.일시불) {
      return "7px";
    }
    return "7px";
  }};
  font-size: ${(props) => {
    if (props.일시불) {
      return "14px";
    }
    if (props.유심) {
      return "14px";
    }
    return "14px";
  }};
  cursor: pointer;
  @media screen and (max-width: 850px) {
    padding: 9px 10px 7px;
    padding: ${(props) => {
    if (props.일시불) {
      return "9px 10px 7px";
    }
    if (props.유심) {
      return "9px 20px 7px";
    }
    return "9px 10px 7px";
  }};
    font-size: ${(props) => {
    if (props.일시불) {
      return "14px";
    }
    if (props.유심) {
      return "14px";
    }
    return "14px";
  }};
  }
  @media screen and (max-width: 600px) {
    padding: 8px 16px;
    font-size: ${(props) => {
    if (props.일시불) {
      return "14px";
    }
    if (props.유심) {
      return "14px";
    }
    return "14px";
  }};
    padding: ${(props) => {
    if (props.일시불) {
      return "9px 10px 7px";
    }
    if (props.유심) {
      return "9px 20px 7px";
    }
    return "9px 20px 7px";
  }};
    width: 23.5%;
    margin-right: 7px;
    //margin-bottom: 15px;
  }
`;

export const PhoneOptionCircle2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  border: ${(props) =>
    props.selected === true ? "2px solid black" : "1px solid #DDDDDD"};
  color: ${(props) => (props.selected === true ? "black" : "#999999")};
  background-color: #fff;
  padding: ${(props) => {
    if (props.일시불) {
      return "9px 10px 7px";
    }
    if (props.유심) {
      return "9px 20px 7px";
    }
    return "9px 10px 7px";
  }};
  font-weight: ${(props) => {
    if (props.일시불) {
      return 600;
    }
    if (props.유심) {
      return 600;
    }
    return 500;
  }};
  margin-right: ${(props) => {
    if (props.일시불) {
      return "7px";
    }
    return "7px";
  }};
  font-size: ${(props) => {
    if (props.일시불) {
      return "14px";
    }
    if (props.유심) {
      return "14px";
    }
    return "14px";
  }};
  cursor: pointer;
  @media screen and (max-width: 850px) {
    padding: 9px 10px 7px;
    padding: ${(props) => {
    if (props.일시불) {
      return "9px 10px 7px";
    }
    if (props.유심) {
      return "9px 20px 7px";
    }
    return "9px 10px 7px";
  }};
    font-size: ${(props) => {
    if (props.일시불) {
      return "14px";
    }
    if (props.유심) {
      return "14px";
    }
    return "14px";
  }};
  }
  @media screen and (max-width: 600px) {
    padding: 8px 16px;
    font-size: ${(props) => {
    if (props.일시불) {
      return "14px";
    }
    if (props.유심) {
      return "14px";
    }
    return "14px";
  }};
    padding: ${(props) => {
    if (props.일시불) {
      return "9px 10px 7px";
    }
    if (props.유심) {
      return "9px 20px 7px";
    }
    return "9px 20px 7px";
  }};
    width: 49%;
    margin-right: 7px;
    //margin-bottom: 15px;
  }
`;

export const SelectBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export const PhoneSummary = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 25px 20px;
  border-radius: 10px;
  background: #f3f3f3;
  border-radius: 8px;
  @media screen and (max-width: 450px) {
    padding: 0px;
    background-color: #fff;
  }
`;

export const PhoneBorderBox = styled.div`
  border-radius: 0 0 7px 7px;
  background-color: black;
  padding: 8px 16px;
  margin-top: 7px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  min-width: 275px;
  width: 100%;
`;

export const PhoneBorderLeft = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

export const PhoneBorderRight = styled.div`
  color: yellow;
  font-size: 17px;
  font-weight: 600;
`;

export const PhoneSummaryKeyValue = styled.div`
  display: flex;
  align-items: center;
  padding: ${(props) => {
    if (props.가격정보) {
      return "2px 15px";
    }
  }};
  @media screen and (max-width: 500px) {
    flex-direction: ${(props) => {
    if (props.가격정보) {
      return "column";
    }
  }};
    padding: ${(props) => {
    if (props.가격정보) {
      return "14px 0px";
    }
  }};
  }
`;

export const PhoneSummaryKey = styled.div`
  font-size: 15px;
  color: black;
  width: 135px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding-top: 6px;
  padding-bottom: 6px;
  @media screen and (max-width: 1280px) {
    font-size: 16px;
    padding-top: 9px;
    padding-bottom: 5px;
  }
  @media screen and (max-width: 768px) {
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  @media screen and (max-width: 500px) {
    margin-bottom: -5px;
    justify-content: ${(props) => {
    if (props.가격정보) {
      return "center";
    }
  }};
    font-size: ${(props) => {
    if (props.가격정보) {
      return "18px";
    }
  }};
    color: ${(props) => {
    if (props.가격정보) {
      return "#999";
    }
  }};
  }
`;

export const PhoneSummaryValue = styled.div`
  font-size: 16px;
  margin-left: auto;
  min-width: 80px;
  text-align: right;
  font-weight: ${(props) => {
    if (props.normal) {
      return "noraml";
    }
    return "bold";
  }};
  color: ${(props) => {
    if (props.summary) {
      return "black";
    }
    return "";
  }};
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
  @media screen and (max-width: 500px) {
    margin-left: ${(props) => {
    if (props.가격정보) {
      return "0";
    }
  }};
    font-size: ${(props) => {
    if (props.가격정보) {
      return "18px";
    }
  }};
    text-align: ${(props) => {
    if (props.가격정보) {
      return "center";
    }
  }};
  }
`;

export const PhoneSummaryWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 400px;
  @media (max-width: 1280px) {
    width: 100%;
  }
`;

export const PrKaKa = styled.div`
  font-size: 15px;
  min-width: 135px;
  @media screen and (max-width: 450px) {
    font-size: 15px;
    padding: 12px 0 8px;
  }
  @media screen and (max-width: 360px) {
    font-size: 13px;
    padding: 12px 0 8px;
  }
`;

export const Phone5G = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
  padding-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 10px;
  background-color: black;
  font-weight: bold;

  @media screen and (max-width: 450px) {
    font-size: 24px;
  }
`;

export const SummaryLogo = styled.div`
  width: 210px;
  height: 36px;
  /* background: #f0f0f0; */
  background-image: ${(props) => {
    if (props.src) {
      return `url('${props.src}')`;
    }
  }};
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 20px;

  @media screen and (max-width: 768px) {
    font-size: 11px;
  }
  @media screen and (max-width: 375px) {
    width: 150px;
    height: 27px;
  }
`;

export const PhoneOptionAll = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  @media screen and (max-width: 1280px) {
    width: 100%;
  }
`;

export const PhoneOptionRateBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 5px 5px 0px 0px;
  cursor: pointer;
  @media (max-width: 1280px) {
    width: 87%;
  }
  @media (max-width: 1150px) {
    width: 85%;
  }
  @media (max-width: 960px) {
    width: 82%;
  }
  @media (max-width: 800px) {
    width: 77%;
  }
  @media (max-width: 768px) {
    width: 73%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const PhoneOptionRateBoxNameAndDetail = styled.div`
  display: flex;
  align-items: center;
  padding-right: 12px;
  border-bottom: 1px solid #d3d3d3;
`;

export const PhoneOptionRateBoxName = styled.div`
  font-size: 15px;
  padding: 12px 15px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const PhoneOptionRateBoxPrice = styled.div`
  margin-left: auto;
  font-size: 20px;
  color: #525252;

  /* @media screen and (max-width: 1600px) {
    font-size: 18px;
  } */
  @media screen and (max-width: 1100px) {
    font-size: 16px;
  }
`;

export const PhoneOptionRateBoxDetail = styled.div`
  padding: 5px 15px;
  background-color: #f7f7f7;
  color: black;
  font-size: 14px;
  line-height: 5px;
  line-height: 21px;
`;

export const PhoneOptionDiscountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > div:first-child {
    margin-right: 10px;
    @media (max-width: 600px) {
      margin-right: 10px;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const PhoneOptionDiscount = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px 8px;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  background: #f0f0f0;
  cursor: pointer;
  & > span:first-child {
    color: #666;
  }
  & > span:last-child {
    font-weight: 700;
    font-size: 14px;
  }
  @media screen and (max-width: 600px) {
    width: 49%;
    font-size: 14px;
    padding: 10px 15px 8px;
  }
`;

export const ProductFont_Resize = styled.span`
  font-size: 14px;
  @media screen and (max-width: 768px) {
    font-size: 11px;
  }
`;

export const PhoneOptionSelect = styled.div`
  border: ${(props) =>
    props.selected === true ? "2px solid black" : "1px solid #999"};
  color: ${(props) => (props.selected === true ? "black" : "#999")};
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border-radius: 5px;
  font-weight: ${(props) => (props.selected === true ? 600 : 500)};
  width: 50%;
  cursor: pointer;
  font-size: 14px;
`;

export const PhoneOrnerOption = styled.td`
  background-color: #fff;
  font-size: 12px;
  letter-spacing: -0.5px;
`;

export const PhoneOptionNotSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  font-size: 16px;
  @media (max-width: 1280px) {
    :first-child div {
      font-size: 14px;
    }
    padding: 11px 16px 7px;
  }
  @media (max-width: 450px) {
  }
`;

export const BelowBar = styled.div`
  height: 100px;
  background: black;
  width: 100%;
  min-width: 335px;
  position: fixed;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  left: 0;
  right: 0;
  display: flex;
  bottom: 0;
  z-index: 3;
  /* @media screen and (max-width: 1600px) {
    height: 85px;
  } */
  @media screen and (max-width: 1100px) {
    height: 80px;
  }
  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 85px;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      -webkit-position: fixed;
      -webkit-bottom: 0;
    }
  }
`;

export const BelowBarInner = styled.div`
  display: flex;
  max-width: 1440px;
  padding: 0 20px;
  align-items: center;
  margin: 0 auto;
  font-weight: 700;
  color: #fff;
  width: 100%;
  font-size: 30px;

  /* @media screen and (max-width: 1600px) {
    font-size: 22px;
  } */
  @media screen and (max-width: 1100px) {
    font-size: 14px;
  }
  @media screen and (max-width: 450px) {
    font-size: 12px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const BelowBarBtn = styled.div`
  background: #fff;
  color: black;
  font-size: 20px;
  padding: 10px 20px 5px;
  border-radius: 15px;
  margin-left: auto;
  white-space: nowrap;
  cursor: pointer;

  ${mq[0]} {
    margin-left: 0px;
    padding: 10px 10px 6px;
    border-radius: 8px;
    font-size: 14px;
  }
`;

export const PhoneSummaryWrappeblack = styled.div`
  border: 1px solid black;
  border-radius: 10px;
  margin-top: 10px;
  background-color: #fff;
  min-width: 277px;
`;

export const PhoneSummaryTotal = styled.div`
  display: flex;
  padding: 15px 20px 5px;

  justify-content: space-between;
`;

export const PriceVal = styled.span`
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;
export const TagImage = styled.div`
  font-weight: 600;
  font-size: 28px;
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
  @media screen and (max-width: 360px) {
    font-size: 15px;
    margin: -3px 0 0 -5px;
  }
`;
export const PhoneSummaryTotalEach = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  & > span:nth-child(1) {
    font-size: 15px;
    font-weight: 700;
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
    @media screen and (max-width: 380px) {
      letter-spacing: -.5px;
    }
  }
  & > span:nth-child(2) {
    font-size: 13px;
    color: #606060;
    @media screen and (max-width: 768px) {
      font-size: 11px;
    }
  }
  & > span:nth-child(3) {
    font-size: 15px;
    margin-top: 5px;
    font-weight: 700;
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
`;

export const FaqWrapper = styled.div`
  margin-bottom: 250px;
  margin-top: 50px;
`;

export const FaqLabel = styled.div`
  display: flex;

  border-bottom: 1px solid #cccccc;
  @media (max-width: 391px) {
    justify-content: space-between;
  }
`;

export const FaqLabelSpan = styled.div`
  border-bottom: ${(props) => {
    if (props.action) {
      return "2px solid black";
    }
  }};
  font-weight: 600;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  @media screen and (max-width: 600px) {
    /* margin: 0 auto; */
    letter-spacing: -0.7px;
    padding: 6px 0px;
    width: 33%;
    min-width: 94px;
    text-align: center;
  }
  @media screen and (max-width: 391px) {
    letter-spacing: -1px;
  }
`;

export const ReviewBox = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: #fff;
  width: calc(100% / 4 - 45px);
  height: 250px;
  margin-bottom: 40px;
  border-radius: 7px;
  margin-right: 15px;
  @media screen and (max-width: 768px) {
    width: calc(100% / 2 - 45px);
  }

  @media screen and (max-width: 375px) {
    width: 100%;
  }
`;

export const ReviceColumn = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  max-width: 1440px;
  min-width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dcdcdc;
  margin: 20px auto;
  border-radius: 10px;
  /* border-bottom: 1px solid #dcdcdc; */
`;

export const ReviceColumnIn = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 15px;
`;

export const ReviceColumnLeftImg = styled.div`
  background-image: ${(props) => {
    if (props.src) {
      return `url('${props.src}')`;
    }
  }};
  @media screen and (max-width: 752px) {
    //display: none;
    width: 45px;
    height: 45px;
  }
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  margin-right: 13px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  width: 55px;
  height: 55px;
  border-radius: 6px;
`;

export const ReviewRihgtBox = styled.div`
  margin-left: 0px;
`;

export const ReviewRightBox_Title = styled.div`
  display: flex;
  font-size: 14px;
  align-items: center;
  font-weight: bold;
  & > span.device {
    @media(max-width: 390px) {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 80px;
      margin-right: 2px;
    }
  }
`;

export const ReviewRightBox_Star = styled.div`
  margin-top: 5px;
  height: 20px;
  width: auto;
  margin-left: 4px;
`;
export const RawRightClick = styled.span`
  color: #ff253e;
  font-size: 13px;
  margin-left: 4px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;
export const ReviewRightBox_Point = styled.span`
  color: #ff253e;
  font-size: 13px;
  margin-left: 4px;
`;

export const ReviewRightBox_Content = styled.div`
  margin-top: 6px;
  font-size: 14px;
  color: #555;
  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
  @media screen and (max-width: 674px) {
    font-size: 12px;
  }
`;
export const Selected = styled.div`
  cursor: pointer;
  width: 100%;
  height: 40px;
  background-color: orange;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 100px;
  font-weight: 500;

  :hover {
    background-color: rgba(250, 190, 88, 1);
  }
`;

export const MobileChangeBox = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 1280px) {
    margin-top: -5px;
  }
  @media (max-width: 600px) {
    margin-top: 8px;
  }
`;

export const MobileChangeBox2 = styled.div`
  width: 100%;
  margin-top: -25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 600px) {
    margin-top: 8px;
  }
`;

export const MobileChangeBox3 = styled.div`
  width: 100%;
  margin-top: -10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 600px) {
    margin-top: 8px;
  }
`;

export const MobileChangeLeftBox = styled.div`
  width: 105px;
  min-width: 100px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: 9px;
  background-color: #333333;
  color: white;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: -0.6px;
  ${mq[0]} {
    font-size: 12px;
  }
`;

export const MobileChangeRightBox = styled.div`
  margin-left: 12px;
  height: 30px;
  line-height: 37px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-size: 14px;
  color: #444444;
  letter-spacing: -1px;
  min-width: 220px;
  ${mq[0]} {
    font-size: 13px;
  }
`;

export const Bar = styled.div`
  width: 100%;
  height: 3px;
  background-color: black;
  opacity: 0.7;
`;

export const BottomSmLabel = styled.div`
  font-size: 19px;
  color: #fff;
  margin-right: 7px;
  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
  @media screen and (max-width: 450px) {
    font-size: 13px;
  } ;
  @media screen and (max-width: 360px) {
    letter-spacing: -.5px;
    font-size: 12.5px;
  } ;
`;

export const BottomSmPrice = styled.div`
  font-size: 19px;
  color: yellow;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
  @media screen and (max-width: 450px) {
    font-size: 12px;
  } ;
`;

export const BottomLgLabel = styled.div`
  font-size: 25px;
  font-weight: 600;
  color: #fff;
  margin-right: 5px;
  @media(max-width: 380px) {
    letter-spacing: -.5px;
  }
  ${mq[1]} {
    font-size: 16px;
  }
  ${mq[0]} {
    font-size: 14px;
  }
`;

export const BottomLgPrice = styled.div`
  font-size: 25px;
  font-weight: 600;
  color: #fff;
  /* background: linear-gradient(to top, #ffaf9b 10%, transparent 50%); */
  background-color: transparent;
  ${mq[1]} {
    font-size: 16px;
  }
  ${mq[0]} {
    font-size: 14px;
  }
  @media(max-width: 380px) {
    letter-spacing: -.5px;
  }
`;

export const StyledSelImage = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 2%;
  right: 1%;
  z-index: 999;
  cursor: pointer;
  @media screen and (max-width: 573px) {
    left: 92%;
  }
  @media screen and (max-width: 454px) {
    left: 88%;
  }
`;

export const ModalmgBox = styled.img`
  width: 35px;
  height: 35px;
  resize: contain;
`;
export const LeftButton2 = styled.img`
  position: absolute;
  left: 15px;
  top: 0%;
  bottom: 0;
  margin: auto;
  width: 22px;
  height: 40px;
  cursor: pointer;
  resize: contain;
  @media (max-width: 786px) {
    top: -40%;
  }
`;

export const LeftButton = styled.img`
  position: absolute;
  left: -50px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 22px;
  height: 40px;
  cursor: pointer;
  resize: contain;
  @media screen and (max-width: 1200px) {
    left: 0;
  } ;
`;

export const RightButton2 = styled.img`
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
  margin: auto;
  resize: contain;
  cursor: pointer;
  width: 22px;
  height: 40px;
  @media (max-width: 786px) {
    right: 15px;
    top: -40%;
  }
`;

export const RightButton = styled.img`
  position: absolute;
  right: -50px;
  top: 0;
  bottom: 0;
  margin: auto;
  resize: contain;
  cursor: pointer;
  width: 22px;
  height: 40px;
  @media screen and (max-width: 1200px) {
    right: 0;
  } ;
`;

export const ModalWrapper = styled.div`
  padding: 45px 20px 20px;
  @media (max-width: 786px) {
    padding: 45px 20px 0;
  }
  @media (max-width: 450px) {
    padding: 40px 10px 0;
  }
`;

export const ModalWrapperLists = styled.div`
  padding: 38px 73px;
  @media screen and (max-width: 1200px) {
    padding: 0px 0px;
    height: 540px;
  }
`;

export const ModalTitleFlex = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const ModalTitleFlex_Pare = styled.div`
  display: flex;
  align-items: center;
`;

export const ModalTitleValue_Box = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-left: 6px;
  @media screen and (max-width: 900px) {
    font-size: 16px;
  }
`;
export const ModalTitle_ImgBox = styled.img`
  width: 30px;
  height: 30px;
  resize: cover;
`;
export const OptionBox = styled.div`
  font-size: 16;
  @media screen and (max-width: 900px) {
    font-size: 10px;
    margin-left: 12px;
    margin-top: 10px;
  }
`;
export const GigaBox = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  padding: 5px 15px;
  border: 1px solid #fff;
  border-radius: 7px;
  margin-right: 10px;
  @media screen and (max-width: 900px) {
    font-size: 12px;
    padding: 2px 5px;
  }
`;

export const ModalHeader = styled.div`
  background: #222222;
  padding: 15px 20px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  font-weight: 700;
  color: #fff;
  font-size: 16px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  @media screen and (max-width: 900px) {
    font-size: 12px;
    width: 98% !important;
    border-radius: 16px;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto 10px;
    height: 90px;
  }
  @media screen and (max-width: 768px) {
    width: 97% !important;
  }
  @media screen and (max-width: 450px) {
    width: 95% !important;
  }
`;

export const ModalTable_Box_Mobile_pare = styled.div`
  padding: 8px 8px;
  display: none;
  @media screen and (max-width: 900px) {
    display: block;
  }
`;
export const ModalTable_Box_Mobile_Pare_Inner = styled.div`
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 3px;
  @media screen and (max-width: 900px) {
    border-radius: 16px;
  }
`;
export const MobileTable_Box_Mobile_Pare_Top = styled.div`
  padding: 10px;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  font-weight: bold;
  overflow: hidden;
`;
export const MobileTable_Box_Mobile_pare_TopP = styled.p`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellpsis;
  font-weight: normal;
  white-space: nowrap;
  max-width: 70%;
`;
export const MobileTable_Box_Mobile_pare_Bottom = styled.div`
  padding: 10px;
  background-color: #f6f6f6;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;
export const MobileTable_Box_Mobile_pare_BottomP = styled.p`
  color: #000000;
  font-size: 12px;
`;
export const ModalTable_Box = styled.div`
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;

  padding-bottom: 16px;
  padding-top: 16px;
  font-size: 16px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  cursor: pointer;
  :hover {
    background-color: #f5f5f5;
  }
  @media screen and (max-width: 900px) {
    display: none;
  }
`;
export const ModalTable_Box_Pare = styled.div`
  padding: 0 25px;
  width: 20%;
`;
export const ReviewTable = styled.table`
  width: 100%;
  display: block;
  margin-bottom: 30px;
  white-space: nowrap;
  overflow-x: auto;

  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }

  thead th {
    padding: 10px 20px;
    font-size: 25px;
    @media screen and (max-width: 768px) {
      font-size: 15px;
    }
    color: #6f6f6f;
    text-align: left;
    background: #eaeaea;
  }
  tbody {
    width: 100%;
  }
  tbody tr td {
    padding: 10px 20px;
    font-size: 20px;
    @media screen and (max-width: 768px) {
      font-size: 14px;
      font-weight: 500;
    }
    text-align: left;
    margin: 10px 0;
  }
  tbody tr {
    margin: 10px 0;
  }
`;
export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 324px; */
  height: 598px;
  @media screen and (max-width: 1200px) {
    width: 100%;
    height: 540px;
  }
`;

export const CardSButtonM = styled.a`
  display: none;
  @media screen and (max-width: 1200px) {
    display: flex;
    width: 100%;
    height: 40px;
    background: #222222;
    margin-top: 35px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0px;
    display: flex;

    color: white;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
  }
`;

export const CardSButton = styled.a`
  width: 139px;
  height: 35px;
  background: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  margin-top: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;
export const CardItem = styled.div`
  width: 324px;
  height: 598px;
  display: flex;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 10px;
  @media screen and (max-width: 1200px) {
    width: 100%;
    height: 100%;
    border: none;
  }
`;
export const CardInfoView = styled.div`
  display: flex;
  margin-top: 24px;
  align-items: center;
`;

export const CardPriceView = styled.div`
  margin-top: 5px;
  font-weight: bold;
  font-size: 12px;
`;
export const CardInfoTitleView = styled.div`
  color: #777777;
  font-size: 12px;
`;
export const CardInfoButtonView = styled.div`
  font-size: 12px;
  padding: 5px 10px;
  /* border: 0.7px solid #cccccc; */
  border-radius: 5px;
  margin-left: 9px;
  background-color: #222;
  color: #fff;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;
export const CardItemInner = styled.div`
  width: 272px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const CardImageView = styled.img`
  width: 227px;
  margin-top: 32px;
  background-color: #eee;
  height: 144px;
`;
export const CardTitleView = styled.div`
  font-weight: bold;
  font-size: 16px;
  font-weight: bold;
  margin-top: 16px;
`;
export const CardSubTitleView = styled.div`
  margin-top: 5px;
  font-size: 12px;
  border-bottom: 0.5px solid #ddd;
  padding-bottom: 22px;
  color: #777;
  text-align: center;
  width: 100%;
`;
export const ModalCardItem = styled.div`
  min-width: 315px;
  //height: 370px;
  padding-left: 16px;
  padding-right: 16px;
  height: 572px;
  background: #fff;
  border: 1.5px solid #f0f0f0;
  border-radius: 8px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  @media screen and (max-width: 768px) {
    height: 400px;
    font-size: 11px;
  }

  @media screen and (max-width: 450px) {
  }
`;
export const ModalConBoxMinHeight = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 12px;
  min-height: 228px;
  @media screen and (max-width: 768px) {
    min-height: 180px;
  }
`;
export const ModalCardInner = styled.table`
  display: flex;
  overflow-x: scroll;
  height: 100%;

  @media screen and (max-width: 700px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const NameText = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

export const PriceText = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

export const Imgskeleton = styled.img`
  max-width: 90%;
  height: 200px;
  border: none;
  resize: both;
  margin: 0 auto;
  border-radius: 5px;
  object-fit: contain;
  @media screen and (max-width: 768px) {
    height: 85px;
  }
`;

export const CardDiscount_View = styled.div`
  width: 25%;
  font-size: 16px;
  font-weight: 600;
  box-shadow: inset 0 -8px 0 #d9fcdb;
`;

export const CardSelect = styled.div`
  background: orange;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    font-size: 11px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
`;

export const CardSeletText = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
`;

export const AttentionMessage = styled.p`
  background: #f3f3f3;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  margin-top: 12px;
  font-size: 13px;
  border-radius: 15px;
  letter-spacing: -0.5px;
  @media (max-width: 1280px) {
  }
`;

export const LiviewBox = styled.div`
  width: 100%;
  height: 435px;
  display: flex;
  @media screen and (max-width: 786px) {
    font-size: 10px;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
`;

export const LiviewLeft = styled.div`
  width: 75%;
  height: 100%;
  @media screen and (max-width: 786px) {
    height: 350px;
    width: 100%;
    background-color: #eee;
  }
  @media screen and (max-width: 500px) {
    height: 245px;
    width: 100%;
    background-color: #eee;
  }
`;

export const LiviewTitle = styled.div`
  font-size: 16px;
  color: #000;
  font-weight: bold;
`;
export const LiviewRight = styled.div`
  width: 50%;
  height: 538px;
  margin-left: 12px;
  @media screen and (max-width: 786px) {
    margin-left: 0px;
    padding: 10px 25px;
    margin-top: 16px;
    width: 100%;
    height: auto;
  }
`;

export const LiviewStarLine = styled.div`
  margin-top: 2px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 12px;
`;

export const LiviewStarBox = styled.div``;
export const LiviewTextBox = styled.div`
  height: 380px;
  overflow-y: scroll;
  margin-top: 16px;
  font-size: 12px;
  & > img {
    width: 77px;
    height: 50px;
    margin-top: 25px;
    border: 2px solid #000;
  }
  @media screen and (max-width: 786px) {
    overflow-y: visible;
    height: 100%;
  }
`;

export const LiviewDateBox = styled.div`
  font-weight: 300;
  font-size: 13px;
  color: #999;
`;

export const LiviewModalWrapper = styled.div`
  max-width: 990px;
  width: 100%;
  padding: 0;
  box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.25);
  background: #fff;
  height: 581px;
  position: relative;
  border-radius: 10px;
  @media screen and (max-width: 786px) {
    margin: 0 auto;
    width: 90%;
    height: 750px;
  }
  @media screen and (max-width: 500px) {
    margin: 0 auto;
    width: 100%;
    height: 600px;
  }
`;

export const CloseBox2 = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 999px;
  /* background-color: #f5f5f5; */
  position: absolute;
  top: 0%;
  right: 0px;
  cursor: pointer;
  z-index: 99999;
`;

export const CloseBox = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 999px;
  background-color: #f5f5f5;
  position: absolute;
  top: 2%;
  left: 93%;
  cursor: pointer;
  z-index: 99999;

  ${mq[1]} {
    left: 89%;
  }
  ${mq[0]} {
    left: 88%;
  }
  ${mq[4]} {
    left: 85%;
  }
  ${mq[5]} {
    left: 82%;
  }
`;
export const PhoneSummaryWrappered = styled.div`
  border: 1px solid black;
  border-radius: 10px;
  margin-top: 10px;
  background-color: #fff;
  min-width: 277px;
`;