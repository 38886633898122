import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import Faq from "react-faq-component";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
import {
  GetAuthData,
  GetCardList,
  GetCardListN,
  GetInforMation,
  GetInformationOrigin,
  GetInformationOriginNone,
  GetProductInfo,
  GetQaList,
  GetStoreInfo,
  GetStoreNInfo,
  GetUsageCate,
  LiviewFindAll,
} from "Datas/swr";
import moment from "moment";
import {
  CloseBtnIco,
  Kt_IC,
  Left_IC,
  Line_IC,
  Right_IC,
  R_ViewICO,
  StarIcon,
  T_IC,
  U_IC,
} from "assets";
import { DeleteReview } from "Datas/api";
import { handlerTopMovement } from "Settings/util";
import WhiteCancel from "../../assets/files/whiteCancel.png";
import GreyCancel from "../../assets/files/greyCancel.png";
import ReactStars from "react-stars";
import Slider from "react-slick";
import DownArrow from "../../assets/files/downArrow.png";
import Carousel from "react-material-ui-carousel";
import AliceCarousel from "react-alice-carousel";
import { Pagination } from "react-pagination-bar";
import "react-pagination-bar/dist/index.css";

import "react-alice-carousel/lib/alice-carousel.css";
import {
  Wrapper,
  Top,
  Things,
  Inner,
  PhonePreview,
  PhonePreviewImg,
  PhonePreviewInfo,
  PhonePreviewName,
  PhonePreviewDiscountPrice,
  PhoneOptionWrapper,
  PhoneOption,
  PhoneOptionCircleMemory,
  PhoneOptionName,
  PhoneOptionCircle,
  SelectBox,
  PhoneSummary,
  PhoneSummaryKeyValue,
  PhoneSummaryKey,
  PhoneSummaryValue,
  PhoneSummaryWrapper,
  Phone5G,
  SummaryLogo,
  PhoneOptionAll,
  PhoneOptionRateBox,
  PhoneOptionRateBoxNameAndDetail,
  PhoneOptionRateBoxName,
  PhoneOptionRateBoxPrice,
  PhoneOptionRateBoxDetail,
  PhoneOptionDiscountWrapper,
  PhoneOptionDiscount,
  PhoneOptionSelect,
  PhoneOptionNotSelect,
  BelowBar,
  BelowBarInner,
  BelowBarBtn,
  PhoneSummaryTotal,
  PhoneSummaryTotalEach,
  FaqWrapper,
  FaqLabel,
  FaqLabelSpan,
  ReviewBox,
  Selected,
  MobileChangeBox,
  MobileChangeLeftBox,
  MobileChangeRightBox,
  Bar,
  BottomSmLabel,
  BottomSmPrice,
  BottomLgLabel,
  BottomLgPrice,
  ModalWrapper,
  ModalHeader,
  ReviewTable,
  ModalCardItem,
  ModalCardInner,
  NameText,
  PriceText,
  Imgskeleton,
  CardDiscount_View,
  CardSelect,
  CardSeletText,
  AttentionMessage,
  CloseBox,
  PhoneOrnerOption,
  StyledSelImage,
  PriceVal,
  PrKaKa,
  ReviceColumn,
  ReviceColumnIn,
  ReviceColumnLeftImg,
  ReviewRihgtBox,
  ReviewRightBox_Title,
  ReviewRightBox_Star,
  ReviewRightBox_Point,
  ReviewRightBox_Content,
  ModalTitleValue_Box,
  ModalTitleFlex,
  ModalTitleFlex_Pare,
  ModalTitle_ImgBox,
  ModalTable_Box,
  ModalTable_Box_Pare,
  ModalTable_Box_Mobile_Pare_Inner,
  MobileTable_Box_Mobile_Pare_Top,
  MobileTable_Box_Mobile_pare_TopP,
  MobileTable_Box_Mobile_pare_Bottom,
  MobileTable_Box_Mobile_pare_BottomP,
  ModalTable_Box_Mobile_pare,
  ProductFont_Resize,
  ModalConBoxMinHeight,
  RawRightClick,
  TagImage,
  PhonePreviewLeftName,
  PhonePreviewRightBox,
  PhonePreviewRightPrice,
  PhonePreviewRightTitle,
  PhonePreviewRightTitle_T,
  PhonePreviewRightTitle_B,
  PhoneBorderBox,
  PhoneBorderLeft,
  PhoneBorderRight,
  PhoneSummaryWrappered,
  PhoneOptionCircleVers,
  GigaBox,
  OptionBox,
  SizeBox,
  PhoneColorPare,
  PhoneColorPareText,
  PhoneOptionCircleMemoryHard,
  CardItem,
  CardImageView,
  CardTitleView,
  CardItemInner,
  CardSubTitleView,
  CardInfoView,
  CardInfoTitleView,
  CardInfoButtonView,
  CardPriceView,
  CardWrapper,
  CardSButton,
  LeftButton,
  RightButton,
  ModalWrapperLists,
  CardSButtonM,
  CloseBox2,
  LiviewModalWrapper,
  LiviewBox,
  LiviewLeft,
  LiviewRight,
  LiviewStarLine,
  LiviewStarBox,
  LiviewDateBox,
  LiviewTextBox,
  RightButton2,
  LeftButton2,
  LiviewTitle,
  PhoneOptionName2,
  MobileChangeBox2,
  PhoneOptionCircleMemory2,
  PhoneOption2,
  MobileChangeBox3,
  PhoneOptionCircle2,
} from "./styles";
import CustomPagination from "Settings/pagination";
import { useAuth } from "Datas/storage";

const CareerColorSwitch = styled.div`
  display: inline-block;
  border: ${(props) => {
    if (props.selected) {
      return "2px solid black";
    }
    return "1px solid #CCCCCC";
  }};
  border-radius: 7px;
  width: 40px;
  height: 40px;

  padding: ${(props) => {
    if (props.Custom) {
      return "9px";
    }
  }};
  border-radius: ${(props) => {
    if (props.Custom) {
      return "10px";
    }
  }};
  display: ${(props) => {
    if (props.Custom) {
      return "flex";
    }
  }};
  justify-content: ${(props) => {
    if (props.Custom) {
      return "center";
    }
  }};
  align-items: ${(props) => {
    if (props.Custom) {
      return "center";
    }
  }};
  cursor: pointer;
`;

const CareerColorSwitch_Inner = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: ${(props) => {
    if (props.color) {
      return `rgba(${props.color.r}, ${props.color.g}, ${props.color.b}, ${props.color.a})`;
    }
  }};
`;

const handleDragStart = (e) => e.preventDefault();

const items = [
  <CardWrapper>
    <CardItem onDragStart={handleDragStart} role="presentation">
      <CardItemInner>
        <CardImageView></CardImageView>
        <CardTitleView>KB카드</CardTitleView>
        <CardSubTitleView>고객센터 1577-5500</CardSubTitleView>
        <CardInfoView>
          <CardInfoTitleView>전월실적 300,000원 이상</CardInfoTitleView>
          <CardInfoButtonView>선택하기</CardInfoButtonView>
        </CardInfoView>
        <CardPriceView>
          매월 <span style={{ color: "red" }}>6,000원</span> x 24 개월 = 총{" "}
          <span style={{ color: "red" }}>144,000</span>원
        </CardPriceView>
      </CardItemInner>
      <CardSButtonM>자세히보기</CardSButtonM>
    </CardItem>

    <CardSButton>자세히보기</CardSButton>
  </CardWrapper>,
  <CardWrapper>
    <CardItem onDragStart={handleDragStart} role="presentation">
      <CardItemInner>
        <CardImageView></CardImageView>
        <CardTitleView>KB카드</CardTitleView>
        <CardSubTitleView>고객센터 1577-5500</CardSubTitleView>
        <CardInfoView>
          <CardInfoTitleView>전월실적 300,000원 이상</CardInfoTitleView>
          <CardInfoButtonView>선택하기</CardInfoButtonView>
        </CardInfoView>
        <CardPriceView>
          매월 <span style={{ color: "red" }}>6,000원</span> x 24 개월 = 총{" "}
          <span style={{ color: "red" }}>144,000</span>원
        </CardPriceView>
      </CardItemInner>
    </CardItem>
    <CardSButton>자세히보기</CardSButton>
  </CardWrapper>,
  <CardWrapper>
    <CardItem onDragStart={handleDragStart} role="presentation">
      <CardItemInner>
        <CardImageView></CardImageView>
        <CardTitleView>KB카드</CardTitleView>
        <CardSubTitleView>고객센터 1577-5500</CardSubTitleView>
        <CardInfoView>
          <CardInfoTitleView>전월실적 300,000원 이상</CardInfoTitleView>
          <CardInfoButtonView>선택하기</CardInfoButtonView>
        </CardInfoView>
        <CardPriceView>
          매월 <span style={{ color: "red" }}>6,000원</span> x 24 개월 = 총{" "}
          <span style={{ color: "red" }}>144,000</span>원
        </CardPriceView>
      </CardItemInner>
    </CardItem>
    <CardSButton>자세히보기</CardSButton>
  </CardWrapper>,
  <CardWrapper>
    <CardItem onDragStart={handleDragStart} role="presentation">
      <CardItemInner>
        <CardImageView></CardImageView>
        <CardTitleView>KB카드</CardTitleView>
        <CardSubTitleView>고객센터 1577-5500</CardSubTitleView>
        <CardInfoView>
          <CardInfoTitleView>전월실적 300,000원 이상</CardInfoTitleView>
          <CardInfoButtonView>선택하기</CardInfoButtonView>
        </CardInfoView>
        <CardPriceView>
          매월 <span style={{ color: "red" }}>6,000원</span> x 24 개월 = 총{" "}
          <span style={{ color: "red" }}>144,000</span>원
        </CardPriceView>
      </CardItemInner>
    </CardItem>
    <CardSButton>자세히보기</CardSButton>
  </CardWrapper>,
  <CardWrapper>
    <CardItem onDragStart={handleDragStart} role="presentation">
      <CardItemInner>
        <CardImageView></CardImageView>
        <CardTitleView>KB카드</CardTitleView>
        <CardSubTitleView>고객센터 1577-5500</CardSubTitleView>
        <CardInfoView>
          <CardInfoTitleView>전월실적 300,000원 이상</CardInfoTitleView>
          <CardInfoButtonView>선택하기</CardInfoButtonView>
        </CardInfoView>
        <CardPriceView>
          매월 <span style={{ color: "red" }}>6,000원</span> x 24 개월 = 총{" "}
          <span style={{ color: "red" }}>144,000</span>원
        </CardPriceView>
      </CardItemInner>
    </CardItem>
    <CardSButton>자세히보기</CardSButton>
  </CardWrapper>,
  <CardWrapper>
    <CardItem onDragStart={handleDragStart} role="presentation">
      <CardItemInner>
        <CardImageView></CardImageView>
        <CardTitleView>KB카드</CardTitleView>
        <CardSubTitleView>고객센터 1577-5500</CardSubTitleView>
        <CardInfoView>
          <CardInfoTitleView>전월실적 300,000원 이상</CardInfoTitleView>
          <CardInfoButtonView>선택하기</CardInfoButtonView>
        </CardInfoView>
        <CardPriceView>
          매월 <span style={{ color: "red" }}>6,000원</span> x 24 개월 = 총{" "}
          <span style={{ color: "red" }}>144,000</span>원
        </CardPriceView>
      </CardItemInner>
    </CardItem>
    <CardSButton>자세히보기</CardSButton>
  </CardWrapper>,
];
const FaqRender = ({ qadata }) => {
  const [select, setSelect] = useState(0);
  const [data, setData] = useState({
    rows: [
      {
        title: "tt",
        content: "tt",
      },
    ],
  });

  useEffect(() => {
    let newArray = [];
    if (qadata !== undefined) {
      let newdata = [...qadata];
      switch (select) {
        case 0:
          newArray = newdata
            ?.map((item, index) => {
              if (item.opttype !== 0) {
                return;
              } else {
                return {
                  title: item.title,
                  content: item.contents,
                };
              }
            })
            .filter((item) => item !== undefined);
          break;
        case 1:
          newArray = newdata
            ?.map((item, index) => {
              if (item.opttype !== 1) {
                return;
              } else {
                return {
                  title: item.title,
                  content: item.contents,
                };
              }
            })
            .filter((item) => item !== undefined);
          break;
        case 2:
          newArray = newdata
            ?.map((item, index) => {
              if (item.opttype !== 2) {
                return;
              } else {
                return {
                  title: item.title,
                  content: item.contents,
                };
              }
            })
            .filter((item) => item !== undefined);
          break;
        default:
          break;
      }
      newdata.rows = newArray;
      setData(newdata);
    }
  }, [qadata, select]);

  return (
    <FaqWrapper>
      <FaqLabel>
        <FaqLabelSpan
          action={select === 0}
          aria-selected={select === 0}
          onClick={() => {
            setSelect(0);
          }}
        >
          신청 및 개통문의
        </FaqLabelSpan>
        <FaqLabelSpan
          action={select === 1}
          aria-selected={select === 1}
          onClick={() => {
            setSelect(1);
          }}
        >
          배송문의
        </FaqLabelSpan>
        <FaqLabelSpan
          action={select === 2}
          aria-selected={select === 2}
          onClick={() => {
            setSelect(2);
          }}
        >
          취소 교환 반품문의
        </FaqLabelSpan>
      </FaqLabel>
      <Faq data={data} />
    </FaqWrapper>
  );
};

const Product = ({ location, profile, match, history }) => {
  const [datas, setDatas] = useState({
    storage: "",
    color: "",
    oldtelecom: "",
    newtelecom: "",
    plan: "",
    discount: match?.params?.discount,
    Sales: 0,
    checkout: "",
    sim: "",
    sktDiscout: "",
    card: "",
    storagePrice: 0,
  });
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const { pathname } = window.location;
  const [allPrice, setAllPrice] = useState(0);
  const [isRatePlanModalOpen, setisRatePlanModalOpen] = useState(false);
  const [isCardModalOpen, setisCardModalOpen] = useState(false);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [isPhoneViewOpen, setIsPhoneViewOpen] = useState(false);
  const [phoneImg, setPhoneImg] = useState(0);
  const [selectedReview, setSelectedReview] = useState(null);
  const [bene, setBene] = useState(false);
  const [telCount, setTelcount] = useState(0);
  const [cardSelected, setCardSelected] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [telId, setTelId] = useState(1);
  const { ProductInfoData, isLoading, ProductInfoMutate } = GetProductInfo(
    Number(match?.params?.id),
    profile?.DLMode,
    profile?.userId,
    profile?.joincode,
    profile?.storeid
    //DLMode
    //userid
    //joincode
    //storeid
  );
  //:DLMode/:userid/:joincode/:storeid
  const [LiviewsData, setLiviewsData] = useState([]);

  const [DC_NEWOPTION, setDC_NEWOPTION] = useState(0);
  const [offset, setOffset] = useState(0);
  const [page, setPages] = useState([]);
  const { LiviewData, LiviewMutate } = LiviewFindAll({
    offset: offset,
    limit: 10,
  });

  const { QaListData, QaListMutate } = GetQaList({ offset: 0, limit: 9999 });
  const [currentTele, setCurrentTele] = useState(undefined); //현재 텔레데이터



  const { OriginData } = GetInformationOrigin();
  const { OriginNData } = GetInformationOriginNone();
  const { AuthData } = GetAuthData();
  //전체 텔레콤리스트를 필터링하여야함


  const { GetUsageCateData } = GetUsageCate(match?.params?.id, telId);
  //feat

  const { CardListNData } = GetCardListN(datas?.newtelecom, 0);
  const { CardListData } = GetCardList(datas?.newtelecom, 0);
  //feat 

  const { StoreInfoData } = GetStoreInfo(profile?.storeid);
  const { StoreNInfoData, StoreNInfoError, StoreNInfoMutate } = GetStoreNInfo(profile?.storeid)

  const [changePrice, setChangePrice] = useState(0);
  const CheckoutMonth = datas?.checkout.replace("개월", "");


  useEffect(() => {
    if (ProductInfoData) {
      if (datas?.newtelecom === datas?.oldtelecom || datas?.discount === "선택약정할인") {
        setChangePrice(0)
      } else {

        setChangePrice(ProductInfoData?.telecom_product_key_product.filter(
          (item, idx) => item.id === currentTele
        )[0]?.telecom_product.change_sales_price)
      }

    }
  }, [datas, currentTele, ProductInfoData])
  useEffect(() => {
    if (!isLoading) {
      setAllPrice(ProductInfoData?.price);

      setCurrentTele(Number(match?.params?.chooseBase)); //선택된 텔레콤아이디 세팅
      setDatas({
        ...datas,
        oldtelecom: match?.params?.tel,
        newtelecom: match?.params?.newtel,
        color: ProductInfoData?.ColorPrices[0]?.name,
        storage: ProductInfoData?.HardPrices[0]?.name,
        storagePrice: ProductInfoData?.HardPrices[0]?.price,
        price: ProductInfoData?.HardPrices[0]?.price,
        sim:
          match?.params?.tel === match?.params?.newtel
            ? "기존 유심"
            : "새 유심",
        checkout: "24개월",
      });
      //tel id 규정

      setTelId(
        ProductInfoData?.telecom_product_key_product.filter(
          (item, idx) => item.id === Number(match?.params?.chooseBase)
        )[0]?.telecomid
      );
      //이구간에서 선택된 텔레콤만필터하여서 계산해주면됨
      //선택된데이터이므로 해당데이터 기반으로 뿌려줌
    }
  }, [isLoading]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (datas.oldtelecom === datas.newtelecom) {
      setTelcount(0);
    } else {
      let price = ProductInfoData?.telecom_product_key_product?.filter(
        (item) => item.name === datas.newtelecom
      )[0]?.telecom_product?.dc_move_price;
      setTelcount(price);
    }

    if (datas?.oldtelecom === "신규가입") {
      if (datas?.discount === "공시지원할인") {
        setDC_NEWOPTION(
          ProductInfoData?.telecom_product_key_product.filter(
            (item, idx) => item.id === currentTele
          )[0]?.telecom_product.dc_sales_new_price
        );
      } else {
        setDC_NEWOPTION(
          ProductInfoData?.telecom_product_key_product.filter(
            (item, idx) => item.id === currentTele
          )[0]?.telecom_product.dc_choose_new_price
        );
      }
    } else {
      setDC_NEWOPTION(0);
    }
  }, [datas]);



  let ORigins = AuthData?.data?.selectStore === "인증방식" ? OriginData : OriginData;
  const CardsData = AuthData?.data?.selectStore === "인증방식" ? CardListData : CardListData;

  const StoredtIds = AuthData?.data?.selectStore === "인증방식" ? StoreInfoData : StoreInfoData;


  const _handleDeleteReview = async (id) => {
    let res = await DeleteReview(id);
    if (res === false) {
      alert("잘못된 요청입니다");
      return;
    } else {
      await ProductInfoMutate();
      return;
    }
  };



  // 출시가격

  let MarketPp = 0;
  if (datas.newtelecom === "SKT") {
    MarketPp = ProductInfoData?.SKTPrice
  } else if (datas.newtelecom === "KT") {
    MarketPp = ProductInfoData?.KTPrice

  } else if (datas.newtelecom === "LGU+") {
    MarketPp = ProductInfoData?.LGUPrice
  }

  const Beforediscount =
    Number(MarketPp) + Number(datas?.storagePrice);

  // 공시지원 / 선택약정 + 기기변경 / 통신사 이동 가격
  // 공시지원 기본

  console.log(ProductInfoData, "인포");
  // 출시가격 - 제휴카드 할인

  // 공시지원 / 선택약정 + 기기변경 / 통신사 이동 가격 + 제휴카드 할인 + 폰테크 슈퍼 D.C
  const GongYou = ProductInfoData?.telecom_product_key_product.filter(
    (item, idx) => item.id === currentTele
  )[0]?.pricemonth;
  // 출시가격 - (공시지원 & 선택약정 기본 + 기기변경 & 통신사 이동 + 제휴카드 할인 + 폰테크 슈퍼 D.C)
  const PerCent = (allPrice, percent) => {
    return Math.round(((allPrice * percent) / 100) * 24);
  };
  // 출고가
  const PRODUCT_PRICE =
    Number(MarketPp) + Number(datas?.storagePrice);




  let DC_PRICE =
    datas?.discount === "공시지원할인"
      ? ProductInfoData?.telecom_product_key_product.filter(
        (item, idx) => item.id === currentTele
      )[0]?.telecom_product.dc_sales_price
      : PerCent(
        GongYou,
        Number(
          ProductInfoData?.telecom_product_key_product.filter(
            (item, idx) => item.id === currentTele
          )[0]?.telecom_product.dc_chooses_price
        )
      );
  //전환지원금



  /* datas?.discount === "공시지원할인"  ?  ProductInfoData?.telecom_product_key_product.filter(
  (item, idx) => item.id === currentTele
)[0]?.telecom_product.dc_sales_new_price   : ProductInfoData?.telecom_product_key_product.filter(
          (item, idx) => item.id === currentTele
  )[0]?.telecom_product.dc_choose_new_price */

  let DC_PRICE_TYPE =
    datas?.discount === "공시지원할인"
      ? datas?.oldtelecom === datas?.newtelecom
        ? ProductInfoData?.telecom_product_key_product.filter(
          (item, idx) => item.id === currentTele
        )[0]?.telecom_product.dc_sales_change_price
        : ProductInfoData?.telecom_product_key_product.filter(
          (item, idx) => item.telecomname === datas?.newtelecom
        )[0]?.telecom_product.dc_sales_mnp_price
      : datas?.oldtelecom === datas?.newtelecom
        ? ProductInfoData?.telecom_product_key_product.filter(
          (item, idx) => item.id === currentTele
        )[0]?.telecom_product.dc_choose_change_price
        : ProductInfoData?.telecom_product_key_product.filter(
          (item, idx) => item.telecomname === datas?.newtelecom
        )[0]?.telecom_product.dc_choose_mnp_price;
  if (ORigins?.DcTrans) {
    DC_PRICE_TYPE = 0;
  }

  // 케이스메이커 슈퍼 D.C

  // 제휴카드 할인

  const CARD_DC = cardSelected !== 0 ? cardSelected?.monthlyDiscount : 0;

  // 총 할인금액

  // 할부원금

  const DcPrice = () => {
    let mnps;
    let changes;
    if (datas.oldtelecom !== datas.newtelecom) {
      //번호이동
      //선택약정 기기변경 조건절
      if (datas.discount === "선택약정할인") {
        //선택약정 번호이동비
        mnps = ProductInfoData?.telecom_product_key_product.filter(
          (item, idx) => item.id === currentTele
        )[0]?.telecom_product.dc_choose_mnp_price;
      } else {
        //공시지원번호이동비
        mnps = ProductInfoData?.telecom_product_key_product.filter(
          (item, idx) => item.id === currentTele
        )[0]?.telecom_product.dc_sales_mnp_price;
      }
      if (ORigins?.DcTrans) {
        mnps = 0;
      }
      return mnps - Number(StoredtIds?.sale);
    } else {
      //기기변경
      if (datas.discount === "선택약정할인") {
        //선택약정 기기변경비
        changes = ProductInfoData?.telecom_product_key_product.filter(
          (item, idx) => item.id === currentTele
        )[0]?.telecom_product.dc_choose_change_price;
      } else {
        //공시지원 기기변경비
        changes = ProductInfoData?.telecom_product_key_product.filter(
          (item, idx) => item.id === currentTele
        )[0]?.telecom_product.dc_sales_change_price;
      }
      if (ORigins?.DcTrans) {
        changes = 0;
      }
      return changes - Number(StoredtIds?.sale);
    }
  };
  let TongPrice;
  if (datas.newtelecom === "SKT") {
    console.log("에스케이티");
    TongPrice = ProductInfoData?.sktPrice;
  }
  if (datas.newtelecom === "KT") {
    console.log("케이티");

    TongPrice = ProductInfoData?.ktPrice;
  }
  if (datas.newtelecom === "LGU+") {
    console.log("엘지");

    TongPrice = ProductInfoData?.lguPrice;
  }
  console.log(TongPrice, "통프라이스");
  const SunTackHupBe = ProductInfoData?.telecom_product_key_product.filter(
    (item, idx) => item.id === currentTele
  )[0]?.telecom_product.dc_chooses_price;
  const DiscountPrice =
    Number(
      ProductInfoData?.telecom_product_key_product.filter(
        (item, idx) => item.id === currentTele
      )[0]?.telecom_product.dc_sales_price
    ) + (cardSelected !== 0 ? cardSelected?.monthlyDiscount : 0);

  //여기서 20만원 슈펑ㅊ
  const AfterDiscount = Beforediscount - DiscountPrice;
  let TOTAL_DISCOUNT =
    Number(DC_PRICE) +
    DcPrice() +
    (cardSelected !== 0 ? cardSelected?.monthlyDiscount : 0) + changePrice +
    TongPrice;

  const TOTAL_DISCOUNT_GONG =
    DcPrice() + (cardSelected !== 0 ? cardSelected?.monthlyDiscount : 0);

  console.log(DC_PRICE, "선택약정할인금 슈퍼 DC + 카드프라이스");

  const CHECKOUT_PRICE = PRODUCT_PRICE - TOTAL_DISCOUNT - DC_NEWOPTION;
  const SunTackYoGumJae = Math.round(DC_PRICE / 24);
  const CardDiscountPrice =
    Beforediscount - Math.round(TOTAL_DISCOUNT_GONG) - TongPrice - DC_NEWOPTION;
  if (datas?.oldtelecom === "신규가입") {
    TOTAL_DISCOUNT = TOTAL_DISCOUNT + DC_NEWOPTION;
  }
  const tos1 = ProductInfoData?.telecom_product_key_product.filter(
    (item, idx) => item.id === currentTele
  )[0]?.pricemonth;
  const tos2 = ProductInfoData?.telecom_product_key_product.filter(
    (item, idx) => item.id === currentTele
  )[0]?.pricemonth;
  const tos = ProductInfoData?.telecom_product_key_product.filter(
    (item, idx) => item.id === currentTele
  )[0]?.pricemonth;

  const tosd = ProductInfoData?.telecom_product_key_product.filter(
    (item, idx) => item.id === currentTele
  )[0]?.pricemonth;

  const SunTackBonus = CardDiscountPrice < 0 ? Math.abs(CardDiscountPrice) : 0;
  const PricePayData = () => {
    let data;

    if (datas.checkout !== "일시불") {
      if (datas?.discount === "공시지원할인") {
        data =
          Math.round(CHECKOUT_PRICE / CheckoutMonth) > 0
            ? Math.round(CHECKOUT_PRICE / CheckoutMonth + tos)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : Number(tos)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        data =
          Math.round(Number(CardDiscountPrice / CheckoutMonth)) > 0
            ? Math.round(Number(CardDiscountPrice / CheckoutMonth + tos))
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : Number(tos)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    } else {
      if (datas?.discount === "공시지원할인") {
        data = ProductInfoData?.telecom_product_key_product.filter(
          (item, idx) => item.id === currentTele
        )[0]?.pricemonth;
      } else {
        data = Math.round(
          ProductInfoData?.telecom_product_key_product.filter(
            (item, idx) => item.id === currentTele
          )[0]?.pricemonth -
          (DC_PRICE + DC_PRICE_TYPE) / 24
        );
      }
    }
    return data;
  };
  const Gong = Math.round(
    Number(CHECKOUT_PRICE / CheckoutMonth) / ORigins?.salePrice
  );
  ///<img[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/i

  const SunTack = Math.round(
    Number(CardDiscountPrice / CheckoutMonth) / ORigins?.salePrice
  );

  const CardMonthSale = isNaN(Math.round(CARD_DC / CheckoutMonth))
    ? 0
    : Math.round(CARD_DC / CheckoutMonth);

  let InfoDatas;
  if (datas?.newtelecom === "SKT") {
    InfoDatas = ProductInfoData?.productinfoS;
  }
  if (datas?.newtelecom === "KT") {
    InfoDatas = ProductInfoData?.productinfoK;
  }
  if (datas?.newtelecom === "LGU+") {
    InfoDatas = ProductInfoData?.productinfoL;
  }

  const responsive = {
    0: { items: 1 },

    1196: { items: 3 },
  };

  const responsive_L = {
    0: { items: 1 },
  };
  const MonthPrice = ({ month }) => {
    console.log(month, "먼드");
    let Days = Number(month);
    if (Days === 24) {
      return 0.062614;
    }
    if (Days === 30) {
      return 0.078014;
    }
    if (Days === 36) {
      return 0.093559;
    }
  };

  const Retral = (
    Math.round(CHECKOUT_PRICE / CheckoutMonth + GongYou) +
    Math.round(
      Math.round(
        (Math.round(CHECKOUT_PRICE * MonthPrice({ month: CheckoutMonth })) +
          Number(CHECKOUT_PRICE)) *
        (ORigins?.salePrice / 100)
      ) / Number(CheckoutMonth)
    )
  ).toLocaleString();
  const Retral2 = (
    Math.round(
      Number(CardDiscountPrice / CheckoutMonth + tos - SunTackYoGumJae)
    ) +
    Math.round(
      Math.round(
        (Math.round(CardDiscountPrice * MonthPrice({ month: CheckoutMonth })) +
          Number(CardDiscountPrice)) *
        (ORigins?.salePrice / 100)
      ) / Number(CheckoutMonth)
    )
  ).toLocaleString();
  return (
    <>
      <Fade Button>
        <Wrapper>
          <Inner style={{ maxWidth: 1280 }}>
            <Modal
              closeTimeoutMS={300}
              style={{
                overlay: {
                  position: "fixed",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "rgba(10, 30, 66, 0.4)",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100%",
                  padding: "0 20px",
                  zIndex: "999",
                },
                content: {
                  position: null,
                  maxWidth: 1190,
                  width: "100%",
                  padding: "0",
                  background: "#fff",
                  maxHeight: "743px",
                  inset: null,
                  border: null,
                  display: "flex",
                  flexDirection: "column",
                },
              }}
              isOpen={isCardModalOpen}
              onRequestClose={() => {
                setisCardModalOpen(false);
              }}
            >
              <div
                style={{
                  width: "100%",
                  padding: "0",
                  borderRadius: "16px",
                  background: "#fafafa",
                  maxHeight: "788px",
                  position: "relative",
                }}
              >
                <StyledSelImage
                  src={CloseBtnIco}
                  onClick={() => {
                    setisCardModalOpen(false);
                  }}
                />
                <ModalWrapperLists>
                  <AliceCarousel
                    renderPrevButton={() => {
                      return <LeftButton src={Left_IC} />;
                    }}
                    renderNextButton={() => {
                      return <RightButton src={Right_IC} />;
                    }}
                    responsive={responsive}
                    items={CardsData?.rows?.map((item, idx) => {
                      return (
                        <CardWrapper>
                          <CardItem
                            onDragStart={handleDragStart}
                            role="presentation"
                          >
                            <CardItemInner>
                              <CardImageView
                                src={item?.images[0]}
                              ></CardImageView>
                              <CardTitleView> {item?.name}</CardTitleView>
                              <CardSubTitleView>
                                고객센터 {item?.tel}
                              </CardSubTitleView>
                              {item?.price?.map((items, idxs) => {
                                return (
                                  <>
                                    <CardInfoView>
                                      <CardInfoTitleView>
                                        전월실적{" "}
                                        {items?.last
                                          ?.toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}{" "}
                                        이상
                                      </CardInfoTitleView>
                                      <CardInfoButtonView
                                        onClick={() => {
                                          setisCardModalOpen(false);
                                          const tempData = {
                                            images: item?.images,
                                            name: item?.name,
                                            discount: items?.discount,
                                            last: items?.last,
                                            monthlyDiscount:
                                              Number(items?.discount) * 24,
                                          };
                                          setCardSelected(0);
                                          setCardSelected({
                                            images: item?.images,
                                            name: item?.name,
                                            discount: Number(items?.discount),
                                            last: Number(items?.last),
                                            monthlyDiscount: Number(
                                              Number(items?.discount) * 24
                                            ),
                                          });
                                        }}
                                      >
                                        선택하기
                                      </CardInfoButtonView>
                                    </CardInfoView>
                                    <CardPriceView>
                                      매월{" "}
                                      <span style={{ color: "red" }}>
                                        {items?.discount
                                          ?.toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}{" "}
                                      </span>{" "}
                                      원 x 24 개월 = 총{" "}
                                      <span style={{ color: "red" }}>
                                        {" "}
                                        {(Number(items?.discount) * 24)
                                          ?.toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </span>
                                      원
                                    </CardPriceView>
                                  </>
                                );
                              })}
                            </CardItemInner>
                          </CardItem>
                          <CardSButtonM href={item?.link}>
                            자세히보기
                          </CardSButtonM>
                          <CardSButton href={item?.link}>
                            자세히보기
                          </CardSButton>
                        </CardWrapper>
                      );
                    })}
                  />
                </ModalWrapperLists>
              </div>
            </Modal>
            <Modal
              closeTimeoutMS={300}
              style={{
                overlay: {
                  position: "fixed",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "rgba(10, 30, 66, 0.4)",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100%",
                  padding: "0 20px",
                  zIndex: "999",
                },
                content: {
                  position: null,
                  maxWidth: "896px",
                  width: "100%",
                  padding: 0,
                  background: "#fff",
                  maxHeight: "580px",
                  overflowY: "scroll",
                  inset: null,
                  border: null,
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: 10,
                },
              }}
              isOpen={isRatePlanModalOpen}
              onRequestClose={() => {
                setisRatePlanModalOpen(false);
              }}
            >
              <ModalWrapper style={{ position: "relative" }}>
                <div
                  style={{
                    backgroundColor: "#fff",
                    margin: "0 0 25px",
                  }}
                >
                  <StyledSelImage
                    src={CloseBtnIco}
                    onClick={() => {
                      setisRatePlanModalOpen(false);
                    }}
                  />
                  <ModalTitleFlex>
                    <ModalTitleFlex_Pare>
                      {datas.newtelecom === "SKT" && (
                        <>
                          <ModalTitle_ImgBox src={T_IC} />
                          <ModalTitleValue_Box>SKT 요금제</ModalTitleValue_Box>
                        </>
                      )}
                      {datas.newtelecom === "KT" && (
                        <>
                          <ModalTitle_ImgBox src={Kt_IC} />
                          <ModalTitleValue_Box>KT 요금제</ModalTitleValue_Box>
                        </>
                      )}
                      {datas.newtelecom === "LGU+" && (
                        <>
                          <ModalTitle_ImgBox src={U_IC} />
                          <ModalTitleValue_Box>LGU+ 요금제</ModalTitleValue_Box>
                        </>
                      )}
                    </ModalTitleFlex_Pare>
                  </ModalTitleFlex>
                </div>
                {GetUsageCateData !== undefined &&
                  GetUsageCateData?.length !== 0 ? (
                  GetUsageCateData?.map((el, idx) => {
                    return (
                      <div style={{ marginBottom: 15 }}>
                        <ModalHeader>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <GigaBox>{el?.giga}</GigaBox>
                            <div>{el?.title}</div>
                          </div>
                          <OptionBox>{el?.option}</OptionBox>
                        </ModalHeader>
                        {el?.TypeTeleComs?.length !== 0
                          ? el?.TypeTeleComs.map((item, idx) => {
                            return (
                              <>
                                <ModalTable_Box
                                  isLast={idx === el?.TypeTeleComs?.length}
                                  onClick={() => {
                                    setCurrentTele(item.id);
                                    setisRatePlanModalOpen(false);
                                  }}
                                >
                                  <ModalTable_Box_Pare
                                    style={{
                                      borderRight: "1px solid #e5e5e5",
                                    }}
                                  >
                                    {item?.name}
                                  </ModalTable_Box_Pare>
                                  <ModalTable_Box_Pare
                                    style={{
                                      borderRight: "1px solid #e5e5e5",
                                      fontWeight: "bold",
                                      textAlign: "center",
                                    }}
                                  >
                                    월 {item?.pricemonth.toLocaleString()}원
                                  </ModalTable_Box_Pare>
                                  <ModalTable_Box_Pare
                                    style={{
                                      borderRight: "1px solid #e5e5e5",
                                      fontWeight: "bold",
                                      textAlign: "center",
                                    }}
                                  >
                                    {item?.datarem}
                                  </ModalTable_Box_Pare>
                                  <ModalTable_Box_Pare
                                    style={{
                                      borderRight: "1px solid #e5e5e5",
                                      textAlign: "center",
                                    }}
                                  >
                                    {item?.callrem}
                                  </ModalTable_Box_Pare>
                                  <ModalTable_Box_Pare
                                    style={{ textAlign: "center" }}
                                  >
                                    {item?.smsrem}
                                  </ModalTable_Box_Pare>
                                </ModalTable_Box>
                                <ModalTable_Box_Mobile_pare
                                  onClick={() => {
                                    setCurrentTele(item.id);
                                    setisRatePlanModalOpen(false);
                                  }}
                                >
                                  <ModalTable_Box_Mobile_Pare_Inner>
                                    <MobileTable_Box_Mobile_Pare_Top>
                                      <MobileTable_Box_Mobile_pare_TopP>
                                        {item?.name}
                                      </MobileTable_Box_Mobile_pare_TopP>
                                      <span style={{ color: "red" }}>
                                        월{item?.pricemonth.toLocaleString()}
                                        원
                                      </span>
                                    </MobileTable_Box_Mobile_Pare_Top>
                                    <MobileTable_Box_Mobile_pare_Bottom>
                                      <MobileTable_Box_Mobile_pare_BottomP>
                                        {item?.datarem} + {item?.callrem} +
                                        {item?.smsrem}
                                      </MobileTable_Box_Mobile_pare_BottomP>
                                    </MobileTable_Box_Mobile_pare_Bottom>
                                  </ModalTable_Box_Mobile_Pare_Inner>
                                </ModalTable_Box_Mobile_pare>
                              </>
                              /*  <div style={{ padding: 10 }}>
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  marginBottom: 15,
                                }}
                              >
                                <NameText>{item.name}</NameText>
                                <PriceText style={{ color: "orangered" }}>
                                  {item.pricemonth
                                    ?.toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  원
                                </PriceText>
                              </div>
                              <ReviewTable>
                                <colgroup>
                                  <col style={{ width: "33%" }} />
                                  <col style={{ width: "33%" }} />
                                  <col style={{ width: "33%" }} />
                                </colgroup>
                                <thead>
                                  <tr>
                                    <th>데이터</th>
                                    <th>음성</th>
                                    <th>문자</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{item.datarem}</td>
                                    <td>{item.callrem}</td>
                                    <td>{item.smsrem}</td>
                                  </tr>
                                </tbody>
                              </ReviewTable>

                              <SelectBox>
                                <Selected
                                  onClick={() => {
                                    setCurrentTele(item.id);
                                    setisRatePlanModalOpen(false);
                                  }}
                                  style={{ width: "48.5%" }}
                                >
                                  선택하기
                                </Selected>
                                <Selected
                                  onClick={() => {
                                    setisRatePlanModalOpen(false);
                                  }}
                                  style={{ width: "48.5%" }}
                                >
                                  닫기
                                </Selected>
                              </SelectBox>
                            </div> */
                            );
                          })
                          : "요금제 정보가없습니다"}
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: 500,
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 200,
                      padding: 0,
                      fontSize: 17,
                      fontWeight: "600",
                    }}
                  >
                    요금제가 없습니다
                  </div>
                )}
              </ModalWrapper>
            </Modal>
            {/* 리뷰모달 */}
            <Modal
              closeTimeoutMS={300}
              style={{
                overlay: {
                  position: "fixed",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "rgba(10, 30, 66, 0.4)",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100%",
                  padding: "0 20px",
                  zIndex: "999",
                },
                content: {
                  background: "rgba(0, 0, 0, 0)",
                  position: null,
                  maxWidth: "990px",
                  width: "100%",
                  padding: "20px",
                  inset: null,
                  border: null,
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "scroll",
                },
              }}
              isOpen={isReviewModalOpen}
              onRequestClose={() => {
                setIsReviewModalOpen(false);
              }}
            >
              <LiviewModalWrapper>
                <CloseBox2
                  onClick={() => {
                    setIsReviewModalOpen(false);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 24,
                    fontWeight: "bold",
                  }}
                >
                  ×
                </CloseBox2>

                <ModalWrapper>
                  <AliceCarousel
                    renderPrevButton={() => {
                      return <LeftButton2 src={Left_IC} />;
                    }}
                    renderNextButton={() => {
                      return <RightButton2 src={Right_IC} />;
                    }}
                    responsive={responsive_L}
                    items={LiviewsData?.map((item, idx) => {
                      return (
                        <LiviewBox>
                          <LiviewLeft>
                            <img
                              src={
                                item.contents?.match(
                                  "<img[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>"
                                ) !== null &&
                                String(
                                  item.contents?.match(
                                    "<img[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>"
                                  )[1]
                                )
                              }
                              style={{
                                width: "100%",
                                height: "100%",
                                maxWidth: "100%",
                              }}
                            ></img>
                          </LiviewLeft>
                          <LiviewRight>
                            <LiviewTitle>{item?.title}</LiviewTitle>
                            <LiviewStarLine>
                              <LiviewStarBox>
                                {" "}
                                <ReactStars
                                  count={5}
                                  edit={false}
                                  value={item?.star}
                                  size={15}
                                  color2={"red"}
                                />
                              </LiviewStarBox>
                              <LiviewDateBox>
                                {" "}
                                {moment(item?.createdAt).format("YYYY.MM.DD")}
                              </LiviewDateBox>
                            </LiviewStarLine>
                            <LiviewTextBox
                              dangerouslySetInnerHTML={{
                                __html: item?.contents,
                              }}
                            />
                          </LiviewRight>
                        </LiviewBox>
                      );
                    })}
                  />

                  <ModalCardInner
                    style={{
                      display: "none",
                      flexDirection: "column",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        alignItems: "center",
                        position: "absolute",
                        top: 15,
                        left: 15,
                      }}
                    >
                      <ReviewRightBox_Star>
                        <ReactStars
                          count={5}
                          edit={false}
                          value={selectedReview?.star}
                          size={2}
                          color2={"red"}
                        />
                      </ReviewRightBox_Star>
                    </div>
                    {selectedReview?.images?.length !== 0 && (
                      <img
                        src={selectedReview?.images[0]}
                        style={{
                          width: "100%",
                          maxHeight: 550,
                          resize: "both",
                          objectFit: "cover",
                          borderRadius: 10,
                          borderBottomLeftRadius: 0,
                          borderBottomRightRadius: 0,
                        }}
                      />
                    )}
                    <div
                      style={{
                        width: "100%",
                        padding: 15,
                        marginTop: selectedReview?.images?.length === 0 && 50,
                      }}
                    >
                      <div style={{}}>
                        <div style={{ fontSize: 20, fontWeight: "600" }}>
                          {selectedReview?.title}
                        </div>
                        <div
                          style={{
                            marginTop: 7,
                            fontSize: 14,
                            fontWeight: "600",
                            color: "#a7a7a7",
                          }}
                        >
                          {moment(selectedReview?.createdAt).format(
                            "YYYY년 MM월 DD일"
                          )}
                        </div>
                        <div
                          style={{ marginBottom: 25, marginTop: 15 }}
                          dangerouslySetInnerHTML={{
                            __html: selectedReview?.contents,
                          }}
                        ></div>
                      </div>
                    </div>
                  </ModalCardInner>
                </ModalWrapper>
              </LiviewModalWrapper>
            </Modal>

            <Modal
              closeTimeoutMS={300}
              style={{
                overlay: {
                  position: "fixed",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "rgba(10, 30, 66, 0.4)",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100%",
                  padding: "0 20px",
                  zIndex: "999",
                },
                content: {
                  position: null,
                  maxWidth: "700px",
                  width: "100%",
                  padding: "0",
                  borderRadius: "16px",
                  background: "#fff",
                  maxHeight: "550px",
                  inset: null,
                  border: null,
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "scroll",
                },
              }}
              isOpen={isPhoneViewOpen}
              onRequestClose={() => {
                setIsPhoneViewOpen(false);
              }}
            >
              <div
                style={{
                  maxWidth: "700px",
                  width: "100%",
                  position: "relative",
                }}
                onClick={() => {
                  setIsPhoneViewOpen(false);
                }}
              >
                <CloseBox
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p style={{ color: "red", fontWeight: "bold" }}>X</p>
                </CloseBox>
                <ModalWrapper>
                  <ModalCardInner
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                    }}
                  >
                    <img
                      src={ProductInfoData?.images[phoneImg]}
                      style={{
                        width: "100%",
                        maxHeight: 650,
                        resize: "both",
                        objectFit: "contain",
                      }}
                    />
                  </ModalCardInner>
                </ModalWrapper>
              </div>
            </Modal>
            <Top>
              {/* 수정사항  */}
              <PhonePreview>
                <PhonePreviewImg onClick={() => setIsPhoneViewOpen(true)}>
                  {ProductInfoData?.images !== null &&
                    ProductInfoData?.images?.map((el, idx) => {
                      return <img src={el}></img>;
                    })}
                </PhonePreviewImg>
              </PhonePreview>

              <PhoneOptionAll>
                <PhonePreviewName>
                  <PhonePreviewLeftName>기기정보</PhonePreviewLeftName>
                  <PhonePreviewRightBox>
                    <PhonePreviewRightTitle>
                      <PhonePreviewRightTitle_T>
                        {ProductInfoData?.name}
                      </PhonePreviewRightTitle_T>
                      <PhonePreviewRightTitle_B>
                        {ProductInfoData?.giga}
                      </PhonePreviewRightTitle_B>
                    </PhonePreviewRightTitle>
                    <PhonePreviewRightPrice>
                      {" "}
                      {datas?.discount === "공시지원할인" ? (
                        <>
                          {CHECKOUT_PRICE > 0
                            ? CHECKOUT_PRICE.toString().replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )
                            : 0}
                        </>
                      ) : (
                        <>
                          {CardDiscountPrice < 0
                            ? 0
                            : CardDiscountPrice.toString().replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                        </>
                      )}
                      원
                    </PhonePreviewRightPrice>
                  </PhonePreviewRightBox>
                </PhonePreviewName>
                <PhoneOptionWrapper>
                  <PhoneOption>
                    <PhoneOptionName>용량</PhoneOptionName>
                    <PhoneOptionCircleMemoryHard>
                      {ProductInfoData?.HardPrices.map((item, idx) => {
                        return (
                          <SizeBox
                            selected={datas.storage === item.name}
                            onClick={() =>
                              setDatas({
                                ...datas,
                                storage: item.name,
                                storagePrice: item.price,
                              })
                            }
                          >
                            {item.name}
                          </SizeBox>
                        );
                      })}
                    </PhoneOptionCircleMemoryHard>
                  </PhoneOption>
                </PhoneOptionWrapper>
                <PhoneOptionWrapper>
                  <PhoneOption>
                    <PhoneOptionName>색상</PhoneOptionName>
                    <PhoneOptionCircleMemory>
                      {ProductInfoData?.ColorPrices?.map((item, idx) => {
                        return (
                          <PhoneColorPare>
                            <CareerColorSwitch
                              selected={datas.color === item.name}
                              onClick={() =>
                                setDatas({ ...datas, color: item.name })
                              }
                            >
                              <CareerColorSwitch_Inner
                                color={item?.clcolor}
                              ></CareerColorSwitch_Inner>
                            </CareerColorSwitch>
                            <PhoneColorPareText>
                              {item?.name}
                            </PhoneColorPareText>
                          </PhoneColorPare>
                        );
                      })}
                    </PhoneOptionCircleMemory>
                  </PhoneOption>
                </PhoneOptionWrapper>
                <PhoneOptionWrapper>
                  <PhoneOption>
                    <PhoneOptionName>기존통신사</PhoneOptionName>
                    <PhoneOptionCircleMemory>
                      <PhoneOptionCircleVers
                        tel
                        selected={datas.oldtelecom === "SKT" ? true : false}
                        onClick={() => {
                          if (datas.newtelecom !== "SKT") {
                            setDatas({
                              ...datas,
                              sim: "새 유심",
                              oldtelecom: "SKT",
                            });
                          } else {
                            setDatas({ ...datas, oldtelecom: "SKT" });
                          }
                        }}
                      >
                        SKT
                      </PhoneOptionCircleVers>
                      <PhoneOptionCircleVers
                        tel
                        selected={datas.oldtelecom === "KT" ? true : false}
                        onClick={() => {
                          if (datas.newtelecom !== "KT") {
                            setDatas({
                              ...datas,
                              sim: "새 유심",
                              oldtelecom: "KT",
                            });
                          } else {
                            setDatas({ ...datas, oldtelecom: "KT" });
                          }
                        }}
                      >
                        KT
                      </PhoneOptionCircleVers>
                      <PhoneOptionCircleVers
                        tel
                        selected={datas.oldtelecom === "LGU+" ? true : false}
                        onClick={() => {
                          if (datas.newtelecom !== "LGU+") {
                            setDatas({
                              ...datas,
                              sim: "새 유심",
                              oldtelecom: "LGU+",
                            });
                          } else {
                            setDatas({ ...datas, oldtelecom: "LGU+" });
                          }
                        }}
                      >
                        LGU+
                      </PhoneOptionCircleVers>
                      <PhoneOptionCircleVers
                        tel
                        selected={datas.oldtelecom === "알뜰폰" ? true : false}
                        onClick={() => {
                          if (datas.newtelecom !== "알뜰폰") {
                            setDatas({
                              ...datas,
                              sim: "새 유심",
                              oldtelecom: "알뜰폰",
                            });
                          } else {
                            setDatas({ ...datas, oldtelecom: "알뜰폰" });
                          }
                        }}
                      >
                        알뜰폰
                      </PhoneOptionCircleVers>
                    </PhoneOptionCircleMemory>
                  </PhoneOption>
                </PhoneOptionWrapper>
                <PhoneOptionWrapper>
                  <PhoneOption>
                    <PhoneOptionName>변경통신사</PhoneOptionName>
                    <PhoneOptionCircleMemory>
                      {ProductInfoData?.sktOn === false && (
                        <PhoneOptionCircleVers
                          tel
                          selected={datas.newtelecom === "SKT" ? true : false}
                          onClick={() => {
                            if (datas.oldtelecom !== "SKT") {
                              setDatas({
                                ...datas,
                                sim: "새 유심",
                                newtelecom: "SKT",
                              });
                              setTelId(1);
                              let ChooseIdx =
                                ProductInfoData?.telecom_product_key_product.filter(
                                  (item, idx) => {
                                    if (
                                      item.telecomname === "SKT" &&
                                      item?.telecom_product?.validate === true
                                    ) {
                                      return true;
                                    }
                                  }
                                )[0]?.id;

                              setCurrentTele(ChooseIdx);
                            } else {
                              setDatas({ ...datas, newtelecom: "SKT" });
                              setTelId(1);
                              let ChooseIdx =
                                ProductInfoData?.telecom_product_key_product.filter(
                                  (item, idx) => {
                                    if (
                                      item.telecomname === "SKT" &&
                                      item?.telecom_product?.validate === true
                                    ) {
                                      return true;
                                    }
                                  }
                                )[0]?.id;

                              setCurrentTele(ChooseIdx);
                            }
                          }}
                        >
                          SKT
                        </PhoneOptionCircleVers>
                      )}
                      {ProductInfoData?.ktOn === false && (
                        <PhoneOptionCircleVers
                          tel
                          selected={datas.newtelecom === "KT" ? true : false}
                          onClick={() => {
                            if (datas.oldtelecom !== "KT") {
                              setDatas({
                                ...datas,
                                sim: "새 유심",
                                newtelecom: "KT",
                              });
                              setTelId(2);
                              let ChooseIdx =
                                ProductInfoData?.telecom_product_key_product.filter(
                                  (item, idx) => {
                                    if (
                                      item.telecomname === "KT" &&
                                      item?.telecom_product?.validate === true
                                    ) {
                                      return true;
                                    }
                                  }
                                )[0]?.id;

                              setCurrentTele(ChooseIdx);
                            } else {
                              setDatas({ ...datas, newtelecom: "KT" });
                              setTelId(2);
                              let ChooseIdx =
                                ProductInfoData?.telecom_product_key_product.filter(
                                  (item, idx) => {
                                    if (
                                      item.telecomname === "KT" &&
                                      item?.telecom_product?.validate === true
                                    ) {
                                      return true;
                                    }
                                  }
                                )[0]?.id;

                              setCurrentTele(ChooseIdx);
                              //KT 첫요금제 걸르기 없으면 null
                            }
                          }}
                        >
                          KT
                        </PhoneOptionCircleVers>
                      )}
                      {ProductInfoData?.lguOn === false && (
                        <PhoneOptionCircleVers
                          tel
                          selected={datas.newtelecom === "LGU+" ? true : false}
                          onClick={() => {
                            if (datas.oldtelecom !== "LGU+") {
                              setDatas({
                                ...datas,
                                sim: "새 유심",
                                newtelecom: "LGU+",
                              });
                              setTelId(3);
                              let ChooseIdx =
                                ProductInfoData?.telecom_product_key_product.filter(
                                  (item, idx) => {
                                    if (
                                      item.telecomname === "LGU+" &&
                                      item?.telecom_product?.validate === true
                                    ) {
                                      return true;
                                    }
                                  }
                                )[0]?.id;

                              setCurrentTele(ChooseIdx);
                            } else {
                              setDatas({ ...datas, newtelecom: "LGU+" });
                              setTelId(3);
                              let ChooseIdx =
                                ProductInfoData?.telecom_product_key_product.filter(
                                  (item, idx) => {
                                    if (
                                      item.telecomname === "LGU+" &&
                                      item?.telecom_product?.validate === true
                                    ) {
                                      return true;
                                    }
                                  }
                                )[0]?.id;

                              setCurrentTele(ChooseIdx);
                              //LGU 첫번쨰 요금 거르기
                            }
                          }}
                        >
                          LGU+
                        </PhoneOptionCircleVers>
                      )}
                      {ProductInfoData?.newOption && (
                        <PhoneOptionCircleVers
                          tel
                          selected={
                            datas.oldtelecom === "신규가입" ? true : false
                          }
                          onClick={() => {
                            if (datas.newtelecom !== "신규가입") {
                              setDatas({
                                ...datas,
                                sim: "새 유심",
                                oldtelecom: "신규가입",
                              });
                            } else {
                              setDatas({ ...datas, oldtelecom: "신규가입" });
                            }
                          }}
                        >
                          신규
                        </PhoneOptionCircleVers>
                      )}
                    </PhoneOptionCircleMemory>
                  </PhoneOption>
                  {/* 변경 통신사 */}
                  <PhoneOption>
                    <PhoneOptionName
                      Origin
                      style={{
                        border: "none",
                        backgroundColor: "#fff",
                      }}
                      className="tableBinBox"
                    >
                      {" "}
                    </PhoneOptionName>
                    <MobileChangeBox2>
                      <MobileChangeLeftBox>
                        {datas.newtelecom === datas.oldtelecom ? (
                          `기기변경`
                        ) : (
                          <>
                            {datas?.oldtelecom === "신규가입"
                              ? "신규"
                              : "통신사변경"}{" "}
                          </>
                        )}
                      </MobileChangeLeftBox>
                      <MobileChangeRightBox>
                        {datas.newtelecom === datas.oldtelecom ? (
                          `통신사 그대로 기기만변경!`
                        ) : (
                          <>
                            {datas?.oldtelecom === "신규가입"
                              ? `${datas.newtelecom} 신규입니다!`
                              : "번호는 그대로 통신사만 변경!"}{" "}
                          </>
                        )}
                      </MobileChangeRightBox>
                    </MobileChangeBox2>
                  </PhoneOption>
                </PhoneOptionWrapper>
                <PhoneOptionWrapper TelPrice>
                  <PhoneOption>
                    <PhoneOptionName2>요금제</PhoneOptionName2>
                    <PhoneOptionRateBox
                      onClick={() => {
                        setisRatePlanModalOpen(true);
                      }}
                    >
                      <PhoneOptionRateBoxNameAndDetail>
                        <PhoneOptionRateBoxName
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {
                            ProductInfoData?.telecom_product_key_product.filter(
                              (item, idx) => item.id === currentTele
                            )[0]?.name
                          }
                          <img
                            src={Line_IC}
                            style={{
                              width: 1,
                              height: 12,
                              marginLeft: 10,
                              marginRight: 5,
                            }}
                          />
                          <span
                            style={{
                              fontSize: 14,
                              color: "#535353",
                              marginLeft: 12,
                            }}
                          >
                            월{" "}
                            <span style={{ color: "red" }}>
                              {ProductInfoData?.telecom_product_key_product
                                .filter(
                                  (item, idx) => item.id === currentTele
                                )[0]
                                ?.pricemonth.toLocaleString()}
                            </span>{" "}
                            원
                          </span>
                        </PhoneOptionRateBoxName>
                        <PhoneOptionRateBoxPrice>
                          <img
                            src={DownArrow}
                            style={{ width: 12, height: 6 }}
                          />
                        </PhoneOptionRateBoxPrice>
                      </PhoneOptionRateBoxNameAndDetail>
                      <PhoneOptionRateBoxDetail>
                        데이터 :
                        <span style={{ color: "red", marginLeft: 5 }}>
                          {
                            ProductInfoData?.telecom_product_key_product.filter(
                              (item, idx) => item.id === currentTele
                            )[0]?.datarem
                          }
                        </span>
                        <br />
                        음성 :
                        <span style={{ color: "red", marginLeft: 5 }}>
                          {
                            ProductInfoData?.telecom_product_key_product.filter(
                              (item, idx) => item.id === currentTele
                            )[0]?.callrem
                          }
                        </span>
                        <br />
                        문자 :
                        <span style={{ color: "red", marginLeft: 5 }}>
                          {
                            ProductInfoData?.telecom_product_key_product.filter(
                              (item, idx) => item.id === currentTele
                            )[0]?.smsrem
                          }
                        </span>
                      </PhoneOptionRateBoxDetail>
                    </PhoneOptionRateBox>
                  </PhoneOption>
                </PhoneOptionWrapper>
                <PhoneOptionWrapper>
                  <PhoneOption>
                    <PhoneOptionName>할인방법</PhoneOptionName>
                    <PhoneOptionDiscountWrapper>
                      <PhoneOptionDiscount
                        onClick={() =>
                          setDatas({
                            ...datas,
                            discount: "공시지원할인",
                            Sales:
                              ProductInfoData?.telecom_product_key_product.filter(
                                (item, idx) => item.id === currentTele
                              )[0]?.telecom_product.dc_sales_price,
                          })
                        }
                        style={
                          datas.discount === "공시지원할인"
                            ? {
                              backgroundColor: "#fff",
                              color: "red",
                              border: "2px solid red",
                            }
                            : {
                              backgroundColor: "#fff",
                              border: "1px solid #999",
                            }
                        }
                      >
                        <span
                          style={
                            datas.discount === "공시지원할인"
                              ? { color: " red", fontWeight: "bold" }
                              : { color: "#999", fontWeight: "normal" }
                          }
                        >
                          공시지원할인
                        </span>
                        <span
                          style={
                            datas.discount === "공시지원할인"
                              ? { color: " red", fontWeight: "bold" }
                              : { color: "#999", fontWeight: "normal" }
                          }
                        >
                          총
                          {ORigins?.DcTrans
                            ? datas.newtelecom === datas.oldtelecom
                              ? Number(
                                ProductInfoData?.telecom_product_key_product.filter(
                                  (item, idx) => item.id === currentTele
                                )[0]?.telecom_product.dc_sales_price + 0
                              ).toLocaleString()
                              : Number(
                                ProductInfoData?.telecom_product_key_product.filter(
                                  (item, idx) => item.id === currentTele
                                )[0]?.telecom_product.dc_sales_price + 0
                              ).toLocaleString()
                            : datas.newtelecom === datas.oldtelecom
                              ? Number(
                                ProductInfoData?.telecom_product_key_product.filter(
                                  (item, idx) => item.id === currentTele
                                )[0]?.telecom_product.dc_sales_price
                              ).toLocaleString()
                              : Number(
                                ProductInfoData?.telecom_product_key_product.filter(
                                  (item, idx) => item.id === currentTele
                                )[0]?.telecom_product.dc_sales_price
                              ).toLocaleString()}
                          원
                        </span>
                      </PhoneOptionDiscount>
                      <PhoneOptionDiscount
                        onClick={() =>
                          setDatas({
                            ...datas,
                            discount: "선택약정할인",
                            Sales:
                              ProductInfoData?.telecom_product_key_product.filter(
                                (item, idx) => item.id === currentTele
                              )[0]?.telecom_product.dc_chooses_price,
                          })
                        }
                        style={
                          datas.discount === "선택약정할인"
                            ? {
                              backgroundColor: "#fff",
                              color: " red",
                              border: "2px solid red",
                            }
                            : {
                              backgroundColor: "#fff",
                              border: "1px solid #999",
                            }
                        }
                      >
                        <span
                          style={
                            datas.discount === "선택약정할인"
                              ? { color: " red", fontWeight: "bold" }
                              : { color: "#999", fontWeight: "normal" }
                          }
                        >
                          선택약정할인
                        </span>
                        <span
                          style={
                            datas.discount === "선택약정할인"
                              ? { color: " red", fontWeight: "bold" }
                              : { color: "#999", fontWeight: "normal" }
                          }
                        >
                          {/* 관리자 데이터 수정되면 여기 주석 풀어여합니당 */}
                          총
                          {ORigins?.DcTrans
                            ? datas.newtelecom === datas.oldtelecom
                              ? PerCent(
                                GongYou,
                                Number(
                                  ProductInfoData?.telecom_product_key_product.filter(
                                    (item, idx) => item.id === currentTele
                                  )[0]?.telecom_product.dc_chooses_price
                                )
                              )
                                .toLocaleString()
                                .toLocaleString()
                              : PerCent(
                                GongYou,
                                Number(
                                  ProductInfoData?.telecom_product_key_product.filter(
                                    (item, idx) => item.id === currentTele
                                  )[0]?.telecom_product.dc_chooses_price
                                )
                              )
                                .toLocaleString()
                                .toLocaleString()
                            : datas.newtelecom === datas.oldtelecom
                              ? PerCent(
                                GongYou,
                                Number(
                                  ProductInfoData?.telecom_product_key_product.filter(
                                    (item, idx) => item.id === currentTele
                                  )[0]?.telecom_product.dc_chooses_price
                                )
                              ).toLocaleString()
                              : PerCent(
                                GongYou,
                                Number(
                                  ProductInfoData?.telecom_product_key_product.filter(
                                    (item, idx) => item.id === currentTele
                                  )[0]?.telecom_product.dc_chooses_price
                                )
                              ).toLocaleString()}
                          원
                        </span>
                      </PhoneOptionDiscount>
                    </PhoneOptionDiscountWrapper>
                  </PhoneOption>
                  {datas.discount === "선택약정할인" ? (
                    <PhoneOption>
                      <PhoneOptionName
                        Origin
                        style={{ border: "none", background: "#fff" }}
                        className="tableBinBox"
                      >
                        {" "}
                      </PhoneOptionName>
                      <MobileChangeBox3>
                        <MobileChangeLeftBox>
                          {Number(
                            ProductInfoData?.telecom_product_key_product.filter(
                              (item, idx) => item.id === currentTele
                            )[0]?.telecom_product.dc_chooses_price
                          ).toLocaleString()}{" "}
                          % 요금 할인
                        </MobileChangeLeftBox>
                        <MobileChangeRightBox>
                          약정 기간 동안 매월 기본료 25% 할인
                        </MobileChangeRightBox>
                      </MobileChangeBox3>
                    </PhoneOption>
                  ) : (
                    <PhoneOption>
                      <PhoneOptionName
                        Origin
                        style={{
                          border: "none",
                          backgroundColor: "#fff",
                        }}
                        className="tableBinBox"
                      >
                        {" "}
                      </PhoneOptionName>
                      <MobileChangeBox>
                        <MobileChangeLeftBox>단말기 할인</MobileChangeLeftBox>
                        <MobileChangeRightBox>
                          개통 당시 1회 단말기 구매 비용 할인
                        </MobileChangeRightBox>
                      </MobileChangeBox>
                    </PhoneOption>
                  )}
                </PhoneOptionWrapper>
                <PhoneOptionWrapper>
                  <PhoneOption>
                    <PhoneOptionName>구매방법</PhoneOptionName>
                    <PhoneOptionCircleMemory2>
                      <PhoneOptionCircle
                        일시불
                        selected={datas.checkout === "일시불" ? true : false}
                        onClick={() =>
                          setDatas({ ...datas, checkout: "일시불" })
                        }
                      >
                        일시불
                      </PhoneOptionCircle>
                      <PhoneOptionCircle
                        일시불
                        selected={datas.checkout === "24개월" ? true : false}
                        onClick={() =>
                          setDatas({ ...datas, checkout: "24개월" })
                        }
                      >
                        24개월
                      </PhoneOptionCircle>
                      <PhoneOptionCircle
                        일시불
                        selected={datas.checkout === "30개월" ? true : false}
                        onClick={() =>
                          setDatas({ ...datas, checkout: "30개월" })
                        }
                      >
                        30개월
                      </PhoneOptionCircle>
                      <PhoneOptionCircle
                        일시불
                        selected={datas.checkout === "36개월" ? true : false}
                        onClick={() =>
                          setDatas({ ...datas, checkout: "36개월" })
                        }
                      >
                        36개월
                      </PhoneOptionCircle>
                    </PhoneOptionCircleMemory2>
                  </PhoneOption>
                </PhoneOptionWrapper>
                <PhoneOptionWrapper>
                  <PhoneOption>
                    <PhoneOptionName>유심선택</PhoneOptionName>
                    <PhoneOptionCircleMemory2>
                      <PhoneOptionCircle2
                        유심
                        selected={datas.sim === "새 유심" ? true : false}
                        onClick={() => {
                          setDatas({ ...datas, sim: "새 유심" });
                        }}
                      >
                        새 유심
                      </PhoneOptionCircle2>

                      <PhoneOptionCircle2
                        유심
                        selected={datas.sim === "기존 유심" ? true : false}
                        onClick={() => {
                          if (datas.newtelecom !== datas.oldtelecom) {
                            return;
                          }
                          setDatas({ ...datas, sim: "기존 유심" });
                        }}
                      >
                        기존 유심
                      </PhoneOptionCircle2>
                    </PhoneOptionCircleMemory2>
                  </PhoneOption>
                  {datas.sim === "새 유심" ? (
                    <AttentionMessage>
                      * 새 유심 구매 시 첫 달은{" "}
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          marginLeft: 3,
                        }}
                      >
                        {" "}
                        7,700원
                      </span>
                      이 청구 됩니다.
                    </AttentionMessage>
                  ) : (
                    <></>
                  )}
                </PhoneOptionWrapper>

                {/*   <PhoneOptionWrapper>
                  <PhoneOption2>
                    <PhoneOptionName>복지할인</PhoneOptionName>
                    <div
                      className="firstdiv"
                      style={{
                        border: "1px solid #e8e8e8",
                        borderRadius: 8,
                        position: "relative",
                      }}
                    >
                      <PhoneOptionNotSelect
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setBene(!bene);
                        }}
                      >
                        <div>{bene ? "접어두기" : "복지혜택보기"}</div>
                        <div>
                          <img
                            src={DownArrow}
                            style={{ width: 12, height: 6 }}
                          />
                        </div>
                      </PhoneOptionNotSelect>
                      {bene && (
                        <table
                          id="customers1"
                          style={{
                            width: "100%",
                            borderRadius: "8px",
                            position: "absolute",
                            background: "#fff",
                            border: "1px solid #ccc",
                            overflow: "hidden",
                          }}
                        >
                          <thead style={{ height: 35 }}>
                            <tr>
                              <td
                                style={{
                                  backgroundColor: "#666",
                                  color: "#fff",
                                  fontWeight: "bold",
                                }}
                              >
                                복지할인
                              </td>
                              <td
                                style={{
                                  backgroundColor: "#666",
                                  textAlign: "right",
                                  color: "#fff",
                                  fontWeight: "bold",
                                }}
                              >
                                설명
                              </td>
                            </tr>
                          </thead>
                          <tbody style={{ padding: "10px 0px" }}>
                            <tr style={{ height: 33 }}>
                              <PhoneOrnerOption style={{ color: "#444" }}>
                                미적용
                              </PhoneOrnerOption>
                              <PhoneOrnerOption
                                style={{ textAlign: "right", color: "#333" }}
                              >
                                복지 할인
                              </PhoneOrnerOption>
                            </tr>
                            <tr style={{ height: 33 }}>
                              <PhoneOrnerOption style={{ color: "#444" }}>
                                장애등급
                              </PhoneOrnerOption>
                              <PhoneOrnerOption
                                style={{ textAlign: "right", color: "#333" }}
                              >
                                기본료 35% 할인
                              </PhoneOrnerOption>
                            </tr>
                            <tr style={{ height: 33 }}>
                              <PhoneOrnerOption style={{ color: "#444" }}>
                                국가 유공자
                              </PhoneOrnerOption>
                              <PhoneOrnerOption
                                style={{ textAlign: "right", color: "#333" }}
                              >
                                기본료 35% 할인
                              </PhoneOrnerOption>
                            </tr>
                            <tr style={{ height: 33 }}>
                              <PhoneOrnerOption style={{ color: "#444" }}>
                                차상위계층
                              </PhoneOrnerOption>
                              <PhoneOrnerOption
                                style={{ textAlign: "right", color: "#333" }}
                              >
                                최대 23,650원 할인
                              </PhoneOrnerOption>
                            </tr>
                            <tr style={{ height: 33 }}>
                              <PhoneOrnerOption style={{ color: "#444" }}>
                                기초생활수급자(생계/의료)
                              </PhoneOrnerOption>
                              <PhoneOrnerOption
                                style={{ textAlign: "right", color: "#333" }}
                              >
                                최대 28,600원 할인
                              </PhoneOrnerOption>
                            </tr>
                            <tr style={{ height: 33 }}>
                              <PhoneOrnerOption style={{ color: "#444" }}>
                                기초생활수급자(주거/교육)
                              </PhoneOrnerOption>
                              <PhoneOrnerOption
                                style={{ textAlign: "right", color: "#333" }}
                              >
                                최대 23,650원 할인
                              </PhoneOrnerOption>
                            </tr>
                            <tr style={{ height: 33 }}>
                              <PhoneOrnerOption style={{ color: "#444" }}>
                                기초생활수급자(기초연금 수급자)
                              </PhoneOrnerOption>
                              <PhoneOrnerOption
                                style={{ textAlign: "right", color: "#333" }}
                              >
                                최대 12,100원 할인
                              </PhoneOrnerOption>
                            </tr>
                            <tr>
                              <td
                                colSpan={2}
                                style={{
                                  height: 33,
                                  backgroundColor: "#666",
                                  borderBottomLeftRadius: 8,
                                  borderBottomRightRadius: 8,
                                }}
                              >
                                <span
                                  style={{
                                    color: "#fff",
                                    fontSize: 12,
                                    letterSpacing: 0.5,
                                  }}
                                >
                                  휴대폰으로 114 &gt; 고객센터 문의 &gt; 통신사
                                  고객센터 문의
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </div>
                  </PhoneOption2>
                </PhoneOptionWrapper> */}
                <PhoneOptionWrapper>
                  <PhoneOptionName>제휴카드</PhoneOptionName>
                  {cardSelected === 0 ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <PhoneOptionSelect
                          onClick={() => {
                            setisCardModalOpen(true);
                          }}
                          selected={isCardModalOpen === true ? true : false}
                          style={{ width: "48.5%" }}
                        >
                          선택
                        </PhoneOptionSelect>
                        <PhoneOptionSelect
                          onClick={() => {
                            setisCardModalOpen(false);
                          }}
                          selected={isCardModalOpen === true ? false : true}
                          style={{ width: "48.5%" }}
                        >
                          선택안함
                        </PhoneOptionSelect>
                      </div>
                    </>
                  ) : (
                    <div
                      style={{
                        border: "2px solid #f0f0f0",
                        borderRadius: 10,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginBottom: 10,
                        paddingTop: 5,
                      }}
                    >
                      <div
                        style={{
                          width: "86%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          marginBottom: 15,
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              fontSize: 17,
                              fontWeight: "600",
                              marginTop: 10,
                              marginBottom: 10,
                            }}
                          >
                            {cardSelected?.name}
                          </div>
                          <div
                            style={{
                              padding: "5px 14px",
                              borderRadius: 10,
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#fff",
                              backgroundColor: "rgb(244,18,92)",
                              cursor: "pointer",
                            }}
                            onClick={() => setisCardModalOpen(true)}
                          >
                            수정
                          </div>
                        </div>
                      </div>
                      <img
                        src={cardSelected?.images[0]}
                        style={{
                          width: "100%",
                          height: 250,
                          backgroundColor: "#fff",
                          resize: "both",
                          objectFit: "contain",
                          borderRadius: 7,
                        }}
                      />
                      <div
                        style={{
                          width: "86%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          marginBottom: 15,
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              fontSize: 14,
                              fontWeight: "500",
                              color: "#535353",
                              marginTop: 24,
                            }}
                          >
                            전월실적
                            {cardSelected?.last
                              ?.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                            이상
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            alignSelf: "flex-start",
                            marginTop: 5,
                          }}
                        >
                          <div
                            style={{
                              fontSize: 14,
                              fontWeight: "600",
                              width: "72%",
                            }}
                          >
                            매월{" "}
                            {cardSelected?.discount
                              ?.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                            x 24개월 = 총
                          </div>
                          <CardDiscount_View
                            style={{ marginLeft: 3, width: "10%" }}
                          >
                            {cardSelected?.monthlyDiscount
                              ?.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </CardDiscount_View>
                        </div>
                      </div>
                    </div>
                  )}
                  {cardSelected !== 0 && (
                    <PhoneOptionSelect
                      selected={true}
                      onClick={() => setCardSelected(0)}
                    >
                      선택안함
                    </PhoneOptionSelect>
                  )}
                </PhoneOptionWrapper>
              </PhoneOptionAll>
              <PhoneSummaryWrapper style={{ marginTop: 40 }}>
                <PhoneSummary>
                  <SummaryLogo
                    src={ORigins?.image?.length > 0 && ORigins?.image[0]}
                    style={{ display: "none" }}
                  />
                  {/* <PhoneBorderBox>
                    <PhoneBorderLeft>
                      {datas.checkout === "일시불" ? "현금완납" : "할부원금"}
                    </PhoneBorderLeft>
                    <PhoneBorderRight>
                      <>
                        {datas?.discount === "공시지원할인" ? (
                          <>
                            {CHECKOUT_PRICE > 0
                              ? CHECKOUT_PRICE.toString().replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )
                              : 0}
                          </>
                        ) : (
                          <>
                            {CardDiscountPrice < 0
                              ? 0
                              : CardDiscountPrice.toString().replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )}
                          </>
                        )}
                      </>
                      <span style={{ fontWeight: "normal" }}> 원</span>
                    </PhoneBorderRight>
                  </PhoneBorderBox> */}

                  <PhoneSummaryKeyValue
                    가격정보
                    style={{
                      marginBottom: 5,
                      border: "1px solid #111",
                      borderRadius: 7,
                      backgroundColor: "#fff",
                      minWidth: 275,
                    }}
                  >
                    <PhoneSummaryKey
                      가격정보
                      style={{
                        paddingLeft: 0,
                        fontWeight: "bold",
                        width: 100,
                      }}
                    >
                      가격정보
                    </PhoneSummaryKey>
                    <PhoneSummaryValue 가격정보>
                      {ProductInfoData?.name}
                    </PhoneSummaryValue>
                  </PhoneSummaryKeyValue>

                  <div
                    style={{
                      border: "1px solid #333",
                      marginTop: 4,
                      minWidth: 275,
                      borderRadius: 7,
                      padding: "4px 15px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <PhoneSummaryKeyValue>
                      <PhoneSummaryKey>출고가</PhoneSummaryKey>
                      <PhoneSummaryValue>
                        {PRODUCT_PRICE?.toString().replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )}
                        원
                      </PhoneSummaryValue>
                    </PhoneSummaryKeyValue>

                    <PhoneSummaryKeyValue>
                      <PhoneSummaryKey>
                        {ORigins !== undefined && ORigins.pagename} D.C

                      </PhoneSummaryKey>
                      <PhoneSummaryValue summary>
                        <div style={{ display: "none" }}>
                          {StoredtIds?.dc?.toLocaleString()}원
                        </div>
                        {DcPrice() === 0
                          ? 0
                          : (DcPrice() * -1).toLocaleString()}
                        원
                      </PhoneSummaryValue>
                    </PhoneSummaryKeyValue>
                    {datas?.oldtelecom === "신규가입" && (
                      <PhoneSummaryKeyValue>
                        <PhoneSummaryKey>
                          {ORigins !== undefined && ORigins.pagename}{" "}
                          신규가입 할인
                        </PhoneSummaryKey>
                        <PhoneSummaryValue summary>
                          {(DC_NEWOPTION * -1).toLocaleString()}원
                        </PhoneSummaryValue>
                      </PhoneSummaryKeyValue>
                    )}
                    <PhoneSummaryKeyValue>
                      <PhoneSummaryKey>
                        {ORigins !== undefined && ORigins.pagename} 특판
                        할인
                      </PhoneSummaryKey>
                      <PhoneSummaryValue summary>
                        <div style={{ display: "none" }}></div>
                        {/*     - {DcPrice()?.toLocaleString()}원 */}-
                        {TongPrice?.toLocaleString()}원
                      </PhoneSummaryValue>
                    </PhoneSummaryKeyValue>

                    <PhoneSummaryKeyValue>
                      <PhoneSummaryKey>
                        전환지원금


                      </PhoneSummaryKey>
                      <PhoneSummaryValue summary>
                        -{Math.round(Number(changePrice))?.toLocaleString()}원
                      </PhoneSummaryValue>
                    </PhoneSummaryKeyValue>



                    <PhoneSummaryKeyValue>
                      <PhoneSummaryKey>
                        {datas.discount === "공시지원할인"
                          ? "공시지원할인"
                          : "선택약정할인"}

                        {datas.discount === "선택약정할인" ? (
                          //선택약정 할인일때
                          datas.checkout !== "일시불" ? (
                            // 여기서 일시불이 아니면
                            (
                              PerCent(
                                GongYou,
                                Number(
                                  ProductInfoData?.telecom_product_key_product.filter(
                                    (item, idx) => item.id === currentTele
                                  )[0]?.telecom_product.dc_chooses_price
                                )
                              ) / 24
                            ).toLocaleString() + "X 24개월"
                          ) : (
                            //일시불
                            Math.round(
                              (DC_PRICE + DC_PRICE_TYPE) / 24
                            ).toLocaleString() + " X 24개월"
                          )
                        ) : datas.checkout !== "일시불" ? (
                          //일시불 아니면
                          <></>
                        ) : (
                          //일시불
                          <></>
                        )}
                      </PhoneSummaryKey>
                      <PhoneSummaryValue summary>
                        -{Math.round(Number(DC_PRICE))?.toLocaleString()}원
                      </PhoneSummaryValue>
                    </PhoneSummaryKeyValue>
                    <PhoneSummaryKeyValue>
                      <PhoneSummaryKey>제휴카드</PhoneSummaryKey>

                      <PhoneSummaryValue style={{ color: "red" }} normal>
                        {cardSelected !== 0 ? cardSelected?.name : "선택안함"}
                      </PhoneSummaryValue>
                    </PhoneSummaryKeyValue>
                    <PhoneSummaryKeyValue>
                      <PhoneSummaryKey>제휴카드 할인</PhoneSummaryKey>
                      <PhoneSummaryValue
                        normal
                        style={{ color: cardSelected !== 0 && "#ff0000" }}
                      >
                        {cardSelected !== 0
                          ? `-${cardSelected?.monthlyDiscount
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원`
                          : "선택안함"}
                      </PhoneSummaryValue>
                    </PhoneSummaryKeyValue>
                  </div>

                  <div
                    style={{
                      border: "1px solid black",
                      margin: "8px 0 0",
                      minWidth: 275,
                      borderRadius: 8,
                      padding: "5px 0px 0px",
                      background: "#fff",
                    }}
                  >
                    <PhoneSummaryKeyValue style={{ padding: "0 16px" }}>
                      <PhoneSummaryKey>상품권</PhoneSummaryKey>
                      <PhoneSummaryValue summary>
                        {datas?.discount === "공시지원할인"
                          ? CHECKOUT_PRICE < 0
                            ? Math.abs(CHECKOUT_PRICE).toLocaleString()
                            : 0
                          : SunTackBonus.toLocaleString()}
                        원
                      </PhoneSummaryValue>
                    </PhoneSummaryKeyValue>
                    <PhoneSummaryKeyValue style={{ padding: "0 16px" }}>
                      <PhoneSummaryKey>총 할인금액</PhoneSummaryKey>
                      <PhoneSummaryValue summary>
                        {Math.round(TOTAL_DISCOUNT * -1)?.toLocaleString()}원
                      </PhoneSummaryValue>
                    </PhoneSummaryKeyValue>
                    <PhoneBorderBox>
                      <PhoneBorderLeft>
                        {datas.checkout === "일시불" ? "현금완납" : "할부원금"}
                      </PhoneBorderLeft>
                      <PhoneBorderRight>
                        <>
                          {datas?.discount === "공시지원할인" ? (
                            <>
                              {CHECKOUT_PRICE > 0
                                ? CHECKOUT_PRICE.toString().replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                                : 0}
                            </>
                          ) : (
                            <>
                              {CardDiscountPrice < 0
                                ? 0
                                : CardDiscountPrice.toString().replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                            </>
                          )}
                        </>
                        <span style={{ fontWeight: "normal" }}>원</span>
                      </PhoneBorderRight>
                    </PhoneBorderBox>
                  </div>
                  <PhoneSummaryWrappered>
                    <PhoneSummaryTotal>
                      {datas.checkout !== "일시불" ? (
                        <>
                          <PhoneSummaryTotalEach>
                            <span>월 할부금</span>
                            <span>{datas.checkout}</span>
                            <span>
                              {datas.checkout !== "일시불" ? (
                                <>
                                  {datas?.discount === "공시지원할인" ? (
                                    <>
                                      {Math.round(
                                        Number(CHECKOUT_PRICE) /
                                        Number(
                                          datas?.checkout.replace("개월", "")
                                        )
                                      ) > 0
                                        ? Math.round(
                                          Number(CHECKOUT_PRICE) /
                                          Number(
                                            datas?.checkout.replace(
                                              "개월",
                                              ""
                                            )
                                          )
                                        )
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )
                                        : 0}
                                    </>
                                  ) : (
                                    <>
                                      {Math.round(
                                        Number(
                                          CardDiscountPrice / CheckoutMonth
                                        )
                                      ) > 0
                                        ? Math.round(
                                          Number(
                                            CardDiscountPrice / CheckoutMonth
                                          )
                                        )
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )
                                        : 0}
                                    </>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                              원
                            </span>
                          </PhoneSummaryTotalEach>
                          <PhoneSummaryTotalEach
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <TagImage>+</TagImage>
                          </PhoneSummaryTotalEach>
                        </>
                      ) : (
                        <></>
                      )}

                      <PhoneSummaryTotalEach>
                        <span>요금제</span>
                        <span>
                          {
                            ProductInfoData?.telecom_product_key_product.filter(
                              (item, idx) => item.id === currentTele
                            )[0]?.name
                          }
                        </span>
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          {datas?.discount === "공시지원할인" ? (
                            <>
                              <PriceVal
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 14,
                                }}
                              >
                                {GongYou?.toLocaleString()}원
                              </PriceVal>
                            </>
                          ) : (
                            <>
                              <PriceVal
                                style={{
                                  textDecoration: "line-through",
                                  fontWeight: "bold",
                                }}
                              >
                                {Math.round(
                                  Number(
                                    ProductInfoData?.telecom_product_key_product.filter(
                                      (item, idx) => item.id === currentTele
                                    )[0]?.pricemonth
                                  )
                                ).toLocaleString() + "원"}
                              </PriceVal>
                              <PriceVal style={{ fontWeight: "bold" }}>
                                {Math.round(
                                  Number(tos - SunTackYoGumJae)
                                ).toLocaleString()}
                                원
                              </PriceVal>
                            </>
                          )}
                        </span>
                      </PhoneSummaryTotalEach>
                      <PhoneSummaryTotalEach
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <TagImage>=</TagImage>
                      </PhoneSummaryTotalEach>
                      <PhoneSummaryTotalEach>
                        <span>월 납부액</span>
                        <span>VAT, 할부이자 포함</span>
                        <span style={{ color: "red" }}>
                          {datas.checkout !== "일시불" ? (
                            <>
                              {datas?.discount === "공시지원할인" ? (
                                <>
                                  {Math.round(CHECKOUT_PRICE / CheckoutMonth) >
                                    0
                                    ? Retral
                                    : Number(tos - 0)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  원
                                </>
                              ) : (
                                <>
                                  {Math.round(
                                    Number(CardDiscountPrice / CheckoutMonth)
                                  ) > 0
                                    ? Retral2
                                    : Number(tos - SunTackYoGumJae)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  원
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {datas?.discount === "공시지원할인" ? (
                                <>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      marginTop: 5,
                                    }}
                                  >
                                    {Math.round(GongYou).toLocaleString()}원
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span style={{ fontWeight: "bold" }}>
                                    {Math.round(
                                      tos - SunTackYoGumJae
                                    ).toLocaleString()}
                                    원
                                  </span>
                                </>
                              )}
                            </>
                          )}
                        </span>
                      </PhoneSummaryTotalEach>
                    </PhoneSummaryTotal>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        marginTop: 8,
                        justifyContent: "flex-end",
                        borderBottomRightRadius: 8,
                        borderBottomLeftRadius: 8,
                        backgroundColor: "black",
                        minWidth: 275,
                      }}
                    >
                      <p
                        style={{
                          color: "#fff",
                          fontWeight: "bold",
                          fontSize: 13,
                          paddingTop: 8,
                          paddingRight: 10,
                          paddingBottom: 8,
                        }}
                      >
                        {datas?.checkout !== "일시불" ? (
                          <>
                            할부 이자 할부원금 기준{" "}
                            {ORigins?.salePrice?.toFixed(1)}%
                            {datas?.discount === "공시지원할인" ? (
                              <>
                                {Math.round(
                                  Number(CHECKOUT_PRICE) /
                                  Number(
                                    datas?.checkout.replace("개월", "")
                                  ) /
                                  Number(ORigins?.salePrice)
                                ) > 0
                                  ? Math.round(
                                    Math.round(
                                      (Math.round(
                                        CHECKOUT_PRICE *
                                        MonthPrice({ month: CheckoutMonth })
                                      ) +
                                        Number(CHECKOUT_PRICE)) *
                                      (ORigins?.salePrice / 100)
                                    ) / Number(CheckoutMonth)
                                  )
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : 0}
                              </>
                            ) : (
                              <>
                                {Math.round(
                                  Number(CardDiscountPrice / CheckoutMonth) /
                                  Number(ORigins?.salePrice)
                                ) > 0
                                  ? Math.round(
                                    Math.round(
                                      (Math.round(
                                        CardDiscountPrice *
                                        MonthPrice({ month: CheckoutMonth })
                                      ) +
                                        Number(CardDiscountPrice)) *
                                      (ORigins?.salePrice / 100)
                                    ) / Number(CheckoutMonth)
                                  )
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : 0}
                              </>
                            )}
                            원
                          </>
                        ) : (
                          <>할부 이자 할부원금 (일시불)</>
                        )}
                      </p>
                    </div>
                  </PhoneSummaryWrappered>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginTop: 16,
                      justifyContent: "flex-start",
                    }}
                  >
                    <p
                      style={{
                        color: "#444",
                        fontWeight: "bold",
                        fontSize: 12,
                        whiteSpace: 'pre',
                        lineHeight: "26px",
                      }}
                    >
                      {InfoDatas}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      minWidth: 272,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: 5,
                    }}
                  >
                    <div style={{ width: "48%", height: 55 }}>
                      <a
                        target={"_blank"}
                        href={ORigins !== undefined && ORigins.kakaoTalk}
                      >
                        <PrKaKa
                          style={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: "#f7e600",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 7,
                            color: "#000",
                            fontWeight: "600",
                            cursor: "pointer",
                          }}
                        >
                          카카오톡 상담하기
                        </PrKaKa>
                      </a>
                    </div>
                    <div style={{ width: "48%", height: 55 }}>
                      <Link
                        to={{
                          pathname: "/contract",
                          state: {
                            mainDatas: datas,
                            cardSelected: cardSelected,
                            productData: ProductInfoData,
                            PayPrice:
                              datas?.discount === "공시지원할인"
                                ? Math.round(CHECKOUT_PRICE / CheckoutMonth) > 0
                                  ? Retral
                                  : Number(tos - 0)
                                : Math.round(
                                  Number(CardDiscountPrice / CheckoutMonth)
                                ) > 0
                                  ? Retral2
                                  : Number(tos - SunTackYoGumJae),

                            allPrice:
                              datas.discount === "공시지원할인"
                                ? CHECKOUT_PRICE
                                : Number(CardDiscountPrice), // 할부원금
                            /* ChooseSales:
                                  datas.discount === "선택약정할인"
                                    ? ProductInfoData?.telecom_product_key_product.filter(
                                        (item, idx) => item.id === currentTele
                                      )[0]?.telecom_product.dc_chooses_price
                                    : ProductInfoData?.telecom_product_key_product.filter(
                                        (item, idx) => item.id === currentTele
                                      )[0]?.telecom_product.dc_sales_price, */
                            ChooseSales: DC_PRICE,
                            telCount: telCount,
                            // 요금제
                            teleComPrice:
                              ProductInfoData?.telecom_product_key_product.filter(
                                (item, idx) => item.id === currentTele
                              )[0],
                            DCprice: DcPrice(),
                            StoredtIds: StoredtIds,
                            TongPrice: TongPrice,
                            Bonus:
                              datas?.discount === "공시지원할인"
                                ? CHECKOUT_PRICE < 0
                                  ? Math.abs(CHECKOUT_PRICE)
                                  : 0
                                : SunTackBonus, // 요금제
                            YoGumZe:
                              datas?.discount === "공시지원할인"
                                ? GongYou?.toLocaleString()
                                : Math.round(Number(tos - SunTackYoGumJae)),
                            InfoDatas: InfoDatas,
                            newOption: ProductInfoData?.newOption,
                            newPrice: DC_NEWOPTION,
                            MarketPp: Beforediscount,
                            changePrice: changePrice,
                          },
                        }}
                        onClick={() => {
                          handlerTopMovement();
                        }}
                      >
                        <PrKaKa
                          style={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: "#fff",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 7,
                            color: "#222",
                            border: "1px solid #222",
                            fontWeight: "600",
                            cursor: "pointer",
                          }}
                        >
                          지금 개통하러 가기
                        </PrKaKa>
                      </Link>
                    </div>
                  </div>
                </PhoneSummary>
              </PhoneSummaryWrapper>
            </Top>
            <Things>
              <PhoneOptionName
                style={{
                  border: "none",
                  display: "block",
                  fontWeight: "bold",
                  padding: 0,
                  margin: "40px 0 -5px",
                  background: "#fff",
                  fontSize: 18,
                }}
              >
                제품정보
              </PhoneOptionName>
              <div
                style={{
                  border: "1px solid #CCCCCC",
                  borderRadius: 10,
                  minWidth: 275,
                  marginBottom: 45,
                }}
              >
                <div
                  style={{ marginBottom: 25, padding: 8 }}
                  dangerouslySetInnerHTML={{
                    __html: ProductInfoData?.ProductBoard?.contents,
                  }}
                ></div>
              </div>
              {/*
              <PhoneOptionName>고객 후기</PhoneOptionName> */}
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  flexWrap: "wrap",
                  display: "none",
                }}
              >
                {LiviewData !== undefined &&
                  LiviewData?.rows?.length !== 0 &&
                  LiviewData?.rows?.map((el, idx) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        {profile?.gm === 2 && (
                          <PhoneOptionSelect
                            onClick={() => {
                              _handleDeleteReview(el.id);
                            }}
                            style={{ width: "22%", marginBottom: 12 }}
                          >
                            <p>삭제</p>
                          </PhoneOptionSelect>
                        )}
                        <ReviewBox
                          onClick={() => {
                            setIsReviewModalOpen(true);
                            setSelectedReview(el);
                          }}
                          style={{
                            position: "relative",
                            zIndex: 1,
                            cursor: "pointer",
                          }}
                        >
                          {el?.images?.length !== 0 && (
                            <img
                              src={el?.images[0]}
                              style={{
                                width: "100%",
                                height: "100%",
                                resize: "both",
                                objectFit: "cover",
                                borderRadius: 9,
                              }}
                            />
                          )}
                          <div
                            style={{
                              width: "100%",
                              height: "30%",
                              position: "absolute",
                              zIndex: 2,
                              backgroundColor: "transparent",
                              bottom: 0,
                              borderBottomLeftRadius: 7,
                              borderBottomRightRadius: 7,
                              padding: 15,
                              backgroundColor: "rgba(0,0,0,0.5)",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 17,
                                fontWeight: "600",
                                color: "#fff",
                              }}
                            >
                              {el?.title}
                            </div>
                          </div>
                        </ReviewBox>
                      </div>
                    );
                  })}
              </div>
              {LiviewData !== undefined &&
                LiviewData?.rows?.length !== 0 &&
                LiviewData?.rows?.map((el, idx) => {
                  return (
                    <ReviceColumn>
                      <ReviceColumnIn>
                        <>
                          <ReviceColumnLeftImg
                            onClick={() => {
                              setIsReviewModalOpen(true);

                              if (el !== null) {
                                let newDt = LiviewData?.rows?.filter(
                                  (item, idx) => {
                                    return item.id !== el?.id;
                                  }
                                );
                                const reps = LiviewData?.rows?.filter(
                                  (item, idx) => {
                                    return item.id === el?.id;
                                  }
                                );
                                newDt.unshift(reps[0]);
                                setLiviewsData(newDt);
                              }
                            }}
                            src={
                              el.contents?.match(
                                "<img[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>"
                              ) !== null
                                ? String(
                                  el.contents?.match(
                                    "<img[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>"
                                  )[1]
                                )
                                : R_ViewICO
                            }
                          ></ReviceColumnLeftImg>
                        </>

                        <ReviewRihgtBox>
                          <ReviewRightBox_Title>
                            <span className="device">{el?.Product?.name}</span>
                            <ReviewRightBox_Star>
                              <ReactStars
                                count={5}
                                edit={false}
                                value={el.star}
                                size={2}
                                color2={"red"}
                              />
                            </ReviewRightBox_Star>
                            <ReviewRightBox_Point>
                              {el?.star}점
                            </ReviewRightBox_Point>
                            {profile?.gm >= 1 && (
                              <RawRightClick
                                onClick={() => {
                                  _handleDeleteReview(el?.id);
                                  LiviewMutate();
                                }}
                              >
                                삭제하기
                              </RawRightClick>
                            )}
                          </ReviewRightBox_Title>
                          <ReviewRightBox_Content>
                            {el?.contents.replace(/(<([^>]+)>)/gi, "").length >
                              70
                              ? el?.contents
                                .replace(/(<([^>]+)>)/gi, "")
                                .substr(0, 70) + "..."
                              : el?.contents.replace(/(<([^>]+)>)/gi, "")}
                          </ReviewRightBox_Content>
                        </ReviewRihgtBox>
                      </ReviceColumnIn>
                    </ReviceColumn>
                  );
                })} */}
              {/* <div style={{ display: "flex", justifyContent: "center" }}>
                <Pagination
                  currentPage={currentPage}
                  itemsPerPage={10}
                  onPageChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                    setOffset((pageNumber - 1) * 10);
                  }}
                  withProgressBar={true}
                  totalItems={LiviewData?.count}
                  pageNeighbours={4}
                />
              </div> */}
              <FaqRender qadata={QaListData?.rows} />
            </Things>
          </Inner>
        </Wrapper>
      </Fade>
      <BelowBar>
        <BelowBarInner>
          {/* 여기 */}
          <div
            style={{
              width: "80%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: 12,
                }}
              >
                <BottomSmLabel>
                  {datas.checkout === "일시불" ? "현금완납" : "월 할부금"}
                </BottomSmLabel>
                <BottomSmPrice>
                  {datas.checkout !== "일시불" ? (
                    <>
                      {datas?.discount === "공시지원할인" ? (
                        <>
                          {Math.round(
                            Number(CHECKOUT_PRICE) /
                            Number(
                              datas?.checkout.replace("개월", "")
                            )
                          ) > 0
                            ? Math.round(
                              Number(CHECKOUT_PRICE) /
                              Number(
                                datas?.checkout.replace(
                                  "개월",
                                  ""
                                )
                              )
                            )
                              .toString()
                              .replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )
                            : 0}
                        </>
                      ) : (
                        <>
                          {Math.round(
                            Number(
                              CardDiscountPrice / CheckoutMonth
                            )
                          ) > 0
                            ? Math.round(
                              Number(
                                CardDiscountPrice / CheckoutMonth
                              )
                            )
                              .toString()
                              .replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )
                            : 0}
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </BottomSmPrice>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <BottomSmLabel style={{ marginLeft: -4 }}>+</BottomSmLabel>
                <BottomSmLabel>월 통신료</BottomSmLabel>
                <BottomSmPrice>
                  {datas?.discount === "공시지원할인" ? (
                    <>
                      {" "}
                      {ProductInfoData?.telecom_product_key_product
                        .filter((item, idx) => item.id === currentTele)[0]
                        ?.pricemonth.toLocaleString()}
                      원
                    </>
                  ) : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {Math.round(
                        Number(tos - SunTackYoGumJae)
                      ).toLocaleString()}
                      원
                    </div>
                  )}
                </BottomSmPrice>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <BottomLgLabel>월 납부액</BottomLgLabel>
              <BottomLgPrice style={{ color: "yellow" }}>
                {datas.checkout !== "일시불" ? (
                  <>
                    {datas?.discount === "공시지원할인" ? (
                      <>
                        {Math.round(CHECKOUT_PRICE / CheckoutMonth) > 0
                          ? Retral
                          : Number(tos - 0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        원
                      </>
                    ) : (
                      <>
                        {Math.round(Number(CardDiscountPrice / CheckoutMonth)) >
                          0
                          ? Retral2.toString().replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          )
                          : Number(tos - SunTackYoGumJae)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        원
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {datas?.discount === "공시지원할인" ? (
                      <>
                        <span
                          style={{
                            fontWeight: "bold",
                            marginTop: 5,
                          }}
                        >
                          {Math.round(GongYou).toLocaleString()}원
                        </span>
                      </>
                    ) : (
                      <>
                        <span style={{ fontWeight: "bold" }}>
                          {Math.round(tos - SunTackYoGumJae).toLocaleString()}원
                        </span>
                      </>
                    )}
                  </>
                )}
              </BottomLgPrice>
              <BottomSmLabel style={{ marginLeft: 5 }}>
                {datas?.checkout !== "일시불" ? (
                  <>
                    (할부이자{" "}
                    {datas?.discount === "공시지원할인" ? (
                      <>
                        {Math.round(
                          Number(CHECKOUT_PRICE / CheckoutMonth) /
                          ORigins?.salePrice
                        ) > 0
                          ? Math.round(
                            Math.round(
                              (Math.round(
                                CHECKOUT_PRICE *
                                MonthPrice({ month: CheckoutMonth })
                              ) +
                                Number(CHECKOUT_PRICE)) *
                              (ORigins?.salePrice / 100)
                            ) / Number(CheckoutMonth)
                          )
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : 0}
                      </>
                    ) : (
                      <>
                        {Math.round(
                          Number(CardDiscountPrice / CheckoutMonth) /
                          ORigins?.salePrice
                        ) > 0
                          ? Math.round(
                            Math.round(
                              (Math.round(
                                CardDiscountPrice *
                                MonthPrice({ month: CheckoutMonth })
                              ) +
                                Number(CardDiscountPrice)) *
                              (ORigins?.salePrice / 100)
                            ) / Number(CheckoutMonth)
                          )
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : 0}
                      </>
                    )}
                    원)
                  </>
                ) : (
                  <>(할부 이자 일시불)</>
                )}
              </BottomSmLabel>
            </div>
          </div>

          <BelowBarBtn>
            <Link
              to={{
                pathname: "/contract",
                state: {
                  mainDatas: datas,
                  cardSelected: cardSelected,
                  productData: ProductInfoData,
                  PayPrice:
                    datas?.discount === "공시지원할인"
                      ? Math.round(CHECKOUT_PRICE / CheckoutMonth) > 0
                        ? Retral
                        : Number(tos - 0)
                      : Math.round(Number(CardDiscountPrice / CheckoutMonth)) >
                        0
                        ? Retral2
                        : Number(tos - SunTackYoGumJae),

                  allPrice:
                    datas.discount === "공시지원할인"
                      ? CHECKOUT_PRICE
                      : Number(CardDiscountPrice), // 할부원금
                  /* ChooseSales:
                      datas.discount === "선택약정할인"
                        ? ProductInfoData?.telecom_product_key_product.filter(
                            (item, idx) => item.id === currentTele
                          )[0]?.telecom_product.dc_chooses_price
                        : ProductInfoData?.telecom_product_key_product.filter(
                            (item, idx) => item.id === currentTele
                          )[0]?.telecom_product.dc_sales_price, */
                  ChooseSales: DC_PRICE,
                  telCount: telCount,
                  // 요금제
                  teleComPrice:
                    ProductInfoData?.telecom_product_key_product.filter(
                      (item, idx) => item.id === currentTele
                    )[0],
                  DCprice: DcPrice(),
                  StoredtIds: StoredtIds,
                  TongPrice: TongPrice,
                  Bonus:
                    datas?.discount === "공시지원할인"
                      ? CHECKOUT_PRICE < 0
                        ? Math.abs(CHECKOUT_PRICE)
                        : 0
                      : SunTackBonus, // 요금제
                  YoGumZe:
                    datas?.discount === "공시지원할인"
                      ? GongYou?.toLocaleString()
                      : Math.round(Number(tos - SunTackYoGumJae)),
                  InfoDatas: InfoDatas,
                  newOption: ProductInfoData?.newOption,
                  newPrice: DC_NEWOPTION,
                  MarketPp: Beforediscount,
                  changePrice: changePrice,
                },
              }}
              onClick={() => {
                handlerTopMovement();
              }}
            >
              개통하기
            </Link>
          </BelowBarBtn>
        </BelowBarInner>
      </BelowBar>
    </>
  );
};

export default Product;
