import React from "react";
const { uploadImage } = require("Datas/api");

const GetUri = async ({ image }) => {
  const ids = await Promise.all(
    image.map((uri) => {
      if (!!uri.id) return uri.id;
      let formData = new FormData();

      formData.append("files", uri);
      return uploadImage(formData);
    })
  );
  return { url: ids };
};


const GetUrimini = async ({ image }) => {
  const ids = await Promise.all(
    image.map((uri) => {
      if (!!uri.id) return uri.id;
      let formData = new FormData();

      formData.append("files", uri);
      return uploadImage(formData);
    })
  );
  return { miniurl: ids };
};

const ImageHandler = ({ imageBase64, setImgBase64, image, setImage }) => {
  const handleImage = (e) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      const base64 = reader.result;
      if (base64) {
        setImgBase64([base64.toString()]);
      }
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      setImage([e.target.files[0]]); //경로탈착
    }
  };



 


  const handleRemoveImage = (idx) => {
    setImage((images) => images.filter((el, index) => index !== idx));
    setImgBase64((imgBase64) => imgBase64.filter((el, index) => index !== idx));
  };

  return {
    handleRemove: handleRemoveImage,
    handleCreate: handleImage,
  };
};



const ImageHandlermini = ({ imageBase64, setImgBase64mini, image, setImagemini }) => {
  const handleImagemini = (e) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      const base64 = reader.result;
      if (base64) {
        setImgBase64mini([base64.toString()]);
      }
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      setImagemini([e.target.files[0]]); //경로탈착
    }
  };



 


  const handleRemoveImagemini = (idx) => {
    setImagemini((images) => images.filter((el, index) => index !== idx));
    setImgBase64mini((imgBase64) => imgBase64.filter((el, index) => index !== idx));
  };

  return {
    handleRemovemini: handleRemoveImagemini,
    handleCreatemini: handleImagemini,
  };
};



export { ImageHandler, GetUri,ImageHandlermini,GetUrimini};
