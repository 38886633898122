import useSWR from "swr";

const GetBannerList = (offset) => {
  const { data, error, mutate } = useSWR(
    () => `banner/userBannerList/${offset}`
  );

  return {
    BannerListData: data,
    BannerListMutate: mutate,
    BannerListError: !data && !mutate,
    isLoading: !data && !error,
  };
};

export const GetBannerListNone = (offset) => {
  const { data, error, mutate } = useSWR(
    () => `banner/userBannerListNone/${offset}`
  );

  return {
    BannerListNData: data,
    BannerListNMutate: mutate,
    BannerListNError: !data && !mutate,
    isNLoading: !data && !error,
  };
};

// userBannerListNone 
const GetStoreList = (offset, search) => {
  const { data, error, mutate } = useSWR(
    () => `user/storeList/${offset}/${search}`
  );

  return {
    StoreListData: data,
    StoreListMutate: mutate,
    StoreListError: !data && !mutate,
    isLoading: !data && !error,
  };
};

const GetStoreListTele = () => {
  const { data, error, mutate } = useSWR(() => `user/storeListTele`);

  return {
    StoreListTeleData: data,
    StoreListTeleMutate: mutate,
    StoreListTeleError: !data && !mutate,
    isLoading: !data && !error,
  };
};

//getOrderList
export const GetOrderListMain = () => {
  const { data, error, mutate } = useSWR(() => `user/getOrderList`);

  return {
    OrdereList: data?.result,
    OrderMutate: mutate,
    OrderError: !data && !mutate,
    isLoading: !data && !error,
  };
};

const GetStoredL = ({ storeid }) => {
  const { data, error, mutate } = useSWR(() => `user/DLList/${storeid}`);

  return {
    StoredLList: data,
    StoredLMutate: mutate,
    StoredLError: !data && !mutate,
    isLoading: !data && !error,
  };
};
const GetPrTelecom = (name, offset) => {
  const { data, error, mutate } = useSWR(
    () => `category/prTelecomList/${name}/${offset}`
  );

  return {
    PrTelecomData: data,
    PrTelecomMutate: mutate,
    PrTelecomError: !data && !mutate,
    isLoading: !data && !error,
  };
};
const GetTelecomList = () => {
  const { data, error, mutate } = useSWR(() => "category/telecomList");
  return {
    TelecomListData: data,
    TelecomListMutate: mutate,
    TelecomListError: !data && !mutate,
    isLoading: !data && !error,
  };
};

const GetTelecomCategoryList = (id, offset) => {
  const { data, error, mutate } = useSWR(
    () => `category/typeTeleComCategryList/${id}/${offset}`
  );
  return {
    TelecomCategoryData: data,
    TelecomCategoryMutate: mutate,
    TelecomCategoryError: !data && !mutate,
    isLoading: !data && !error,
  };
};

const GetuserProfile = (id) => {
  const { data, error, mutate } = useSWR(() => `user/userProfile/${id}`);

  return {
    UserProfileData: data,
    UserProfileMutate: mutate,
    UserProfileError: !data && !mutate,
    isLoading: !data && !error,
  };
};

const GetAdminData = () => {
  const { data, error, mutate } = useSWR(() => `user/AdminCheck`);

  return {
    AdminsData: data,
    AdminDataMutate: mutate,
    AdminDataError: !data && !mutate,
    isLoading: !data && !error,
  };
};


//storeNInfo




export const GetStoreNInfo = (id) => {
  const { data, error, mutate } = useSWR(() => `user/storeNInfo/${id}`);

  return {
    StoreNInfoData: data,
    StoreNInfoMutate: mutate,
    StoreNInfoError: !data && !mutate,
    isLoading: !data && !error,
  };
};

const GetStoreInfo = (id) => {
  const { data, error, mutate } = useSWR(() => `user/storeInfo/${id}`);

  return {
    StoreInfoData: data,
    StoreInfoMutate: mutate,
    StoreInfoError: !data && !mutate,
    isLoading: !data && !error,
  };
};
export const GetStoredetail = (id) => {
  const { data, error, mutate } = useSWR(() => `user/storedetail/${id}`);

  return {
    StoredetailData: data,
    StoredetailMutate: mutate,
    StoredetailError: !data && !mutate,
    isLoading: !data && !error,
  };
};
const GetPromotionDatas = () => {
  const { data, error, mutate } = useSWR(() => `category/getPromotionList`);

  return {
    PromotionDatas: data,
    PromotionMutate: mutate,
    PrmotionError: !data && !mutate,
    isLoading: !data && !error,
  };
};
const GetCategoryDatas = () => {
  const { data, error, mutate } = useSWR(() => `category/getCategoryList`);

  return {
    CategoryDatas: data,
    CategoryMutate: mutate,
    CategoryError: !data && !mutate,
    isLoading: !data && !error,
  };
};
//getPromotionList

export const GetCardListN = (telecomname, offset) => {
  const { data, error, mutate } = useSWR(
    () => `product/cardListN/${telecomname}/${offset}`
  );

  return {
    CardListNData: data,
    CardListNMutate: mutate,
    CardListNError: !data && !mutate,
    isLoading: !data && !error,
  };
};

//cardListN 
const GetCardList = (telecomname, offset) => {
  const { data, error, mutate } = useSWR(
    () => `product/cardList/${telecomname}/${offset}`
  );

  return {
    CardListData: data,
    CardListMutate: mutate,
    CardListError: !data && !mutate,
    isLoading: !data && !error,
  };
};

const GetuserList = (
  storeid,
  access,
  offset,
  filter,
  search,
  DLMode,
  Dlier,
  dlSelect
) => {
  const { data, error, mutate } = useSWR(
    () =>
      `user/userList/${storeid}/${access}/${offset}/${filter}/${search}/${DLMode}/${Dlier}/${dlSelect}`
  );

  return {
    userListData: data,
    userListMutate: mutate,
    userListError: !data && !mutate,
  };
};

const GetproductList = (
  type,
  promotionid,
  categoryid,
  offset,
  filter,
  search
) => {
  const { data, error, mutate } = useSWR(
    () =>
      `product/productList/${type}/${promotionid}/${categoryid}/${offset}/${filter}/${search === "" ? "null" : search
      }`
  );
  return {
    ProductListData: data,
    ProductListMutate: mutate,
    ProductListError: !data && !mutate,
    Error: error,
  };
};
export const GetproductList_ver2 = (
  type,
  promotionid,
  categoryid,
  offset,
  filter,
  search,
  storeid
) => {
  const { data, error, mutate } = useSWR(
    () =>
      `product/productList_ver2/${type}/${promotionid}/${categoryid}/${offset}/${filter}/${search === "" ? "null" : search
      }/${storeid}`
  );
  return {
    ProductListData: data,
    ProductListMutate: mutate,
    ProductListError: !data && !mutate,
    Error: error,
  };
};

const GetProductAdminList = (
  type,
  promotionid,
  categoryid,
  offset,
  filter,
  search
) => {
  const { data, error, mutate } = useSWR(
    () =>
      `product/getProductAdminList/${type}/${promotionid}/${categoryid}/${offset}/${filter}/${search === "" ? "null" : search
      }`
  );
  return {
    ProductListData: data,
    ProductListMutate: mutate,
    ProductListError: !data && !mutate,
  };
};

//listCategoryNone

export const GetCategoryListNone = (offset) => {
  const { data, error, mutate } = useSWR(() => `category/listCategoryNone`);

  return {
    CategoryNListData: data,
    CategorynListMutate: mutate,
    CategoryNListError: !data && !mutate,
    isLoading: !data && !error,
  };
};
const GetCategoryList = (offset) => {
  const { data, error, mutate } = useSWR(() => `category/listCategory`);

  return {
    CategoryListData: data,
    CategoryListMutate: mutate,
    CategoryListError: !data && !mutate,
    isLoading: !data && !error,
  };
};

const GetGmedInfo = (offset) => {
  const { data, error, mutate } = useSWR(() => `product/gmedInfo`);

  return {
    GmedInfoData: data,
    GmedInfoMutate: mutate,
    GmedInfoError: !data && !mutate,
    isLoading: !data && !error,
  };
};

//gmedInfo
const GetPlanList = (telecomname) => {
  const { data, error, mutate } = useSWR(
    () => `product/planList/${telecomname}`
  );

  return {
    PlanListData: data,
    PlanListMutate: mutate,
    PlanListError: !data && !mutate,
    isLoading: !data && !error,
  };
};
const GetPlanListCate = (id, offset) => {
  const { data, error, mutate } = useSWR(
    () => `product/planListCate/${id}/${offset}`
  );

  return {
    PlanListCateData: data,
    PlanListCateMutate: mutate,
    PlanListCateError: !data && !mutate,
    isLoading: !data && !error,
  };
};

const GetUserOrderList = (id) => {
  const { data, error, mutate } = useSWR(() => `user/userOrderList/${id}`);

  return {
    GetUserOrderListData: data,
    GetUserOrderListMutate: mutate,
    GetUserOrderListError: !data && !mutate,
    isLoading: !data && !error,
  };
};


export const GetAuthData = () => {
  const { data, error, mutate } = useSWR(
    () => `user/getAuthData`
  );

  return {
    AuthData: data?.result,
    AuthDataMutate: mutate,
    AuthDataError: !data && !mutate,
    isLoading: !data && !error,
  };
};



const GetUsageCate = (id, telId) => {
  const { data, error, mutate } = useSWR(
    () => `category/typeTelecomCateTelList/${id}/${telId}`
  );

  return {
    GetUsageCateData: data,
    GetUsageCateDataMutate: mutate,
    GetUsageCateDataError: !data && !mutate,
    isLoading: !data && !error,
  };
};
//getProductGroup
export const GetProductGroup = () => {
  const { data, error, mutate } = useSWR(() => `category/getProductGroup`);

  return {
    ProductGroupDatas: data,
    ProductGroupMutates: mutate,
    ProductGrouptErrors: !data && !mutate,
    isLoadings: !data && !error,
  };
};
const GettypeTelecomListDatas = (type, offset, limit) => {
  const { data, error, mutate } = useSWR(
    () =>
      `category/typeTelecomListDatas/${type}/${offset}/${limit ? limit : null}`
  );

  return {
    typeTelecomListDatas: data,
    typeTelecomListMutates: mutate,
    typeTelecomListtErrors: !data && !mutate,
    isLoadings: !data && !error,
  };
};

//typeTelcomCateListData
export const GettypeTelecomCateListDatas = (type) => {
  const { data, error, mutate } = useSWR(
    () => `category/typeTelcomCateListData/${type}`
  );

  return {
    typeTelecomCateListDatas: data,
    typeTelecomCateListMutates: mutate,
    typeTelecomCateListtErrors: !data && !mutate,
    isLoadings: !data && !error,
  };
};
const GetQaList = ({ offset, limit }) => {
  const { data, error, mutate } = useSWR(
    () => `product/getQaList/${offset}/${limit}`
  );

  return {
    QaListData: data,
    QaListMutate: mutate,
    QaListError: !data && !mutate,
    isLoading: !data && !error,
  };
};

const GetPromotionList = (offset) => {
  const { data, error, mutate } = useSWR(
    () => `category/listPromotion/${offset}`
  );

  return {
    PromotionListData: data,
    PromotionListMutate: mutate,
    PromotionListError: !data && !mutate,
    isLoading: !data && !error,
  };
};


export const GetPromotionListN = (offset) => {
  const { data, error, mutate } = useSWR(
    () => `category/listNPromotion/${offset}`
  );

  return {
    PromotionNListData: data,
    PromotionNListMutate: mutate,
    PromotionNListError: !data && !mutate,
    isNLoading: !data && !error,
  };
};


//listNPromotion 

const GetAllOrderList = (
  offset,
  type,
  option,
  start,
  end,
  text,
  store,
  Dlier,
  dlSelect
) => {
  const { data, error, mutate } = useSWR(
    () =>
      `user/allOrderList/${offset}/${type}/${option}/${start}/${end}/${text}/${store}/${Dlier}/${dlSelect}`
  );

  return {
    allOrderListData: data,
    allOrderListMutate: mutate,
    PallOrderListError: !data && !mutate,
    isLoading: !data && !error,
  };
};

const GetInforMation = () => {
  const { data, error, mutate } = useSWR(() => `banner/getInformation`);

  return {
    InfoData: data,
    InfoDataMutate: mutate,
    InfoDataError: !data && !mutate,
    isLoading: !data && !error,
  };
};
const GetInfoDataNoUser = () => {
  const { data, error, mutate } = useSWR(() => `banner/getInfoDataNoUser`);

  return {
    NoInfoData: data,
    NoInfoDataMutate: mutate,
    NoInfoDataError: !data && !mutate,
    isLoading: !data && !error,
  };
};
//getInformationStore
const GetInforMationStore = () => {
  const { data, error, mutate } = useSWR(() => `banner/getInformationStore`);

  return {
    InfoStoredata: data,
    InfoStoredataMutate: mutate,
    InfoStoredataError: !data && !mutate,
    isLoading: !data && !error,
  };
};
const GetOrderInfoData = (id) => {
  const { data, error, mutate } = useSWR(() => `user/orderInfoData/${id}`);

  return {
    OrderInfoData: data,
    OrderInfoMutate: mutate,
    OrderInfoError: !data && !mutate,
    isLoading: !data && !error,
  };
};
const GetBoardList = (offset, storeid) => {
  const { data, error, mutate } = useSWR(
    () => `user/boardList/${offset}/${storeid}`
  );

  return {
    BoardListData: data,
    BoardListMutate: mutate,
    BoardListError: !data && !mutate,
    isLoading: !data && !error,
  };
};
export const AdminboardListAcm = (offset) => {
  const { data, error, mutate } = useSWR(
    () => `user/AdminboardListAcm/${offset}`
  );

  return {
    BoardListData: data,
    BoardListMutate: mutate,
    BoardListError: !data && !mutate,
    isLoading: !data && !error,
  };
};
//AdminboardListAcm/:offset


//getInformationOriginNone 

export const GetInformationOriginNone = () => {
  const { data, error, mutate } = useSWR(() => `banner/getInformationOriginNone`);

  return {
    OriginNData: data,
    OriginNMutate: mutate,
    OriginNError: !data && !mutate,
    isLoading: !data && !error,
  };
};

const GetInformationOrigin = () => {
  const { data, error, mutate } = useSWR(() => `banner/getInformationOrigin`);

  return {
    OriginData: data,
    OriginMutate: mutate,
    OriginError: !data && !mutate,
    isLoading: !data && !error,
  };
};

const GetBoardrecList = (offset, storeid) => {
  const { data, error, mutate } = useSWR(
    () => `banner/listBoardrec/${offset}/${storeid}`
  );

  return {
    BoardListrecData: data,
    BoardListrecMutate: mutate,
    BoardListrecError: !data && !mutate,
    isLoading: !data && !error,
    Error: error,
  };
};

//getAdminboardList

export const GetAdminboardList = (offset) => {
  const { data, error, mutate } = useSWR(
    () => `banner/getAdminboardList/${offset}`
  );

  return {
    BoardListrecData: data,
    BoardListrecMutate: mutate,
    BoardListrecError: !data && !mutate,
    isLoading: !data && !error,
    Error: error,
  };
};

const GetTermList = (offset, termstype) => {
  const { data, error, mutate } = useSWR(
    () => `product/termsGet/${offset}/${termstype}`
  );

  return {
    TermListData: data,
    TermListMutate: mutate,
    TermListError: !data && !mutate,
    isLoading: !data && !error,
  };
};

const GetTermsGetById = (id) => {
  const { data, error, mutate } = useSWR(() => `product/termsGetById/${id}`);

  return {
    TermsData: data,
    TermsMutate: mutate,
    TermsError: !data && !mutate,
    isLoading: !data && !error,
  };
};

const GetUserProfile = (id) => {
  const { data, error, mutate } = useSWR(() => `user/userProfile/${id}`);

  return {
    UserProfileData: data,
    UserProfileMutate: mutate,
    UserProfileError: !data && !mutate,
    isLoading: !data && !error,
  };
};

export const GetProductInfoAdm = (id) => {
  const { data, error, mutate } = useSWR(() => `product/productInfoAdm/${id}`);

  return {
    ProductInfoData: data,
    ProductInfoMutate: mutate,
    ProductInfoError: !data && !mutate,
    isLoading: !data && !error,
  };
};

const GetProductInfo = (id, DLMode, userid, joincode, storeid) => {
  const { data, error, mutate } = useSWR(
    () =>
      `product/productInfo/${id}/${DLMode}/${userid}/${joincode === "" ? null : joincode
      }/${storeid}`
  );

  return {
    ProductInfoData: data,
    ProductInfoMutate: mutate,
    ProductInfoError: !data && !mutate,
    isLoading: !data && !error,
  };
};

const LiviewFindAll = ({ offset, limit }) => {
  const { data, error, mutate } = useSWR(
    () => `product/LiviewFindAll/${offset}/${limit}`
  );

  return {
    LiviewData: data,
    LiviewMutate: mutate,
    LiviewError: !data && !mutate,
    isLoading: !data && !error,
  };
};
//LiviewFindAll
//getProductGroupById
export const GetProductGroupById = (id) => {
  const { data, error, mutate } = useSWR(
    () => `category/getProductGroupById/${id}`
  );

  return {
    ProductGroupIdsData: data,
    ProductGroupIdsMutate: mutate,
    ProductGroupIdsError: !data && !mutate,
    isLoading: !data && !error,
  };
};

const GetAllTypeTelecom = () => {
  const { data, error, mutate } = useSWR(() => `product/getAllTypeTelecom`);

  return {
    TypeTelecomListData: data,
    TypeTelecomListMutate: mutate,
    TypeTelecomListError: !data && !mutate,
    isLoading: !data && !error,
  };
};

const GetByIdTypeTelecom = (id) => {
  const { data, error, mutate } = useSWR(
    () => `product/getByIdTypeTelecom/${id}`
  );

  return {
    ByIdTypeTeleComData: data,
    ByIdTypeTeleComMutate: mutate,
    ByIdTypeTeleComError: !data && !mutate,
    isLoading: !data && !error,
  };
};

const GetPointAct = (id, offset, stores, opt, dlSelect) => {
  const { data, error, mutate } = useSWR(
    () => `user/getPointAct/${id}/${offset}/${stores}/${opt}/${dlSelect}`
  );

  return {
    PointActData: data,
    PointActMutate: mutate,
    PointActError: !data && !mutate,
    isLoading: !data && !error,
  };
};

const GetPushLogs = (offset, limit, store) => {
  const { data, error, mutate } = useSWR(
    () => `user/getPushLogs/${offset}/${limit}/${store}`
  );

  return {
    PushLogsData: data,
    PushLogsMutate: mutate,
    PushLogsError: !data && !mutate,
    isLoading: !data && !error,
  };
};
export const GetPushList = ({ mode }) => {
  const { data, error, mutate } = useSWR(() => `user/PushGroupList/${mode}`);

  return {
    PushListsData: data,
    PushListsMutate: mutate,
    PushListsError: !data && !mutate,
    isLoading: !data && !error,
  };
};

/** 이벤트 상세정보 가져오기 함수
 *  @id 값전달
 */
export const GetBoarderecByIds = ({ id }) => {
  const { data, error, mutate } = useSWR(() => `banner/getBoardrecByIds/${id}`);

  return {
    BoardrecData: data,
    BoardrecMutate: mutate,
    BoardrecError: !data && !mutate,
    isLoading: !data && !error,
  };
};

/** 인터넷+TV  가져오기 함수
 *  @id 값전달
 */
export const GetBoardByIds = ({ id }) => {
  const { data, error, mutate } = useSWR(() => `banner/getBoardByIds/${id}`);

  return {
    BoardData: data,
    BoardMutate: mutate,
    BoardError: !data && !mutate,
    isLoading: !data && !error,
  };
};

export {
  GetPushLogs,
  GetProductInfo,
  GetPointAct,
  GetByIdTypeTelecom,
  GetTermsGetById,
  GetBannerList,
  GetStoreList,
  GetAllTypeTelecom,
  GetuserList,
  GetuserProfile,
  GetTermList,
  GetStoreListTele,
  GetStoreInfo,
  GetCardList,
  GetInforMation,
  GetBoardrecList,
  GetCategoryList,
  GetPlanList,
  GetPromotionList,
  GetPrTelecom,
  GetOrderInfoData,
  GetAdminData,
  GetGmedInfo,
  GetBoardList,
  GetproductList,
  GetUserProfile,
  GetUserOrderList,
  GetAllOrderList,
  GetTelecomList,
  GetInformationOrigin,
  GetCategoryDatas,
  GetProductAdminList,
  GetInfoDataNoUser,
  GetPromotionDatas,
  GetTelecomCategoryList,
  GetPlanListCate,
  GettypeTelecomListDatas,
  GetQaList,
  LiviewFindAll,
  GetInforMationStore,
  GetStoredL,
  GetUsageCate,
};
